import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { tap, finalize, mergeMap } from 'rxjs/operators';
import { Observable, throwError, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Routes, RouterModule, Router, ActivatedRoute, NavigationEnd, ParamMap } from '@angular/router';
import { NzNotificationService, NzModalRef, NzModalService } from 'ng-zorro-antd';
import { TokenServiceService } from '../../commons/services/token-service.service'
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {
  _isShow = false;
  set isShow(val) {
    this._isShow = val;
  }
  get isShow() {
    return this._isShow;
  }
  constructor(
    private router: Router, 
    private tokenService: TokenServiceService
    ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = sessionStorage.getItem('x-access-current-token');
    const token1: string = sessionStorage.getItem('x-access-refresh-token');

    if (token) {
      request = request.clone({headers:
        request.headers.set('x-access-current-token', token).set('x-access-refresh-token', token1)});//.set('Authorization1', token1)
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body.code === '10002') {
              this.tokenService.isNoToken(true,
                  () => {
                    sessionStorage.clear();
                    this.tokenService.removeToken();
                    this.router.navigate(['/login']);
                  }
                );
              event.body.data = null;
              event.body.code = 200;
              return event;
            } else {
              if(event.body.tokenMap != null) {
                this.tokenService.setToken(event.body.tokenMap);
              }
              return event;
            }

          }
        }),
      );
    } else {
      return next.handle(request);
    }


  }

}
