import { Component, OnInit,Input, EventEmitter,Output,ViewChild, ElementRef,HostListener} from '@angular/core';

@Component({
  selector: 'app-wf-agent-class-type',
  templateUrl: './wf-agent-class-type.component.html',
  styleUrls: ['./wf-agent-class-type.component.css']
})
export class WfAgentClassTypeComponent implements OnInit {
  @ViewChild('drags') drags: ElementRef;
  @Input() isShow:boolean;
  @Input() showTit:any;
  @Output() backEventState = new EventEmitter();
  @Output() backEventData = new EventEmitter();
   /*****
   * isShow:组件显示隐藏
   * showTit:显示标题
   * backEventData：返回组件操作完的数据---保留
   * backEventState：返回组件显示隐藏状态
   * ***** */
  modalTitle='';
  isVisible=false;
  isDown = false;
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom='';
  busId='';
  busName=''
  busTable='';
  treeQueryArgs={
    url:'/businessType/tree',
    pid: '-1',
    isSyn:false,
    "busType":"R1"
  };
  constructor() { }

  ngOnInit() {
    this.changeTem();
  }
  ngOnChanges(){
     this.isVisible=this.isShow;
     this.modalTitle=this.showTit;
  }
    // 点击事件
  @HostListener('document:mousedown', ['$event']) onMousedown(event) {
    if(event.path[0].localName!='input'){
       if(this.activeDom!=''){
       this.isDown = true;
       this.disX = event.clientX;
       this.disY = event.clientY;
       }
    }
 }

 // 监听document移动事件事件
 @HostListener('document:mousemove', ['$event']) onMousemove(event) {
   // 判断该元素是否被点击了。
   if (this.isDown) {
     this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
     this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
   }
 }
 // 监听document离开事件
 @HostListener('document:mouseup', ['$event']) onMouseup(event) {
   // 只用当元素移动过了，离开函数体才会触发。
   if (this.isDown) {
     this.totalOffsetX += event.clientX - this.disX;
     this.totalOffsetY += event.clientY - this.disY;
     this.isDown = false;
   }
 }
 changeTem(){
  this.activeDom=this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
  this.activeDom['style'].position = 'relative';
 }
 //点击树组件返回值
 getNode(args){
  if(args.state){
    if(args.datas.selected){ 
      this.busId=args.datas.busId;
      this.busName=args.datas.busName;
      this.busTable=args.datas.busTable;
    }else{
      this.busId='';
      this.busName=''
      this.busTable='';
    }
  }else{
      this.busId='';
      this.busName=''
      this.busTable='';
  }
}
handleOk(){
   let obj = {
     busId:this.busId,
     busName:this.busName,
     busTable:this.busTable,
   }
   this.isVisible=false;
   this.backEventData.emit(obj);
   this.backEventState.emit(this.isVisible);
}
handleCancel(){
  this.isVisible=false;
  this.backEventState.emit(this.isVisible);
}
}
