import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceApiService} from '../../commons/services/service-api.service';
import { TokenServiceService} from '../../commons/services/token-service.service';
import {NzNotificationService} from 'ng-zorro-antd';
import { url } from 'inspector';


@Injectable({
  providedIn: 'root'
})
export class AutoServiceService {
  baseUrl=this.svApi.urlApi['sysManage'];
  userMenuList=[];
  testToken="";
  passtoken = {
    'x-access-token' :'',
  };
  constructor(private http: HttpClient,
    private svApi:ServiceApiService,
    private token:TokenServiceService,
    private notice: NzNotificationService
    ) { }
  login(urls, data): Observable<any> {
    let url = this.baseUrl + urls;
    return this.http.post(url, data, {withCredentials: true});
  }
  logout(urls, data): Observable<any> {
    let token = this.token.getToken();
    let url = this.baseUrl+urls;
     return this.http.post(url, data,{withCredentials: true});
  }
  getMenuTree(urls, data): Observable<any> {
    let token={
      'x-access-current-token' :this.token.getToken(),
      'x-access-refresh-token' :this.token.getToken1(),

    };
    let url = this.baseUrl+urls;
    return this.http.post(url, data,{headers:token});
  }
  getUserMenuList(urls, data): Observable<any>{
    let url=this.baseUrl+urls;
   let passData=[];
    let token={
      'x-access-current-token' :this.token.getToken(),
      'x-access-refresh-token' :this.token.getToken1(),
    };
    return this.http.post(url, passData,{headers:token});
  }
  buildTree(list){
    let temp = {};
    let tree = [];
    for(let i in list){
      temp[list[i].id] = list[i];
    }
    for(let i in temp){
      if(temp[i].pid) {
        if(temp[i].pid!='-1'){
          if(!temp[temp[i].pid]['children']) {
            temp[temp[i].pid]['children']= [];
          }
         temp[temp[i].pid]['children'].push(temp[i]);
          } else {
            let obj=temp[i];
            tree.push(obj);
          }
        }

    }
    return tree;
  }
  buildTreeChildren(list){
    let temp = {};
    let tree = [];
    for(let i in list){
      temp[list[i].id] = list[i];
    }
    for(let i in temp){
      if(temp[i].pid) {
        if(temp[i].pid!='1'){
          if(!temp[temp[i].pid]['children']) {
            temp[temp[i].pid]['children']= [];
          }
         temp[temp[i].pid]['children'].push(temp[i]);
          } else {
            let obj=temp[i];
            tree.push(obj);
          }
        }

    }
    return tree;
  }
  // testFv(): Observable<any>{
  //   let test='接受数据';
  //   return test;
  // }
  buildTree1(list){
    let temp = {};
    let tree = [];
    for(let i in list){
      temp[list[i].id] = list[i];
    }
    for(let i in temp){
      if(temp[i].pid) {
        if(temp[i].pid!='0'){
          if(!temp[temp[i].pid]['children']) {
            temp[temp[i].pid]['children']= [];
          }
         temp[temp[i].pid]['children'].push(temp[i]);
         temp[temp[i].pid]['expanded']=true;
          } else {
            let obj=temp[i];
            obj['expanded']=true;
            tree.push(obj);
          }
        }

    }
    return tree;
  }
}
