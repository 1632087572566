import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DmModalNameService {
  queryObj=[]
  constructor() {

  }
  getQuery(data){
      this.queryObj=data
  }
  returnQuery(){
    return this.queryObj;
  }
  removeQuery(){
    this.queryObj=[];
  }




}
