import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceApiService} from '../../commons/services/service-api.service';
import { TokenServiceService} from '../../commons/services/token-service.service';
import {NzNotificationService} from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class MenuManageService {
  baseUrl=this.svApi.urlApi['sysManage'];
  isLoad=false;
  testToken="";
  passtoken = {
    'x-access-current-token' :this.token.getToken(),
    'x-access-refresh-token' :this.token.getToken1(),
  };
  constructor(private http: HttpClient,
      private svApi:ServiceApiService,
      private token:TokenServiceService,
      private notice: NzNotificationService
  ) {}
  //主要是收藏
  handelPost(urls, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.token.getToken(),
      'x-access-refresh-token' :this.token.getToken1(),
    };
    let url = this.baseUrl+urls;
    return this.http.post(url, data,{headers:tokens}); 
  }
  handelGet(urls,data): Observable<any> {
    let token=this.passtoken;
    let url = this.baseUrl+urls;
    return this.http.get(url, {params:data,headers:token}); 
  }

  handelOpenLoad(){
    this.isLoad=true;
  }
  handelCloseLoad(){
    this.isLoad=false;
  }
  handelGetLoad(){
    return this.isLoad;
  }
     
}
