import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute, NavigationEnd, ParamMap } from '@angular/router';
import { ViewStateService } from '../services/view-state.service';
import { MenuManageService } from '../../commons/services/menu-manage.service';
import { TranslateService } from '@ngx-translate/core';
import { TokenServiceService } from '../services/token-service.service';
import { NzNotificationService,NzModalRef, NzModalService } from 'ng-zorro-antd';
import { AutoInternationService } from '../../commons/services/auto-internation.service';
import { IndexApiService } from '../services/index-api.service';
import { AutoServiceService} from '../services/auto-service.service';
import { DmModalNameService} from '../../dm/services/dm-modal-name.service';
import { ButtonMenuService }from '../services/button-menu.service';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  documentTit = 'systemstandard';// 制度标准
  documentStatus = false;
  documentUrl = '/portal/content/Page?pageSize=5&&pageNum=1';
  documentDatas = [
    {
      key: 'forumName',
      title: 'columnname',
    },
    {
      key: "contentName",
      title: 'filename',
    },
    {
      key: "keyword",
      title: 'keywords',
    },
    {
      key: 'creatorName',
      title: 'creator',
    },
    {
      key: 'createTime',
      title: 'creationtime',
    }
  ];
  //新首页参数   start
  rightMenuLists = [
    { title: this.nation.getMessage('general','query'), chilrens: [], active: false,link:'main/dm/dmDataQuery' },
    { title: this.nation.getMessage('general','new'), chilrens: [], active: false,link:'main/dm/dmDataApply' },
    { title: this.nation.getMessage('general','modification'), chilrens: [], active: false,link:'main/dm/dmDataEdit' },
    { title: this.nation.getMessage('general','review'), chilrens: [], active: false,link:'main/dm/dmDataAudit' },
  ];

  //统计图1
  array = [];
  effect = 'fade';
  b_lists = [
    { title: '内部数据', imgUrl: 'assets/images/index/icon' + Math.floor(Math.random() * 10 + 1) + '.png', },
    { title: '外部数据', imgUrl: 'assets/images/index/icon' + Math.floor(Math.random() * 10 + 1) + '.png', },
    { title: '内部数据', imgUrl: 'assets/images/index/icon' + Math.floor(Math.random() * 10 + 1) + '.png', },
  ];
  chartOption;
  chartOption1;
  chartOption2;
  chartOption3;
  istodoLists=false;
  isnotices=false;
  standardedit={
    showTit:'制度标准',
    orgId:'1',
  };
  isStandard=false;
  Count={Message:0,Approve:0,Zd:0};
  interval;
  sysList: any;
  appId: any;
  lan="CH"
  //新首页参数   end
  constructor(
    private router: Router,
    private viewAttr: ViewStateService,
    private menu: MenuManageService,
    private translateSe: TranslateService,
    private token: TokenServiceService,
    private notice:NzNotificationService,
    private nation:AutoInternationService,
    private api:IndexApiService,
    private auth:AutoServiceService,
    private dmModalNameSer:ButtonMenuService
  ) {
  }

  ngOnInit() {
    this.init();
    if (this.viewAttr.isRoute) {
      let parms = ['main/dm/dmFiledSetting'];
      this.router.navigate(parms);
      this.viewAttr.coloseIsRoute();
    }
  }
  ngDoCheck(){
    if(this.lan!=this.token.getSysLang()){
      this.lan=this.token.getSysLang();
      this.rightMenuLists = [
        { title: this.nation.getMessage('general','query'), chilrens: [], active: false,link:'main/dm/dmDataQuery' },
        { title: this.nation.getMessage('general','new'), chilrens: [], active: false,link:'main/dm/dmDataApply' },
        { title: this.nation.getMessage('general','modification'), chilrens: [], active: false,link:'main/dm/dmDataEdit' },
        { title: this.nation.getMessage('general','review'), chilrens: [], active: false,link:'main/dm/dmDataAudit' },
      ];
    }
  }
  ngOnDestroy(){
    this.intervalStop();
  }
  init(){
    this.getActiveChange();
    this.getNoticesCount();
    this.getNoticesCountInterval();
    this.getrightLists();
    this.getDatareportCount();
    this.getShowList();
  }
  tolink(url){
    let parms = [url];
    this.router.navigate(parms);
  }
  setActiveChange(data){
    let arr =[];
    this.rightMenuLists.forEach(item=>{
      if(item.active){
        arr.push(item.title);
      }
    });
    localStorage.setItem("isactive",arr.toString());
  }
  getActiveChange(){
    if(localStorage.getItem("isactive")){
      const data=localStorage.getItem("isactive").split(',');
      this.rightMenuLists.forEach(item=>{
        if(data.find(el=>el==item.title)){
          item.active=true;
        }
      });
    }else{
      this.rightMenuLists[0]['active']=true;
    }
  }
  todo(){
    this.istodoLists=true;
  }
  getTodo(data){
    this.istodoLists=data;
  }
  toNotices(){
    this.isnotices=true;
  }
  getNotices(data){
    this.isnotices=data;
  }
  getNoticesCount(){
    this.api.handlePost('/home/queryCount',{type:'sysManage',args:{}}).subscribe(res => {
      if(res.code==200){
        if(res.data != null){
          const Count=res.data;
          this.Count['Message']=Count.filter(item=>item.moduale=='Message')[0]['count'];//公告
          this.Count['Approve']=Count.filter(item=>item.moduale=='Approve')[0]['count'];//待审
          this.Count['Zd']=Count.filter(item=>item.moduale=='Zd')[0]['count'];//制度
        }
      }else{
        this.notice.warning(this.nation.getMessage('general','msg'),res.msg);
      }
    },
     err => {
      this.notice.warning(this.nation.getMessage('general','msg'),err.error.message)
    },
    );
  }
  getNoticesCountInterval(){
    const _self=this;
    _self.interval=setInterval(function(){
      _self.getNoticesCount();
    },300000);
  }
  intervalStop(){
    clearInterval(this.interval);
  }
  //帮助文档
  toSysStandard(){
   this.isStandard=true;
  }
  backStandard(args){
    this.isStandard=args;
  }
  //获取右侧收藏
  getrightLists(){
    let sys_info;
    if(localStorage.getItem('sys_info')){
      sys_info=JSON.parse(localStorage.getItem('sys_info'));
    }else{
      sys_info={id:'271068791503458304'}
    }
    this.api.handlePost('/model/getAllModelFavorite?appId='+sys_info.id+"&lanCode="+this.token.getSysLang(),{type:'mdmManage',args:{appId:sys_info.id}}).subscribe(res => {
      if(res.code==200){
        if(res.data != null){
          //operType对应的值1.查询2.新增3.修改4.审核
          const rightMenuData=res.data;
          let ordnum=0;
          for(let i=0;i<rightMenuData.length;i++){
            ordnum=(+rightMenuData[i]['operType'])-1;
           if(this.rightMenuLists[ordnum].chilrens.length<6){
            this.rightMenuLists[ordnum].chilrens.push({
              name:rightMenuData[i]['modelName'],
               id:rightMenuData[i]['modelId'],
               code:rightMenuData[i]['modelCode'],
               operType:rightMenuData[i]['operType'],
               imgUrl: 'assets/images/index/icon' + Math.floor(Math.random() * 10 + 1) + '.png'
            });
           }
          }
        }
      }else{
        this.notice.warning(this.nation.getMessage('general','msg'),res.msg);
      }
    },
     err => {
      this.notice.warning(this.nation.getMessage('general','msg'),err.error.message)
    },
    );
  }
  toData(data,url){
    if(data.operType=='1'){

    }
    switch(data.operType){
      case '1':
        this.dmModalNameSer.getQuery(data);
        this.tolink(url);
        break;
        case '2':
          this.dmModalNameSer.getModalInfo(data);
          this.tolink(url);
          break;
          case '3':
            this.dmModalNameSer.getEdit(data);
            this.tolink(url);
            break;
            default:
              this.dmModalNameSer.getAudit(data);
              this.tolink(url);
    }
  }
  getDatareportCount(){
    this.api.handlePost('/Datareport/queryApplyFirstPass',{type:'data-report',args:{}}).subscribe(res => {
      if(res.code==200){
        if(res.data != null){
          const Data=res.data;
          let sourceData=[
            {value:Data.not_first_pass, name:'审批'},
            {value:Data.refused, name:'驳回'},
            {value:Data.first_pass, name:'一次性通过'},
        ]
    this.chartOption3=  {
      color: ['#e062ae','#37a2da','#67e0e3','#ffdb5c','#ff9f7f'],
      tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b}: {c} ({d}%)"
      },
      legend: {
          orient: 'horizontal',
          x: 'center',
          y:'20px',
          data:['审批','驳回','一次性通过',]
      },
      series: [
          {
              name:'访问来源',
              type:'pie',
              radius: ['50%', '70%'],
              center: ['50%', '50%'],
              avoidLabelOverlap: false,
              label: {
                  normal: {
                      show: false,
                      position: 'center'
                  },
                  emphasis: {
                      show: true,
                      textStyle: {
                          fontSize: '14',
                          fontWeight: 'bold'
                      }
                  }
              },
              labelLine: {
                  normal: {
                      show: false
                  }
              },
              data:sourceData,
          }
      ]
  };
        }
      }else{
        this.notice.warning(this.nation.getMessage('general','msg'),res.msg);
      }
    },
     err => {
      this.notice.warning(this.nation.getMessage('general','msg'),err.error.message)
    },
    );
  }
  getShowList(){
    this.api.handlePost('/Datareport/queryMasterDataByOperType',{type:'data-report',args:{}}).subscribe(res => {
      if(res.code==200){
        if(res.data != null){
          const Data=res.data;
          let arr=[];
          for(var item of Data){
            let obj={};
            obj['product']=item.busName;
            if(item['operType-1']){
              obj['新增']=item['operType-1'];            
            }
           let sum=item['operType-2']?item['operType-2']:0;
           sum+=item['operType-3']?item['operType-3']:0;
           sum+=item['operType-4']?item['operType-4']:0;
           sum+=item['operType-0']?item['operType-0']:0;
           obj['修改']=sum;
           arr.push(obj);
            }
        this.chartOption = {
          color: ['#e5323e', '#4cabce'],
          legend: {},
          tooltip: {},
          dataset: {
              dimensions: ['product', '新增', '修改'],
              source: arr
          },
          xAxis: {
            type: 'category',
            // axisLabel:{//把文字竖着放
            //   formatter:function(value){
            //       return value.split("").join("\n")
            //   },
            // //   interval: 0,
            // // rotate: 40,
            // }
            axisLabel: {
              interval: 0,
              formatter:function(value){
                var ret = "";//拼接加\n返回的类目项 
                var maxLength = 2;//每项显示文字个数
                var valLength = value.length;//X轴类目项的文字个数
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (rowN > 1)//如果类目项的文字大于3,
                {
                for (var i = 0; i < rowN; i++) {
                      var temp = "";//每次截取的字符串
                var start = i * maxLength;//开始截取的位置
                var end = start + maxLength;//结束截取的位置
                //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                temp = value.substring(start, end) + "\n";
                ret += temp; //凭借最终的字符串
                }
                return ret;
                }else {
                return value;
                }
              }
            }
          },
          grid: {
            bottom: '20%',
          },
          yAxis: {},
          series: [
              {type: 'bar', barWidth: 30},
              {type: 'bar', barWidth: 30}
          ]
      };
        }
      }else{
        this.notice.warning(this.nation.getMessage('general','msg'),res.msg);
      }
    },
     err => {
      this.notice.warning(this.nation.getMessage('general','msg'),err.error.message)
    },
    );
  }
}
