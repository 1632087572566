import { Component, OnInit,Input, EventEmitter,Output,TemplateRef, ViewChild, ElementRef,HostListener} from '@angular/core';
import { NzNotificationService,NzModalService } from 'ng-zorro-antd';
import { FakeMissingTranslationHandler } from '@ngx-translate/core';
import { WfApiService } from './../../../wf/services/wf-api.service';
@Component({
  selector: 'app-wf-agent-show-table',
  templateUrl: './wf-agent-show-table.component.html',
  styleUrls: ['./wf-agent-show-table.component.css']
})
export class WfAgentShowTableComponent implements OnInit {
  @Input() thData:any;
  @Input() tit:any;
  @Input() showWh:any;
  @Input() urls:any;
  @Input() isShow:any;
  @Input() postBody:any;
  @Input() search:boolean;
  @Input() isOk:boolean;
  @Input() types:any;
  @Output() handleBackEvent = new EventEmitter();
  @Output() handleKeepEvent = new EventEmitter();
  @Output() handleSucStateEvent = new EventEmitter();
  @ViewChild('drags') drags: ElementRef;
  /***********
   * thData:表格表头数据
   * tit：模态框标题
   * showWh：模态框宽度
   * urls：表格数据展示url
   * isShow：表格是否展示
   * postBody：表格数据展示POST请求体，数据类型是数组
   * search：是否展示搜索框部分
   * isOk:确定是否有有其余操作
   * handleBackEvent：事件返回
   * handleKeepEvent：事件返回,数据保存
   * handleSucStateEvent:事件返回成功或者失败状态
   *  */
  isLoad=true;
  allChecked = false;
  indeterminate = false;
  pageIndex = 1;
  pageSize = 10;
  pageTotal = 0;
  dataSet = [];
  displayData = [...this.dataSet];
  sortName = null;
  sortValue = null;
  isVisible = false;
  searchBox=false;
  isOks=false;
  type='';
  searchUrl='';
  wh="600px";
  title="";
  url="";
  searchUrlsBodys=[];
  thDatas=[];
  postBodys=[];
  statusTypes=[
    {
      label:'停用',
      value:'0'
    },
    {
      label:'启用',
      value:'1'
    },
  ];
  isDown = false;
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom='';
  constructor(private api:WfApiService,private notice:NzNotificationService,) { }
  ngOnInit() {
    this.isVisible=this.isShow;
    this.wh=this.showWh;
    this.title=this.tit;
    this.url=this.urls;
    this.thDatas=this.thData;
    this.postBodys=this.postBody;
    this.searchBox=this.search;
    this.type=this.types;
    this.isOks=this.isOk;
    this.changeTem();
  }
  ngOnChanges(){
    this.isVisible=this.isShow;
    this.wh=this.showWh;
    this.title=this.tit;
    this.isOks=this.isOk;
    this.url=this.urls;
    this.thDatas=this.thData;
    this.postBodys=this.postBody;
    this.searchBox=this.search;
    this.type=this.types;
    this.init();
  }
  // 点击事件
  @HostListener('document:mousedown', ['$event']) onMousedown(event) {
    if(event.path[0].localName!='input'){
      this.isDown = true;
      this.disX = event.clientX;
      this.disY = event.clientY;
    }
  }
  // 监听document移动事件事件
  @HostListener('document:mousemove', ['$event']) onMousemove(event) {
    // 判断该元素是否被点击了。
    if (this.isDown) {
      this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
      this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
    }
  }

  // 监听document离开事件
  @HostListener('document:mouseup', ['$event']) onMouseup(event) {
    // 只用当元素移动过了，离开函数体才会触发。
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
      this.isDown = false;
    }
  }
 changeTem(){
  this.activeDom=this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
  this.activeDom['style'].position = 'relative';
 }
  handleCancel(): void {
    this.isVisible = false;
    this.handleBackEvent.emit(this.isVisible);
  }
  handleOk(): void {
    if(this.isOks){
      if(this.displayData.filter(value => value.checked === true).length==0){
        this.notice.warning('提示','请选择要操作的数据');
      }else if(this.displayData.filter(value => value.checked === true).length>=1){
        let list=this.displayData.filter(value => value.checked === true);
          this.handleSucStateEvent.emit(list);
          //this.handleKeepEvent.emit(list);
          this.isVisible = false;
          this.handleBackEvent.emit(this.isVisible);
      }
    }else{
      this.isVisible = false;
      this.handleBackEvent.emit(this.isVisible);
    }
  }
  queryRole (args) {
    this.isLoad=true;
    let url=this.url+'?pageSize='+args.pageSize+'&pageNum='+args.pageNum;
    let obj={};
    if(this.postBodys){
      for(let i of this.postBodys){
        obj[i.key]=i.value;
      }
    }
    for(let i of this.thDatas){
      if(i.value!==""&&i.value!=null){
         obj[i.key]=i.value;
      }
    }
    this.api.queryPost(url,obj).subscribe(res => {
      if(res.code==200){
        if(res.data!=null){
          this.displayData=res.data.list;
          this.pageTotal=res.data.total;
        }
        this.isLoad=false;
      }else{
        this.notice.error("提示",res.msg);
      }
    },
    err => {
      this.notice.warning( '提示',err.error.message)
    })
  }
  /***多选功能** */
  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.displayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.displayData.filter(value => !value.disabled).every(value => value.checked === true);
    const allUnChecked = this.displayData.filter(value => !value.disabled).every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }
  changeSize(data){
    if(this.pageSize!=data){
      this.pageIndex=1;
      let argsObj={
        pageNum:1,
        pageSize:data,
      }
      this.queryRole(argsObj);
    }
  }
  checkAll(value: boolean): void {
    this.displayData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }
  sortDown(args,$event){
    $event.stopPropagation();
    if(args&&this.dataSet.length!=0){
      this.displayData=[];
      let das = this.dataSet.sort((a, b) =>  (b[ args] > a[ args ] ? 1 : -1));
      this.displayData=[...das];
    }
  }
  sortUp(args,$event){
    $event.stopPropagation();
    if(args&&this.dataSet.length!=0){
      this.displayData=[];
      let das = this.dataSet.sort((a, b) =>  (a[ args ] > b[ args ] ? 1 : -1));
      this.displayData=[...das];
    }
  }
  init(){
    this.pageIndex=1;
    let argsObj={
      pageNum:1,
      pageSize:this.pageSize,
    }
    this.queryRole(argsObj);
  }
  changeIndexPage(args){
    let index=0;
    if(this.pageIndex==1){
      index=args;
    }else if(this.pageIndex>1){
      index=(Number(this.pageIndex)-1)*Number(this.pageSize)+Number(args);
    }

    return index;
  }
  goPage(number) {
    this.pageIndex=number;
    let argsObj={
      pageNum:number,
      pageSize:this.pageSize,
    }
    this.queryRole(argsObj);
  }
  filterDatas(args,obj){
    let ary=args[obj];
    return ary;
  }
  changeBg(args,obj){
    let bgColor=args.currentTarget.style.backgroundColor;
    obj.checked=!obj.checked;
    if(bgColor=="#EEF5FB"||bgColor=='rgb(238, 245, 251)'){
      args.currentTarget.style.backgroundColor="";
    }else{
      args.currentTarget.style.backgroundColor="#EEF5FB"
    }
  }
  checkTdChange(args,obj){
    args.stopPropagation();
    if(obj.checked){
      args.currentTarget.parentElement.style.backgroundColor="#EEF5FB";
    }else{
      args.currentTarget.parentElement.style.backgroundColor="";
    }
  }
}
