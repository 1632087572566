import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeftMenuService {
  showMenuList={
    'userInterface':{
      data:{
        isNode:1,
        breadcrumb: 'sysManage/rightsManagement/userInterface'
      },
    },
    'userManage':{
      data:{
        isNode:1,
        breadcrumb: 'sysManage/rightsManagement/userManage'
      },
    },
    'usergroupManage':{
      data:{
        isNode:1,
        breadcrumb: 'sysManage/rightsManagement/usergroupManage'
      },
    },
    'resourceManage':{
      data:{
        isNode:1,
        breadcrumb: 'sysManage/rightsManagement/resourceManage'
      },
      'sysSecurityPolicy':{
        data:{
          isNode:1,
          breadcrumb: 'sysManage/rightsManagement/sysSecurityPolicy'
        },
      }
    },
    'roleManage':{
      data:{
        isNode:1,
        breadcrumb: 'sysManage/rightsManagement/roleManage'
      },
    },
    'notifyManage':{
      data:{
        isNode:1,
        breadcrumb: 'sysManage/notifyManage'
      },
    },
    'logManage':{
      data:{
        isNode:1,
        breadcrumb: 'sysManage/logManage'
      },
    },
    'unitOfMeasure':{
      data:{
          isNode:1,
          breadcrumb: 'materialGovernance/basicSittings/unitOfMeasure'
      },
    },
    'decorateSymbol':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/basicSittings/decorateSymbol'
      }
    },
    'extendFieldSet':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/basicSittings/extendFieldSet'
      }
    },
    'searchCategory':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialCategories/searchCategory'
      }
    },
    'createCategory':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialCategories/createCategory'
      }
    },
    'changeCategory':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialCategories/changeCategory'
      }
    },
    'approveCategory':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialCategories/approveCategory'
      }
    },
    'searchTemplete':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialTempletes/searchTemplete'
      }
    },
    'createTemplete':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialTempletes/createTemplete'
      }
    },
    'changeTemplete':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialTempletes/changeTemplete'
      }
    },
    'approveTemplete':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialTempletes/approveTemplete'
      }
    },
    'searchMaterial':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialProcessing/searchMaterial'
      }
    },
    'createMaterial':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialProcessing/createMaterial'
      }
    },
    'changeMaterial':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialProcessing/changeMaterial'
      }
    },
    'approveMaterial':{
      data:{
        isNode:1,
        breadcrumb: 'materialGovernance/materialProcessing/approveMaterial'
      }
    },
    'dmMasterModel':{
      data:{
        isNode:1,
        breadcrumb: 'mdmModel/dmMasterModel'
      }
    },
    'dmDictionaryModel':{
      data:{
        isNode:1,
        breadcrumb: 'mdmModel/dmDictionaryModel'
      }
    },
  };
  constructor() { }
  handleDatas(args){
    
    let ary=[];
    for(let i=0,n=args.length;i<n;i++){
      let obj={
        label:args[i].name,
        icon: 'database',
        url:args[i].img
      }
      if(args[i]['children']!=undefined){
        obj['children']=this.handleDatas(args[i]['children']);
      }
      if(this.showMenuList[args[i].name]!=undefined){
        obj['data']=this.showMenuList[args[i].name].data;
      }
      if(args[i]['children']==undefined){
        obj['routerLink']=args[i].url;
        obj['btnList']=args[i].childList;
        obj['id']=args[i].id;
        // obj['data']={};
        // obj['data']['img']=args[i].img;
        // obj['data']['isNode']=args[i].isCrumb;
        // obj['data']['breadcrumb']=args[i].crumbUrl;
      }
      ary.push(obj);
    }
    return ary;
  }
  getChangeUrls(args){
    let ary=[];
    ary.push(args.children['routeConfig']['path']);
    if(args.children.ActivatedRoute.children.length!=0){
      let obj=this.getChangeUrls(args.children.ActivatedRoute.children[0]);
      ary.push(obj);
    }
    return ary.join("");
  }
}
