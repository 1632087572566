import { Component, OnInit,Input, EventEmitter,Output,ViewChild, ElementRef,HostListener} from '@angular/core';
import { NzNotificationService} from 'ng-zorro-antd';
import { AutoInternationService } from '../../../commons/services/auto-internation.service';
import { FormBuilder,FormControl, FormGroup, Validators } from '@angular/forms';
import { IndexApiService } from '../../services/index-api.service';
@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {
  @ViewChild('drags') drags: ElementRef;
  @Input() isShow:boolean;
  @Input() showTit:any;
  @Input() saveUrl:any;
  @Input() verifyUrl:any;
  @Output() backEventState = new EventEmitter();
  @Output() backEventData = new EventEmitter();
  /*****
   * isShow:组件显示隐藏
   * showTit:显示标题
   * saveUrl:保存地址
   * verifyUrl：验证地址
   * backEventState：返回组件显示隐藏状态
   * backEventData：返回组件操作完的数据---保留
   * ***** */
  validateForm: FormGroup;
  isVisible=false;
  modalTitle='';
  saveUrls='';
  verifyUrls='';
  saveParm='';
  pid='0';
  agentId='';
  busiId='';
  busiTable='';
  showMdm='';
  isEdits=false;
  isDown = false;
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom='';
  constructor(
    private notice:NzNotificationService,
    private nation:AutoInternationService,
    private fb: FormBuilder,
    private api:IndexApiService,
  ) { 
    this.validateForm = this.fb.group({
      oldPassword:['',[Validators.required]],
      newPassword: ['', [Validators.required]],
      confirm: ['', [this.confirmValidator]],
    });
  }

  ngOnInit() {
    this.changeTem();
  }
  ngOnChanges(){
    this.isVisible=this.isShow;
    this.modalTitle=this.showTit;
    this.saveUrls=this.saveUrl;
    this.verifyUrls=this.verifyUrl;
    //this.init();
  }
  //init(){}
  resetForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }
  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls.newPassword.value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  handleCancel(){
    this.isVisible=false;
    this.backEventState.emit(this.isVisible);
  }
  submitForm(){
    
  }
  handleOk() {
    this.resetForm();
    let oldPassword = this.validateForm.value.oldPassword;
    let newPassword = this.validateForm.value.newPassword;
    this.api.getPost(this.verifyUrls + "?passWord=" + oldPassword, {}).subscribe(res => {
      if (res.code == 200) {
        let url = this.saveUrls + "?oldPassWord=" + oldPassword + "&newPassWord=" + newPassword;
        this.api.getPost(url, {}).subscribe(res => {
          if (res.code == 200) {
            this.notice.success(this.nation.getMessage('general', 'msg'),this.nation.getMessage("general",'modifys'));
            this.isVisible=false;
            this.backEventState.emit(this.isVisible);
          } else {
            this.notice.warning(this.nation.getMessage('general', 'msg'), res.msg);
          }
        },
          err => {
            this.notice.warning(this.nation.getMessage('general', 'msg'), err.error.msg);
          })
      } else {
        this.notice.warning(this.nation.getMessage('general', 'msg'), res.msg);
      }
    },
      err => {
        this.notice.warning(this.nation.getMessage('general', 'msg'), err.error.msg);
      })
  }
  // 点击事件
  @HostListener('document:mousedown', ['$event']) onMousedown(event) {
    if(event.path[0].localName!='input'){
       if(this.activeDom!=''){
       this.isDown = true;
       this.disX = event.clientX;
       this.disY = event.clientY;
       }
    }
 }

 // 监听document移动事件事件
 @HostListener('document:mousemove', ['$event']) onMousemove(event) {
   // 判断该元素是否被点击了。
   if (this.isDown) {
     this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
     this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
   }
 }
 // 监听document离开事件
 @HostListener('document:mouseup', ['$event']) onMouseup(event) {
   // 只用当元素移动过了，离开函数体才会触发。
   if (this.isDown) {
     this.totalOffsetX += event.clientX - this.disX;
     this.totalOffsetY += event.clientY - this.disY;
     this.isDown = false;
   }
 }
 changeTem(){
  this.activeDom=this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
  this.activeDom['style'].position = 'relative';
 }
 validateConfirmPassword(): void {
  setTimeout(() => this.validateForm.controls.confirm.updateValueAndValidity());
}
}