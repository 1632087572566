import { Component, OnInit, Input, EventEmitter, Output, TemplateRef, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { NzNotificationService, NzModalService, NzTabSetComponent } from 'ng-zorro-antd';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { AutoInternationService } from '../../services/auto-internation.service';
import { IndexApiService } from '../../services/index-api.service';

@Component({
  selector: 'app-common-index-systemstandard',
  templateUrl: './common-index-systemstandard.component.html',
  styleUrls: ['./common-index-systemstandard.component.less']
})
export class CommonIndexSystemstandardComponent implements OnInit {
  @Input() isVisibles;
  @Input() showTit: any;
  @Output() backEventState = new EventEmitter();
  @ViewChild('drags') drags: ElementRef;
  isDown = false;
  effect = 'scrollx';
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom = '';
  isVisible=false;
  modalTitle: any;
  systemTit = 'system';//制度
  standardTit = 'systemstandard';//标准
  systemLists =[
    {
      key:"status",
      title:'状态',
      checked:true,
      disabled: false,
      value:"",
      type:"select",
    },
    { key:"contentName",
      title:'文档名称',
      checked:true,
      disabled: false,
      value:"",
      type:'input',
    },
    { key:'contentText',
    title:'内容',
    checked:true,
    disabled: false,
    value:"",
    type:'input',
    },
    { key:'forumName',
    title:'栏目名称',
    checked:true,
    disabled: false,
    value:"",
    type:'input',
    },
    { key:"keyword",
      title:'关键字',
      checked:true,
      disabled: false,
      value:"",
      type:'input',
    },
    { key:"isTop",
    title:'是否置顶',
    checked:true,
    disabled: false,
    value:"",
    type:'select',
    },
    { key:"topDeadline",
    title:'置顶截止时间',
    checked:true,
    disabled: false,
    value:"",
    type:'input',
    },
    { key:'des',
      title:'内容描述',
      checked:true,
      disabled: false,
      value:"",
      type:'input',
    },
    { key:'submitName',
      title:'提交人',
      checked:true,
      disabled: false,
      value:"",
      type:'input',
    },
    { key:'submitTime',
      title:'提交时间',
      checked:true,
      disabled: false,
      value:"",
      type:'input',
    }
  ];
  doStatus = false;
   url = '/portal/content/Page?pageSize=10000&&pageNum=1';
  //url = '/sec/logNoticelevel/listByPage?pageSize=10000&&pageNum=1'
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private notice: NzNotificationService,
    private api: IndexApiService,
    private activeRouter: ActivatedRoute,
    private http: HttpClient,
    private nation: AutoInternationService,
  ) {
  }
  ngOnInit() {
    this.isVisible = this.isVisibles;
    this.modalTitle=this.showTit;
    //this.changeTem();
    this.init();
  }
  //点击事件
//   @HostListener('document:mousedown', ['$event']) onMousedown(event) {
//     if (event.path[0].localName != 'input'&&event.path[0].localName != 'li'&&event.path[0].localName != 'button') {
//       this.isDown = true;
//       this.disX = event.clientX;
//       this.disY = event.clientY;
//     }
//   }

//   //监听document移动事件事件
//  @HostListener('document:mousemove', ['$event']) onMousemove(event) {
//     //判断该元素是否被点击了。
//     if (this.isDown) {
//       this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
//       this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
//     }
//   }

//   //监听document离开事件
//  @HostListener('document:mouseup', ['$event']) onMouseup(event) {
//     //只用当元素移动过了，离开函数体才会触发。
//     if (this.isDown) {
//       this.totalOffsetX += event.clientX - this.disX;
//       this.totalOffsetY += event.clientY - this.disY;
//       this.isDown = false;
//     }
//   }
  changeTem() {
    this.activeDom = this.drags['elementRef'].nativeElement.children[0].children[0].children[0]
    this.activeDom['style'].position = 'relative';
  }

  handleCancel(){
    this.isVisible=false;
    this.backEventState.emit(this.isVisible);
  }

  handleOk(){
    this.isVisible=false;
    this.backEventState.emit(this.isVisible);
  }
  //初始化页面
  init(){
  }
}
