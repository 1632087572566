import { Component, OnInit, Input, EventEmitter, Output, TemplateRef, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { NzNotificationService, NzModalService, NzTabSetComponent } from 'ng-zorro-antd';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { AutoInternationService } from '../../services/auto-internation.service';
import { IndexApiService } from '../../services/index-api.service';

@Component({
  selector: 'app-common-index-notices',
  templateUrl: './common-index-notices.component.html',
  styleUrls: ['./common-index-notices.component.less']
})
export class CommonIndexNoticesComponent implements OnInit {
  @Input() isVisibles;
  @Input() showTit: any;
  @Output() backEventState = new EventEmitter();
  @ViewChild('drags') drags: ElementRef;
  isDown = false;
  effect = 'scrollx';
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom = '';
  isVisible=false;
  modalTitle: any;

  informTit='notices';//通知
  informStatus=false;
  informUrl='/message/queryUnNotice?pageSize=10000&&pageNum=1';
  informUrl2='/message/queryNotice?pageSize=10000&&pageNum=1';
  informDatas=[
    {
      key:'title',//标题
      title:'noticesTitle',
    },
    {
      key:"contentdes",//内容
      title:'noticesContentdes',
    },
    {
      key:"messageType",//通知类型
      title:'messageType',
    },
    { key:'messageLeaver',//通知范围
      title:'messageLeaver',
    },
    { key:'roundType',//通知级别
      title:'noticesLevel',
    },
    { key:'roundValue',//通知人
      title:'noticesMember',
    },
    { key:'startTime',//开始时间
      title:'startTime',
    },
    { key:'endTime',//结束时间
      title:'endTime',
    }
  ];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private notice: NzNotificationService,
    private api: IndexApiService,
    private activeRouter: ActivatedRoute,
    private http: HttpClient,
    private nation: AutoInternationService,
  ) {
  }
  ngOnInit() {
    this.isVisible = this.isVisibles;
    this.modalTitle=this.showTit;
    this.changeTem();
    this.init();
  }
  //点击事件
  @HostListener('document:mousedown', ['$event']) onMousedown(event) {
    if (event.path[0].localName != 'input'&&event.path[0].localName != 'li'&&event.path[0].localName != 'button') {
      this.isDown = true;
      this.disX = event.clientX;
      this.disY = event.clientY;
    }
  }

  // 监听document移动事件事件
  @HostListener('document:mousemove', ['$event']) onMousemove(event) {
    // 判断该元素是否被点击了。
    if (this.isDown) {
      this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
      this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
    }
  }

  // 监听document离开事件
  @HostListener('document:mouseup', ['$event']) onMouseup(event) {
    // 只用当元素移动过了，离开函数体才会触发。
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
      this.isDown = false;
    }
  }
  changeTem() {
    this.activeDom = this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
    this.activeDom['style'].position = 'relative';
  }

  handleCancel(){
    this.isVisible=false;
    this.backEventState.emit(this.isVisible);
  }

  handleOk(){
    this.isVisible=false;
    this.backEventState.emit(this.isVisible);
  }
  //初始化页面
  init(){
  }
}
