import { Component, OnInit,Input, EventEmitter,Output, ViewChild, ElementRef,HostListener } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common'
import { AutoInternationService } from '../../../commons/services/auto-internation.service';
import { ViewArrangeService } from '../../../view-lay/services/view-arrange.service';

@Component({
	selector: 'app-model-sinple-table',
	templateUrl: './model-sinple-table.component.html',
	styleUrls: ['./model-sinple-table.component.css']
})
export class ModelSinpleTableComponent implements OnInit {
	@Input() isShow: boolean;
	@Input() thData: [];
	@Input() thInfo: any;
	@Output() backEventState = new EventEmitter();
	@ViewChild('drags') drags: ElementRef;
	isLoad = true;
	isDown = false;
	disX; // 记录鼠标点击事件的位置 X
	disY; // 记录鼠标点击事件的位置 Y
	totalOffsetX = 0; // 记录总偏移量 X轴
	totalOffsetY = 0; // 记录总偏移量 Y轴
	activeDom = '';
	isVisible = false;
	allChecked = false;
	indeterminate = false;
	pageTotal = 0;
	thDatas = [];
	tdDatas = [];
	pageSize = 20;
	pageIndex = 1;

	showModeType = ['主数据', '参考数据'];
	showThTit = [];
	showThTiSc = {};
	displayData = [];
	categoryId = "";
	constructor(
		private notice: NzNotificationService,
		private api: ViewArrangeService,
		private nation: AutoInternationService,
		private datePipe: DatePipe
	) { }

	ngOnInit() {
		this.changeTem();
		this.init();
		this.initTeml();
		this.isVisible = this.isShow;
		console.log(this.thData)
	}
	ngOnChanges() {
		this.isVisible = this.isShow;
		this.initTeml();
	}
	init() {
		this.thDatas = this.thData;
		let argsObj = {
			pageNum: this.pageIndex,
			pageSize: this.pageSize,
			modelId: this.thInfo.modelId,
			fromPage: this.thInfo.fromPage
		}
		if (this.thInfo.subListId) {
			argsObj['subListId'] = this.thInfo.subListId;
		}
		this.queryRole(argsObj);
	}
	initTeml() {
		this.showThTit = ['62px', '62px'];
		for (let i = 0; i < this.thData.length-1; i++) {
			this.showThTit.push('180px');
		}
		
		this.showThTiSc = { y: '380px', x: '100%' };
	}
	//字段初始化
	filterDatas(args, obj) {
		let ary = '';
		if (obj != "listSet" && obj != "pageSet" && obj != "modelType") {
			ary = args[obj];
		} else if (obj == 'modelType') {
			ary = this.showModeType[args[obj]]
		} else if (typeof args[obj] == 'number' && args[obj].toString().substring(args[obj].toString().length - 3) == "000" && args[obj].length == '13') {
			ary = this.datePipe.transform(args[obj], 'yyyy-MM-dd');
		}
		return ary;
	}
	queryRole(args) {
		this.isLoad = true;
		let url = this.thInfo.url + '?limit=' + args.pageSize + '&pageNum=' + args.pageNum;
		let obj = {
			modelId: args.modelId,
			fromPage: args.fromPage
		}
		for (let i of this.thDatas) {
			if (i.value != '') {
				obj[i.key] = i.value;
			}
		}
		this.api.handlePost(url, obj).subscribe(res => {
			if (res.code == 200) {
				this.displayData = res.data.list;
				this.pageTotal = res.data.total;
				this.isLoad = false;
			} else {
				this.notice.warning(this.nation.getMessage('general', 'msg'), res.msg);
			}
		}, err => {
			this.notice.warning(this.nation.getMessage('general', 'msg'), err.error.message)
		});
	}
	isCancel() {
		this.isVisible = false;
		let passData = {
			isShow: this.isVisible,
			datas: [],
		}
		this.backEventState.emit(passData);
	}
	isOk() {
		if (this.displayData.filter(value => value.checked === true).length == 0) {
			this.notice.warning(this.nation.getMessage('general', 'msg'), this.nation.getMessage('general', '2'))
		} else if (this.displayData.filter(value => value.checked === true).length == 1) {
			let list = this.displayData.filter(value => value.checked === true)[0];
			this.handleCreData(list);
		} else {
			this.notice.warning(this.nation.getMessage('general', 'msg'), this.nation.getMessage('general', '3'))
		}

	}
	isBlock(args) {
		if (!args.checked) {
			return 'none'
		}
	}
	handleCreData(args) {
		this.isVisible = false;
		let passData = {
			isShow: this.isVisible,
			datas: args,
		}
		this.backEventState.emit(passData);
	}
	changeBg(args, obj) {
		let bgColor = args.currentTarget.style.backgroundColor;
		obj.checked = !obj.checked;
		if (bgColor == "#EEF5FB" || bgColor == 'rgb(238, 245, 251)') {
			args.currentTarget.style.backgroundColor = "";
		} else {
			args.currentTarget.style.backgroundColor = "#EEF5FB"
		}
	}
	checkTdChange(args, obj) {
		args.stopPropagation();
		if (obj.checked) {
			args.currentTarget.parentElement.style.backgroundColor = "#EEF5FB";
		} else {
			args.currentTarget.parentElement.style.backgroundColor = "";
		}
	}
	changeSize(data) {

	}
	//跳到某一页
	goPage(number) {
		this.pageIndex = number;
		let argsObj = {
			pageNum: number,
			pageSize: this.pageSize,
			modelId: this.thInfo.modelId,
			fromPage: this.thInfo.fromPage
		}
		this.queryRole(argsObj);
	}
	/***多选功能** */
	currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
		this.displayData = $event;
		this.refreshStatus();
	}
	refreshStatus(): void {
		const allChecked = this.displayData.filter(value => !value.disabled).every(value => value.checked === true);
		const allUnChecked = this.displayData.filter(value => !value.disabled).every(value => !value.checked);
		this.allChecked = allChecked;
		this.indeterminate = (!allChecked) && (!allUnChecked);
	}
	checkAll(value: boolean): void {
		this.displayData.forEach(data => {
			if (!data.disabled) {
				data.checked = value;
			}
		});
		this.refreshStatus();
	}
	// 点击事件
	@HostListener('document:mousedown', ['$event']) onMousedown(event) {
		this.isDown = true;
		this.disX = event.clientX;
		this.disY = event.clientY;
	}

	// 监听document移动事件事件
	@HostListener('document:mousemove', ['$event']) onMousemove(event) {
		// 判断该元素是否被点击了。
		if (this.isDown) {
			this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
			this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
		}
	}

	// 监听document离开事件
	@HostListener('document:mouseup', ['$event']) onMouseup(event) {
		// 只用当元素移动过了，离开函数体才会触发。
		if (this.isDown) {
			this.totalOffsetX += event.clientX - this.disX;
			this.totalOffsetY += event.clientY - this.disY;
			this.isDown = false;
		}
	}
	changeTem() {
		this.activeDom = this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
		this.activeDom['style'].position = 'relative';
	}


}
