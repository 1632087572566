import {AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NzSubMenuComponent } from 'ng-zorro-antd';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  level = 1;
  @Input() menuItem: any;
  @Input() isCollapsed:boolean;
  @Input() isImg:boolean;
  @ViewChildren(NzSubMenuComponent) nzSubMenus: QueryList<NzSubMenuComponent>;
  @ViewChildren(MenuItemComponent) subMenus: QueryList<MenuItemComponent>;

  ngAfterViewInit() {
    if (this.subMenus.length) {
      this.subMenus
        .filter(x => x !== this)
        .forEach(menu => {
          setTimeout(_ => {
            menu.level = this.level + 1;
            menu.syncNzSubMenusLevel();
          });
        });
    }
  }

  syncNzSubMenusLevel() {
    if (this.nzSubMenus.length) {
      this.nzSubMenus
        .forEach(menu => {
         //  menu.level = this.level;
        });
    }
  }

}
