import { Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { NzFormatEmitEvent, NzTreeNode,NzDropdownContextComponent,
  NzDropdownService,
 
  NzTreeComponent,
   } from 'ng-zorro-antd';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {
  nodes = [ {
    title   : 'parent 1',
    key     : '100',
    expanded: true,
    children: [ {
      title   : 'parent 1-0',
      key     : '1001',
      expanded: true,
      children: [
        { title: 'leaf', key: '10010', isLeaf: true },
        { title: 'leaf', key: '10011', isLeaf: true },
        { title: 'leaf', key: '10012', isLeaf: true }
      ]
    }, {
      title   : 'parent 1-1',
      key     : '1002',
      children: [
        { title: 'leaf', key: '10020', isLeaf: true }
      ]
    }, {
      title   : 'parent 1-2',
      key     : '1003',
      children: [
        { title: 'leaf', key: '10030', isLeaf: true },
        { title: 'leaf', key: '10031', isLeaf: true }
      ]
    } ]
  } ];
  @ViewChild('treeCom') treeCom: NzTreeComponent;
  dropdown: NzDropdownContextComponent;
  activedNode: NzTreeNode;
  nodesTwo = [ {
    title   : 'parent 0',
    key     : '100',
    author  : 'NG ZORRO',
    expanded: true,
    children: [
      { title: 'leaf 0-0', key: '1000', author: 'NG ZORRO', isLeaf: true },
      { title: 'leaf 0-1', key: '1001', author: 'NG ZORRO', isLeaf: true }
    ]
  }, {
    title   : 'parent 1',
    key     : '101',
    author  : 'NG ZORRO',
    children: [
      { title: 'leaf 1-0', key: '1010', author: 'NG ZORRO', isLeaf: true },
      { title: 'leaf 1-1', key: '1011', author: 'NG ZORRO', isLeaf: true }
    ]
  } ];
  constructor(private nzDropdownService: NzDropdownService) { }

  ngOnInit() {
  }
  searchValue(){
    
  }
  nzEvent(event: NzFormatEmitEvent): void {
    
  }
  openFolder(data: NzTreeNode | NzFormatEmitEvent): void {
    // do something if u want
    if (data instanceof NzTreeNode) {
      data.isExpanded = !data.isExpanded;
    } else {
      data.node.isExpanded = !data.node.isExpanded;
    }
  }

  activeNode(data: NzFormatEmitEvent): void {
    if (this.activedNode) {
      // delete selectedNodeList(u can do anything u want)
      this.treeCom.nzTreeService.setSelectedNodeList(this.activedNode);
    }
    data.node.isSelected = true;
    this.activedNode = data.node;
    // add selectedNodeList
    this.treeCom.nzTreeService.setSelectedNodeList(this.activedNode);
  }

  contextMenu($event: MouseEvent, template: TemplateRef<void>): void {
    this.dropdown = this.nzDropdownService.create($event, template);
  }

  selectDropdown(type: string): void {
    this.dropdown.close();
    // do something
  }

}
