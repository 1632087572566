import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginauthService } from './commons/loginauth.service';
import { LoginComponent } from './login/login/login.component';
import { LayoutComponent } from './commons/layout/layout.component';
import { TablesComponent } from './commons/tables/tables.component';
import { MaterielTablesComponent } from './commons/materiel-tables/materiel-tables.component';
import { IndexComponent } from './commons/index/index.component';
import { MdmTablesComponent } from './commons/mdm-tables/mdm-tables.component';
import { IndexYuanComponent } from './commons/index-yuan/index-yuan.component';
import { SelectivePreloadingStrategyService } from './commons/services/selective-preloading-strategy.service'

const adminRoutes: Routes = [
  {
    path: '',
    redirectTo: 'main/indexYuan',
    pathMatch: 'full'
  },
  {
    path: 'index',
    canActivate: [LoginauthService],
    component: IndexComponent,
  },
  {
    path:'indexYuan',
    canActivate: [LoginauthService],
    component: IndexYuanComponent,
  },
  // {
  //   path: 'materiel',
  //   canActivate: [LoginauthService],
  //   loadChildren: './matter/matter.module#MatterModule',预加载
  //    data: { preload: true} 
  // },
  {
    path: 'materiel',
    canLoad: [LoginauthService],
    loadChildren: './matter/matter.module#MatterModule'
  },
  {
    path: 'sysManage',
    canLoad: [LoginauthService],
    loadChildren: './sys-manage/sys-manage.module#SysManageModule'
  },
  {
    path: 'dm',
    canLoad: [LoginauthService],
    loadChildren: './dm/dm.module#DmModule'
  },
  {
    path: 'wf',
    canLoad: [LoginauthService],
    loadChildren: './wf/wf.module#WfModule'
  },
  {
    path: 'md',
    canLoad: [LoginauthService],
    loadChildren: './md/md.module#MdModule'
  },
  {
    path: 'dataCleaning',
    canLoad: [LoginauthService],
    loadChildren: './data-cleaning/data-cleaning.module#DataCleaningModule'
  },
  {
    path: 'integration',
    canLoad: [LoginauthService],
    loadChildren: './integration/integration.module#IntegrationModule'
  },
  {
    path: 'extData',
    canLoad: [LoginauthService],
    loadChildren: './ext-data/ext-data.module#ExtDataModule'
  },
  {
    path: 'quality',
    canLoad: [LoginauthService],
    loadChildren: './quality-manage/quality.module#QualityModule'
  },
  {
      path: 'mdm',
      canLoad: [LoginauthService],
      loadChildren: './mdm/mdm.module#MdmModule'
   }
];

const routes: Routes = [
  // { path: 'login', loadChildren: './login-n/login.module#LoginModule' },
  { path: 'login', loadChildren: './login/login.module#LoginModule' },
  {
    path: 'viewSet',
    loadChildren: './view-lay/view-lay.module#ViewLayModule'
  },
  {
    path: 'main',
    canActivate: [LoginauthService],
    component: LayoutComponent,
    children: adminRoutes,
  },
  {
    path: '',
    redirectTo:  'main/index',
    pathMatch: 'full'
  },

];
// imports: [RouterModule.forRoot(routes,{preloadingStrategy: SelectivePreloadingStrategyService})],
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
