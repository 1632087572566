import { Component, OnInit,Input, EventEmitter,Output ,ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.css'],
  encapsulation: ViewEncapsulation.Native
})
export class CheckboxListComponent implements OnInit {
  checkbox=false;
  @Input() checkMark:any;
  @Output() handleBCheckEvent = new EventEmitter();
  ngOnChanges(){
  
    this.checkbox=this.checkMark;
    
  }
  constructor() { }

  ngOnInit() {
  }
  switchValue(){
   
    this.checkbox=!this.checkbox;
    this.handleBCheckEvent.emit(this.checkbox);
  }
}
