import { Component, OnInit, Input, EventEmitter, Output, TemplateRef, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { NzNotificationService, NzModalService, NzTabSetComponent } from 'ng-zorro-antd';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { AutoInternationService } from '../../services/auto-internation.service';
import { IndexApiService } from '../../services/index-api.service';

@Component({
  selector: 'app-common-index-todolists',
  templateUrl: './common-index-todolists.component.html',
  styleUrls: ['./common-index-todolists.component.less']
})
export class CommonIndexTodolistsComponent implements OnInit {
  @Input() isVisibles;
  @Input() showTit: any;
  @Output() backEventState = new EventEmitter();
  @ViewChild('drags') drags: ElementRef;
  isDown = false;
  effect = 'scrollx';
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom = '';
  isVisible=false;
  modalTitle: any;
  toDoTit = 'statisticsTobedone';//待办统计
  doDetailTit = 'detailsofTodolist';//待办明细
  doDetail = [
    {
      key: "TYPE",
      title: 'standbymode',//待办类型
      type:"",
      value:""
    },
    {
      key: "NAME",
      title: 'name2',//名称
      type:"input",
      value:""
    },
    {
      key: "CREATORNAME",
      title: 'operator',//操作人
      type:"",
      value:""
    },
    {
      key: "LASTOPERTIME",
      title: 'operationTime',//操作时间
      type:"",
      value:""
    },
  ];
  doDetail1 = [
    {
      key: "TYPE",
      title: 'alreadyDoType',//已办类型
      
    },
    {
      key: "NAME",
      title: 'number',//数量
      
    },];
  doStatus = false;
  doUrl = '/statistics/getImportantApproveList?limit=10000&pageNum=1';
  doUrl1 = '/statistics/queryImportantDatas?limit=10000&pageNum=1&queryType=1';
  toDoStatus = false;
  toDoUrl = '/busPro/queryDealtDatas?limit=10000&&pageNum=1';
  toDoDatas = [
    {
      key: "NAME",
      title: 'standbymode',//待办类型
    },
    {
      key: "ONEDAYNUMS",
      title: 'onedays',//1天
    },
    {
      key: "TWODAYNUMS",
      title: 'twodays',//2天
    },
    {
      key: "THREEDAYNUMS",
      title: 'morethanthreedays',//3天以上
    },

    {
      key: 'TOTALNUMS',
      title: 'totalnum',//总数
    },
  ];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private notice: NzNotificationService,
    private api: IndexApiService,
    private activeRouter: ActivatedRoute,
    private http: HttpClient,
    private nation: AutoInternationService,
  ) {
  }
  ngOnInit() {
    this.isVisible = this.isVisibles;
    this.modalTitle=this.showTit;
    //this.changeTem();
    this.init();
  }
  //点击事件
  // @HostListener('document:mousedown', ['$event']) onMousedown(event) {
  //   if (event.path[0].localName != 'li' || event.path[0].localName != 'a') {
  //     this.isDown = true;
  //     this.disX = event.clientX;
  //     this.disY = event.clientY;
  //   }
  // }

  // 监听document移动事件事件
 // @HostListener('document:mousemove', ['$event']) onMousemove(event) {
    // 判断该元素是否被点击了。
  //   if (this.isDown) {
  //     this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
  //     this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
  //   }
  // }

  // 监听document离开事件
 // @HostListener('document:mouseup', ['$event']) onMouseup(event) {
    // 只用当元素移动过了，离开函数体才会触发。
  //   if (this.isDown) {
  //     this.totalOffsetX += event.clientX - this.disX;
  //     this.totalOffsetY += event.clientY - this.disY;
  //     this.isDown = false;
  //   }
  // }
  // changeTem() {
  //   this.activeDom = this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
  //   this.activeDom['style'].position = 'relative';
  // }

  handleCancel(){
    this.isVisible=false;
    this.backEventState.emit(this.isVisible);
  }

  handleOk(){
    this.isVisible=false;
    this.backEventState.emit(this.isVisible);
  }
  //初始化页面
  init(){
  }
}
