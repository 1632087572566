import { Component, OnInit,Input, EventEmitter,Output,TemplateRef, ViewChild, ElementRef,HostListener} from '@angular/core';
import { initDomAdapter } from '@angular/platform-browser/src/browser';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-help-details',
  templateUrl: './help-details.component.html',
  styleUrls: ['./help-details.component.less']
})
export class HelpDetailsComponent implements OnInit {
  @Input() isShow:any;
  @Input() thDatas:any;
  @Input() tit:any;
  @Input() showWh:any;
  @Input() mdmLists:any;
  @Output() handleBackEvent = new EventEmitter();
  @ViewChild('drags') drags: ElementRef;
  isDown = false;
  createTime='';
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom='';
  isVisible = false;
  wh="600px";
  title="";
  mdmList=[];
  thData=[];
  nouseType=[
    {
      label:"否",
      value:'0',
    },
    {
      label:"是",
      value:'1',
    },
  ];
  statusType=[
    {
      label:"停用",
      value:'0',
    },
    {
      label:"启用",
      value:'1',
    }
  ];
  applyStatus=[
    {
      label:"新增",
      value:'0',
    },
    {
      label:"有错误",
      value:'1',
    },
    {
      label:"校验有错误",
      value:'2',
    },
    {
      label:"待提交",
      value:'3',
    },
    {
      label:"审核通过",
      value:'4',
    },
    {
      label:"待审核",
      value:'5',
    },
    {
      label:"审核驳回",
      value:'6',
    },
  ];
  showType=[
    {
      label:"",
      value:""
    },
    {
      label:"新增",
      value:"1"
    },
    {
      label:"变更",
      value:"2"
    },
    {
      label:"解冻",
      value:"3"
    },
    {
      label:"冻结",
      value:"4"
    },
  ];
  isFileUpload=false;
  passUpload={
    isEdit:false,
    type: 'WD_WD',
    fileId: '',
    thDatas:[
     {
       key:"fileName",
       title:'附件名称',
       checked:true,
       disabled: false,
     },
     {
       key:"fileSize",
       title:'附件大小',
       checked:true,
       disabled: false,
     },
     {
       key:"createTime",
       title:'上传时间',
       checked:true,
       disabled: false,
     },
     {
       key:"creatorName",
       title:'上传人员',
       checked:true,
       disabled: false,
     },
    ],
    showTit:'附件详情',
    wh:'800',
    getUrls:'/file/list',
    seeUrls:'/file/preview',
    delUrls:'/file/deleteByFileId',
    uploadUrls:'/file/upload',
    downloadUrls:'/file/download',
    //showId:this.parentId,
   };
  constructor(private datePipe: DatePipe,) { }
  ngOnInit() {
    this.isVisible=this.isShow;
    this.wh=this.showWh;
    this.title=this.tit;
    this.mdmList=this.mdmLists;
    this.passUpload.fileId=this.mdmList['fileId'];
    this.init();
    this.thData=this.thDatas;
    this.changeTem();
  }
  ngOnChanges(){
    this.isVisible=this.isShow;
    this.wh=this.showWh;
    this.title=this.tit;
    this.mdmList=this.mdmLists;
    this.thData=this.thDatas;
  }
  init(){
     if(this.mdmList['createTime']!=null){
       this.createTime = this.datePipe.transform(this.mdmList['createTime'],'yyyy-MM-dd HH:mm:ss');
     }
     if(this.mdmList['topDeadline']!=null){
       this.createTime = this.datePipe.transform(this.mdmList['topDeadline'],'yyyy-MM-dd HH:mm:ss');
     }
  }
   // 点击事件
   @HostListener('document:mousedown', ['$event']) onMousedown(event) {
    if(event.path[0].localName!='input'&&!this.isFileUpload){
      this.isDown = true;
      this.disX = event.clientX;
      this.disY = event.clientY;
    }
  }

  // 监听document移动事件事件
  @HostListener('document:mousemove', ['$event']) onMousemove(event) {
    // 判断该元素是否被点击了。
    if (this.isDown) {
      this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
      this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
    }
  }

  // 监听document离开事件
  @HostListener('document:mouseup', ['$event']) onMouseup(event) {
    // 只用当元素移动过了，离开函数体才会触发。
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
      this.isDown = false;
    }
  }
 changeTem(){
  this.activeDom=this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
  this.activeDom['style'].position = 'relative';
 }
  handleCancel(): void {
    this.isVisible = false;
    this.handleBackEvent.emit(this.isVisible);
  }
  handleOk(): void {
    this.isVisible = false;
    this.handleBackEvent.emit(this.isVisible);
  }
  changeTime(args){
    let ary =  new Date(args);
    return ary;
  }
  getUploadState(args){
    this.isFileUpload=args;
  }
  fileUpload(){
   this.passUpload.isEdit = true;
   this.isFileUpload=true;
  }
}
