import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { AutoInternationService } from '../../services/auto-internation.service';
import { HttpService } from '../../../sys-manage/services/http.service';

@Component({
	selector: 'app-advanced-search',
	templateUrl: './advanced-search.component.html',
	styleUrls: ['./advanced-search.component.css']
})
export class AdvancedSearchComponent implements OnInit {
	@Input() isShownAdvanced: any;
	@Input() tableName: any;
	@Output() handleBackEventAd = new EventEmitter();
	@Output() handleBackAdData = new EventEmitter();
	@ViewChild('drags') drags: ElementRef;
	isVisible = false;
	isDown = false;
	disX; // 记录鼠标点击事件的位置 X
	disY; // 记录鼠标点击事件的位置 Y
	totalOffsetX = 0; // 记录总偏移量 X轴
	totalOffsetY = 0; // 记录总偏移量 Y轴
	activeDom = '';
	dateData = null;
	num = 0;
	Value = '';
	Value2 = '';
	advUrl = '/AdvancedQuery/getAdvancedListByTableName';
	relationList = [
		{ label: '并且', value: '0', },
		{ label: '或者', value: '1', }
	]
	conditionList = [
		{ label: '是' , value: '1' },
		{ label: '不是', value: '2' },
		{ label: '开头为', value: '3' },
		{ label: '包含' , value: '4'},
		{ label: '大于', value: '5' },
		{ label: '小于', value: '6' },
		{ label: '大于等于', value: '7' },
		{ label: '小于等于', value: '8'},
		{ label: '介于', value: '9' },
		{ label: '为空', value: '10' },
		{ label: '非空', value: '11' },
		{ label: '多值查询', value: '12' },
	];
	advSelectList = [];
	advSearchData = [];
	stateSelect = {
		'STATUS':[
			{label: '停用', value: '0'},
			{label: '启用', value: '1'}
		],
		'ZQ':[
			{label: '否', value: '0'},
			{label: '是', value: '1'}
		],
		'FHTYPE':[
			{label: '前置符号', value: '1'},
			{label: '后置符号', value: '2'},
			{label: '连接符号', value: '3'}
		],
		'STATE':[
			{label: "新增", value: '0'},
			{label: "有错误", value: '1'},
			{label: "校验有错误", value: '2'},
			{label: "待提交", value: '3'},
			{label: "审核通过", value: '4'},
			{label: "待审核", value: '5'},
			{label: "审核驳回", value: '6'}
		],
		'NOUSE':[
			{label: '启用', value: '0'},
			{label: '停用', value: '1'}
		],
		// 'pickerTab':['基本设置','数据设置','校验设置'],
		// 'dataTab':['数据设置'],
	}
	constructor(
		private httpS: HttpService,
		private nation: AutoInternationService,
		private notice: NzNotificationService,
	) { }
	ngOnChanges() {
		this.isVisible = this.isShownAdvanced;
		this.init();
	}
	ngOnInit() {
		this.notice.config({
			nzTop: "100px",
			nzMaxStack: 1
		});
		this.changeTem();
	}
	init() {
		let url = this.advUrl + '?tableName=' + this.tableName;
		this.httpS.httpPost(url, {}).subscribe(data => {
			if (data.code == 200) {
				this.advSelectList = data.data;
				this.add();
			} else {
				this.notice.error(this.nation.getMessage('general', 'msg'), data.msg);
			}
		}, err => {
			this.notice.warning(this.nation.getMessage('general', 'msg'), err.error.message);
		})
	}

	handleOk() {
		this.handleBackAdData.emit(this.postDatas(this.advSearchData));
		this.isVisible = false;
		this.handleBackEventAd.emit(this.isVisible);
	}
	handleCancel() {
		this.isVisible = false;
		this.handleBackEventAd.emit(this.isVisible);
	}
	add() {
		if (this.num < this.advSelectList.length - 1) {
			this.num = this.advSearchData.length;
			let Value = '';
			if (this.advSelectList[this.num].fieid_Name == 'STATUS' || this.advSelectList[this.num].fieid_Name == 'ZQ' || this.advSelectList[this.num].fieid_Name == 'FHTYPE' || this.advSelectList[this.num].fieid_Name == 'STATE' || this.advSelectList[this.num].fieid_Name == 'NOUSE') {
				Value = this.stateSelect[this.advSelectList[this.num].fieid_Name][0].value;
			} else {
				Value = '';
			}
			let obj = {
				advFields: this.advSelectList[this.num],
				condition: this.conditionList[0].value,
				Value: Value
			}
			// if (this.advSearchData.length > 0) {
			// 	this.advSearchData[this.num-1]['relation'] = this.relationList[0].value;
			// }
			this.advSearchData.push(obj)
		}
	}
	del(args,e: MouseEvent) {
		e.preventDefault();
		if (this.advSearchData.length > 1) {
			const index = this.advSearchData.indexOf(args);
			this.advSearchData.splice(index, 1);
		}
	}
	postDatas(args) {
		let conds = [];
		let dateValue: any;
		let dateValue2: any;
		for (let i in args) {
			if (args[i].advFields.type == 'date') {
				if (args[i].Value != '') {
					dateValue = new Date(args[i].Value).getTime();
				}
			} else {
				dateValue = args[i].Value
			}
			if (args[i].advFields.type != 'date' && args[i].condition == '9') {
				let obj = {
					fields: args[i].advFields.fieid_Name, 
					type: args[i].advFields.type, 
					condition: args[i].condition, 
					value: dateValue, 
					value2: this.Value2
				}
				conds.push(obj)
			} else {
				if (this.Value2 != '') {
					dateValue2 = new Date(this.Value2).getTime();
				} else {
					dateValue2 = '';
				}
				let obj = {
					fields: args[i].advFields.fieid_Name, 
					type: args[i].advFields.type, 
					condition: args[i].condition, 
					value: dateValue, 
				}
				if(args[i].condition == '9'){
					obj=Object.assign({value2: dateValue2},obj);
				}
				conds.push(obj)
			}
			// if (args[i].relation) {
			// 	conds.push({relation: args[i].relation});
			// }
		}
		let objs = {
			id: 'advanced',
			value: {
				conds: conds
			}
		}
		return objs
	}
	changeName(args,obj){
		if(args.fieid_Name == 'STATUS') {
			obj.Value = this.stateSelect[args.fieid_Name][0].value;
		}else if(args.fieid_Name == 'STATE'){
			obj.Value = this.stateSelect[args.fieid_Name][0].value;
		} else if(args.fieid_Name == 'ZQ'){
			obj.Value = this.stateSelect[args.fieid_Name][0].value;
		} else if(args.fieid_Name == 'FHTYPE') {
			obj.Value = this.stateSelect[args.fieid_Name][0].value;
		} else if(args.fieid_Name == 'NOUSE') {
			obj.Value = this.stateSelect[args.fieid_Name][0].value;
		}
	}
	// 点击事件
	@HostListener('document:mousedown', ['$event']) onMousedown(event) {
		if(event.path[0].localName!='input' && event.path[0].localName!='select' && event.path[0].localName!='button'){
			this.isDown = true;
			this.disX = event.clientX;
			this.disY = event.clientY;
		}
	}

	// 监听document移动事件事件
	@HostListener('document:mousemove', ['$event']) onMousemove(event) {
		// 判断该元素是否被点击了。
		if (this.isDown) {
			this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
			this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
		}
	}

	// 监听document离开事件
	@HostListener('document:mouseup', ['$event']) onMouseup(event) {
		// 只用当元素移动过了，离开函数体才会触发。
		if (this.isDown) {
			this.totalOffsetX += event.clientX - this.disX;
			this.totalOffsetY += event.clientY - this.disY;
			this.isDown = false;
		}
	}
	changeTem() {
		this.activeDom = this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
		this.activeDom['style'].position = 'relative';
	}
}
