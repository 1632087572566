import { Component, OnInit,Input } from '@angular/core';
import { NzNotificationService,NzModalRef, NzModalService } from 'ng-zorro-antd';
import { AutoInternationService } from '../../../commons/services/auto-internation.service';
import { IndexApiService } from '../../services/index-api.service';
import { Routes, RouterModule,Router } from '@angular/router';
import { DmModalNameService }from '../../../dm/services/dm-modal-name.service';
import {TranslateService} from '@ngx-translate/core';
import { ButtonMenuService }from '../../services/button-menu.service';
@Component({
  selector: 'app-index-general',
  templateUrl: './index-general.component.html',
  styleUrls: ['./index-general.component.css']
})
export class IndexGeneralComponent implements OnInit {
  @Input() showTit:any;
  @Input() url:any;
  @Input() thData:any;
  @Input() status:any;
  showTits="";
  urls='';
  content='';
  state=false;
  pageIndex = 1;
  pageSize = 5;
  dataSet = [];
  displayData = [];
  dataList=[];
  thDatas=[];
  passDetails={
    tit:'文档详细信息',
    showWh:'500px',
    mdmLists:[],
  };
  isDetails=false;
  listOfBs=[
    {
      label:"不重要",
      value:'0',
    },
    {
      label:"重要",
      value:'1',
    },
  ];
  lang: string;
  lang2: string;
  statusLists = [
    {
      label: "",
      value: ""
    },
    {
      label: "启用",
      value: "1"
    },
    {
      label: "已作废",
      value: "0"
    },
  ];
  statusList=[ {
    label: "启用",
    value: "1"
  },
  {
    label: "停用",
    value: "0"
  },]
  stateLists = [
    {
      label: "",
      value: ""
    },
    {
      label: "未发布",
      value: "0"
    },
    {
      label: "已发布",
      value: "4"
    },
  ];
  list = {
    "messageType": { "A": "系统公告", "B": "待审提醒", "C": "审核结果通知", "D": "接口下发预警通知", },
    "messageLeaver": { "A": "重要紧急", "B": "重要不紧急", "C": "紧急不重要", "D": "不重要不紧急", },
    "roundType": { "A": "所有人", "B": "用户组", "C": "操作人", }
  };
  isLoad=false;
  constructor(
    private notice:NzNotificationService,
    private nation:AutoInternationService,
    private api:IndexApiService,
    private router:Router,
    private getM:DmModalNameService,
    private translateSe:TranslateService,
    private getbutton:ButtonMenuService,
  ) { }

  ngOnInit() {
    this.thDatas=this.thData;
    this.urls=this.url;
    this.state=this.status;
    this.init();
  }
  ngOnChanges(){
    this.state=this.status;
    this.lang2 = sessionStorage.getItem('sys-lang')=='ZH'?'ZH':'EN';
    if(this.state||(this.lang!=this.lang2)){
      this.init();
    }
  }
  init(){
      let obj={};
      this.lang = sessionStorage.getItem('sys-lang')=='ZH'?'ZH':'EN';
      this.lang2  = sessionStorage.getItem('sys-lang')=='ZH'?'ZH':'EN';
      if(this.showTit=='statisticsTobedone'){//待办统计
        obj={
          type:'wf',
          args:{},
        };
      }else if(this.showTit=='systemstandard'){//标准
        obj={
          type:'sysManage',
          args:{state:"4",orgId:"1",status:"1"},
          //args:{},
        };
      }else if(this.showTit=='system'){//制度
        obj={
          type:'sysManage',
          args:{state:"4",orgId:"0",status:"1"},
          //args:{},
        };
      }else if(this.showTit=='notices'){//通知
        obj={
          type:'sysManage',
          args:{},
        };
      }else if(this.showTit=='detailsofTodolist'){//待办明细
        obj={
          type:'wf',
          args:[]
        };
        for(let i of this.thDatas){
          let obj1={id:i.key,value:i.value};
          if(i.value!==""&&i.value!==null){
            obj['args'].push(obj1) ;
          }
        }

      }else if(this.showTit=='statisticsTobedone'){//待办明细
        obj={
          type:'wf',
          args:{}
        };
        for(let i of this.thDatas){
          if(i.value!=""){
            obj['args'][i.key]=i.value;
          }
        }

      }

      if(this.urls !=''){
        this.isLoad=true;
        this.api.handlePost(this.urls,obj).subscribe(res => {
          let ary = this.showTit;
          if(res.code==200){
            if(res.data != null){
              if(this.showTit=='system' || this.showTit=='systemstandard' || this.showTit=='notices'){
                this.displayData=res.data.list;
                this.isLoad=false;
              }else{
                this.displayData=res.data;
                this.isLoad=false;
              }
              
            }
          }else{
            this.notice.warning(this.nation.getMessage('general','msg'),res.msg);
          }
        },
         err => {
          this.notice.warning(this.nation.getMessage('general','msg'),err.error.message)
        },
        );
      }
  }
  //字段初始化
  filterDatas(args,obj){
    let ary=args[obj];
    if(obj == "contentText"|| obj == "contentName"|| obj=='submitTime'||obj=='important'||obj=='createTime'||obj=='TYPE'||obj=='topDeadline'||obj=='startTime'||obj=='endTime'||obj=='isTop'){
      ary='';
    }
    if(this.showTit=='notices'){
     if (obj == "status") {
        ary = this.statusLists.find(value => value.value == args[obj])['label'];
      } else if (obj == "state") {
        ary = this.stateLists.find(value => value.value == args[obj])['label'];
      } else if (obj == "messageType" || obj == "messageLeaver" || obj == "roundType") {
        ary = this.list[obj][args[obj]];
      }else if(obj == "roundValue"){
        if(args[obj]=='A'){
          ary='所有人';
        }
      }
    }else if(this.showTit=='system'||this.showTit=='systemstandard'){
      if (obj == "status") {
         ary = this.statusList.find(value => value.value == args[obj])['label'];
       }
    }
    return ary;
  }
  changeName(args,obj){
    let ary=args[obj];
    if(obj=='NAME'){
      ary=args[obj].split("_")[0];
    }
    return ary;
  }
   //表格从小到大序号
   changeIndexPage(args){
    let index=0;
    if(this.pageIndex==1){
      index=args;
    }else if(this.pageIndex>1){
      index=(Number(this.pageIndex)-1)*Number(this.pageSize)+Number(args);
    }
    return index;
  }
  changeUrl(){
    if(this.showTit=='notices'||this.showTit=='systemstandard'){//通知或制度标准
      // let arr=["main/sysManage/sysDocumentQuery"];
      // this.router.navigate(arr);
    }
  }
    //点击文档名称时,弹框显示内容
    showContent(args){
      this.content=args.contentText;
    }
    showModelDetail(args1,obj){
      let ary=args1['BUSTABLE'].split("_")[0];
      let arr=[];
      if(ary=="WL"){
        if(args1[obj]=="物料模板审核"){
          arr=["main/materiel/approveTemplate"];
        }else if(args1[obj]=='物料模板审核驳回'){
          arr=["main/materiel/createTemplate"];
        }else if(args1[obj]=='物料模板变更驳回'){
          arr=["main/materiel/changeTemplate"];
        }else if(args1[obj]=='物料代码审核'){
          arr=["main/materiel/approveMaterial"];
        }else if(args1[obj]=='物料代码审核驳回'){
          arr=["main/materiel/createMaterial"];
        }else if(args1[obj]=='物料代码变更驳回'){
          arr=["main/materiel/changeMaterial"];
        }else if(args1[obj]=='物料类别审核'){
          arr=["main/materiel/typeAudit"];
        }else if(args1[obj]=='物料类别审核驳回'){
          arr=["main/materiel/typeApply"];
        }else if(args1[obj]=='物料类别变更驳回'){
          arr=["main/materiel/typeEdit"];
        }
        this.router.navigate(arr);
      }else if(ary=="T"){
        let str=args1[obj].substring(args1[obj].length-2);
        if(str=="审核"){
          arr=["main/dm/dmDataAudit"]
        }else{
          let str1=args1[obj].substring(args1[obj].length-4);
          if(str1=="审核驳回"){
            arr=["main/dm/dmDataApply"]
          }else if(str1=="变更驳回"){
            arr=["main/dm/dmDataEdit"]
          }
        }
        obj={
          type:'mdmManage',
          args:{}
        };
        let url="/model/getById?id="+args1['BUSID']
        this.api.handlePost(url,obj).subscribe(res => {
          if(res.code==200){
            if(res.data.name){
              let obj1={
                name:res.data.name,
                code:res.data.code,
                id:args1['BUSID']
              }
              if( arr[0]=="main/dm/dmDataApply"){
                this.getbutton.getModalInfo(obj1);
              }else if(arr[0]=="main/dm/dmDataEdit"){
                this.getbutton.getEdit(obj1);
              }else if(arr[0]=="main/dm/dmDataAudit"){
                this.getbutton.getAudit(obj1);
              }
               //this.getM.getModalInfo(obj1);
               this.router.navigate(arr);
            }

          }else{
            this.notice.warning(this.nation.getMessage('general','msg'),res.msg);
          }
        })
      }

    }
     //显示详情
  showView(args){
    this.passDetails.mdmLists=[];
    this.passDetails.mdmLists=args;
    this.isDetails=true;
  }
  backDetails(args){
    this.isDetails=args;
  }
}
