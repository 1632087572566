

import { Component, OnInit,Input, EventEmitter,Output,TemplateRef, ViewChild, ElementRef,HostListener} from '@angular/core';
import { UploadService } from '../../services/upload.service';
import { NzNotificationService,NzModalService } from 'ng-zorro-antd';
import { FakeMissingTranslationHandler } from '@ngx-translate/core';

@Component({
  selector: 'app-approval-log',
  templateUrl: './approval-log.component.html',
  styleUrls: ['./approval-log.component.css']
})
export class ApprovalLogComponent implements OnInit {
  @Input() versionHead:any;
  @Input() title:any;
  @Input() isVersion:any;
  @Input() postBody:any;
  @Output() handleEvent = new EventEmitter();

  @ViewChild('drags') drags: ElementRef;
  /***********
   * thData:表格表头数据
   * tit：模态框标题
   * showWh：模态框宽度
   * urls：表格数据展示url
   * isShow：表格是否展示
   * postBody：表格数据展示POST请求体，数据类型是数组
   * search：是否展示搜索框部分
   * isOk:确定是否有有其余操作
   * searchUrls：搜索框的内容操作url(废弃)
   * searchUrlsBody:搜索框的内容操作url的POST请求体，数据类型是数组(废弃)
   * handleBackEvent：事件返回
   * handleKeepEvent：事件返回,数据保存
   * operationType:选择请求的类型，2:选择编辑、3:选择待活动，4、选择待冻结
   * types:请求类型 WLLB:物料类别 WLDR：物料代码
   * handleSucStateEvent:事件返回成功或者失败状态
   *  */
  isLoad=true;
  allChecked = false;
  indeterminate = false;
  pageIndex = 1;
  pageSize =3;
  pageTotal = 0;
  dataSet = [];
  displayData = [];
  sortName = null;
  sortValue = null;
  isVisible = false;
  tabs=[{index:0,title:"当前审批意见"},{index:1,title:"历史审批意见"}]
  wh="1200px";
  url="/hisApprove/getApproveOpinions";
  thDatas=[];
  postBodys=[];
  showStatus=['活动','停用'];
  statusTypes=[
    {
      label:'停用',
      value:'0'
    },
    {
      label:'启用',
      value:'1'
    },
  ];
  showState=[
    {
      label:'新增',
      value:'0'
    },
    {
      label:'校验无错误',
      value:'1'
    },
    {
      label:'校验有错误',
      value:'2'
    },
    {
      label:'审核拒绝',
      value:'3'
    },
    {
      label:'审核通过',
      value:'4'
    },
    {
      label:'审核中',
      value:'5'
    },
  ];
  stateTask={
    0:'送审',
    1:'待审核',
    2:'审核完成',
  }
  passType={
    0:'拒绝',
    1:'同意',
  }
  opers={
    1:'新增',
    2:'修改',
    3:'启用',
    4:'停用'
  }
  isDown = false;
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom='';
  displayData1=[];
  showThTit=[];
  showThTiSc:any={};
  constructor(private api:UploadService,private notice:NzNotificationService,) { }
  ngOnInit() {
    this.isVisible=this.isVersion;
    this.thDatas=this.versionHead;
    this.postBodys=this.postBody;
    this.changeTem();
    this.initTeml();
    if(this.postBodys){
      this.queryRole();
      //this.queryRole1();
    }
  }
  // ngOnChanges(){
  //   this.isVisible=this.isVersion;
  //   this.thDatas=this.versionHead;
  //   this.postBodys=this.postBody;
  //   if(this.postBodys){
  //     this.queryRole();
  //     //this.queryRole1();
  //   }
  // }
  initTeml(){
      this.showThTit=['50px','70px','150px','140px','140px','100px','100px','100px','100px','80px'];
      this.showThTiSc={ y: '400px' + 'px',x:'100%' };
  }
  // 点击事件
  @HostListener('document:mousedown', ['$event']) onMousedown(event) {
    if(event.path[0].localName!='input'){
      this.isDown = true;
      this.disX = event.clientX;
      this.disY = event.clientY;
    }
  }
  // 监听document移动事件事件
  @HostListener('document:mousemove', ['$event']) onMousemove(event) {
    // 判断该元素是否被点击了。
    if (this.isDown) {
      this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
      this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
    }
  }

  // 监听document离开事件
  @HostListener('document:mouseup', ['$event']) onMouseup(event) {
    // 只用当元素移动过了，离开函数体才会触发。
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
      this.isDown = false;
    }
  }
 changeTem(){
  this.activeDom=this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
  this.activeDom['style'].position = 'relative';
 }
  handleCancel(): void {
    this.isVisible = false;
    this.handleEvent.emit(this.isVisible);
  }
  handleOk(): void {
  
      this.isVisible = false;
      this.handleEvent.emit(this.isVisible);
    
  }
  queryRole () {
    this.isLoad=true;
    let obj=[];
    if(this.postBodys){
      for(let i of this.postBodys){
        // if(i.id=='queryType'){
        //   i.value="0"
        // }
        obj.push(i);
      }
      //obj=this.postBodys;
    }
    for(let i of this.thDatas){
      let obj1={id:i.key,value:i.value};
      if(i.value!==""){
        obj.push(obj1) ;
      } 
    }
    this.api.handleLog(this.url,obj).subscribe(res => {
      if(res.code==200){
        if(res.data!=null){
          this.displayData=res.data.approveDate;
        }
        this.isLoad=false;
      }else{
        this.notice.error("提示",res.msg);
      }
    },
    err => {
      this.notice.warning( '提示',err.error.message)
    })
  }
  queryRole1 () {
    this.isLoad=true;
    let obj=[];
    if(this.postBodys){
      for(let i of this.postBodys){
        if(i.id=='queryType'){
          i.value="1"
        }
        obj.push(i);
      }
      //obj=this.postBodys;
    }
    for(let i of this.thDatas){
      let obj1={id:i.key,value:i.value};
      if(i.value!==""){
        obj.push(obj1) ;
      } 
    }
    this.api.handleLog(this.url,obj).subscribe(res => {
      if(res.code==200){
        if(res.data!=null){
          this.displayData1=res.data.approveDate;
        }
        this.isLoad=false;
      }else{
        this.notice.error("提示",res.msg);
      }
    },
    err => {
      this.notice.warning( '提示',err.error.message)
    })
  }
  /***多选功能** */
  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.displayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.displayData.filter(value => !value.disabled).every(value => value.checked === true);
    const allUnChecked = this.displayData.filter(value => !value.disabled).every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }
  changeSize(data){
    if(this.pageSize!=data){
      this.pageIndex=1;
      let argsObj={
        pageNum:1,
        pageSize:data,
      }
      //this.queryRole(argsObj);
    }
  }
  checkAll(value: boolean): void {
    this.displayData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }
  sortDown(args,$event){
    $event.stopPropagation();
    if(args&&this.dataSet.length!=0){
      this.displayData=[]; 
      let das = this.dataSet.sort((a, b) =>  (b[ args] > a[ args ] ? 1 : -1));
      this.displayData=[];
    } 
  }
  sortUp(args,$event){
    $event.stopPropagation();
    if(args&&this.dataSet.length!=0){
      this.displayData=[];
      let das = this.dataSet.sort((a, b) =>  (a[ args ] > b[ args ] ? 1 : -1));
      this.displayData=[...das];
    } 
  }
  changeIndexPage(args){
    let index=0;
    if(this.pageIndex==1){
      index=args;
    }else if(this.pageIndex>1){
      index=(Number(this.pageIndex)-1)*Number(this.pageSize)+Number(args);
    }
   
    return index;
  }
  goPage(number) {
    this.pageIndex=number;
    let argsObj={
      pageNum:number,
      pageSize:this.pageSize,
    }
    //this.queryRole(argsObj);
  }
  filterDatas(args,obj){
    let ary=args[obj];
    if(obj=='nouse'){
      ary=this.showStatus[args[obj]];
    }else if(obj=='state'){
      ary=this.showState[args[obj]].label;
    }else if(obj=='status'){
      ary=this.statusTypes[args[obj]].label;
    }else if(obj=='taskState'){
      ary=this.stateTask[args[obj]];
    }else if(obj=='pass'){
      ary=this.passType[args[obj]];
    }else if(obj=='operType'){
      ary=this.opers[args[obj]];
    }else if(obj=='createTime'||obj=='create_time'){
      ary='';
    }
    return ary;
  }

}

