import { Component, OnInit, Input, EventEmitter, Output, TemplateRef, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { NzNotificationService, NzModalService, NzTabSetComponent } from 'ng-zorro-antd';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { AutoInternationService } from '../../services/auto-internation.service';
import { IndexApiService } from '../../services/index-api.service';
@Component({
  selector: 'app-help-manage',
  templateUrl: './help-manage.component.html',
  styleUrls: ['./help-manage.component.css']
})
export class HelpManageComponent implements OnInit {
  @Input() isVisibles;
  @Input() Data: any;
  @Output() backEventState = new EventEmitter();
  @ViewChild('drags') drags: ElementRef;
  isDown = false;
  effect = 'scrollx';
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom = '';
  isVisible=false;
  modalTitle='';

  mdmDatas=[];
  allChecked = false;
  indeterminate = false;
  pageIndex = 1;
  pageSize = 10;
  pageTotal = 0;
  dataSet = [];
  sorts="";
  order="";
  displayData = [...this.dataSet];
  sortName = null;
  sortValue = null;
  bodyHeight = null;
  isShow=false;
  title="";
  thDatas=[
    {
      key:"status",
      title:'状态',
      checked:true,
      disabled: false,
      value:"",
      type:"select",
    },
    { key:"contentName",
      title:'文档名称',
      checked:true,
      disabled: false,
      value:"",
      type:'input',
    },
    { key:'contentText',
    title:'内容',
    checked:true,
    disabled: false,
    value:"",
    type:'input',
    },
    { key:'forumName',
    title:'栏目名称',
    checked:true,
    disabled: false,
    value:"",
    type:'input',
    },
    { key:"keyword",
      title:'关键字',
      checked:true,
      disabled: false,
      value:"",
      type:'input',
    },
    { key:"isTop",
    title:'是否置顶',
    checked:true,
    disabled: false,
    value:"",
    type:'select',
    },
    { key:"topDeadline",
    title:'置顶截止时间',
    checked:true,
    disabled: false,
    value:"",
    type:'input',
    },
    { key:'des',
      title:'内容描述',
      checked:true,
      disabled: false,
      value:"",
      type:'input',
    }, 
    { key:'submitName',
      title:'提交人',
      checked:true,
      disabled: false,
      value:"",
      type:'input',
    },
    { key:'submitTime',
      title:'提交时间',
      checked:true,
      disabled: false,
      value:"",
      type:'input',
    }
  ];
  showType=[
    {
      label:"新增",
      value:"1"
    }, 
    {
      label:"变更",
      value:"2"
    },
    {
      label:"解冻",
      value:"3"
    },
    {
      label:"冻结",
      value:"4"
    }
  ]
  flag:number;
  isLoad=false;
  btnInfo=[];
  showThTit=[];
  showThTiSc={};
  isDetails: boolean=false;
  passDetails={
    tit:'文档详细信息',
    showWh:'500px',
    mdmLists:[],
  };
  typeStatus=[
      {
        label:"停用",
        value:'0',
      },
      {
        label:"启用",
        value:'1',
      },
  ]
  listOfBs=[
    {
      label:"否",
      value:'0',
    },
    {
      label:"是",
      value:'1',
    },
  ];
  orgId: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private notice: NzNotificationService,
    private api: IndexApiService,
    private activeRouter: ActivatedRoute,
    private http: HttpClient,
    private nation: AutoInternationService,
  ) {
  }
  ngOnInit() {
    this.isVisible = this.isVisibles;
    this.modalTitle=this.Data.showTit;
    this.orgId=this.Data.orgId;
    this.changeTem();
    this.init();
  }
  //点击事件
  @HostListener('document:mousedown', ['$event']) onMousedown(event) {
    if (event.path[0].localName != 'input'&&!this.isDetails) {
      this.isDown = true;
      this.disX = event.clientX;
      this.disY = event.clientY;
    }
  }

  // 监听document移动事件事件
  @HostListener('document:mousemove', ['$event']) onMousemove(event) {
    // 判断该元素是否被点击了。
    if (this.isDown) {
      this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
      this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
    }
  }

  // 监听document离开事件
  @HostListener('document:mouseup', ['$event']) onMouseup(event) {
    // 只用当元素移动过了，离开函数体才会触发。
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
      this.isDown = false;
    }
  }
  changeTem() {
    this.activeDom = this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
    this.activeDom['style'].position = 'relative';
  }

  handleCancel(){
    this.isVisible=false;
    this.backEventState.emit(this.isVisible);
  }

  handleOk(){
    this.isVisible=false;
    this.backEventState.emit(this.isVisible);
  }
  initTeml(){
    if(window.screen.width>=1366&&window.screen.width<1600){
      this.bodyHeight = window.screen.height - 475;
      this.showThTit=['62px','62px','100px','100px','100px','100px','100px'];
      this.showThTiSc={ y: this.bodyHeight + 'px'};
    }else if(window.screen.width>=1600&&window.screen.width<1800){
      this.bodyHeight = window.screen.height - 480;
      this.showThTit=['62px','62px','100px','100px','100px','100px','100px'];
      this.showThTiSc={ y: this.bodyHeight + 'px'};
    }else if(window.screen.width<=1920&&window.screen.width>1800){
      this.bodyHeight = window.screen.height - 480;
      this.showThTit=['62px','62px','100px','100px','100px','100px','100px'];
      this.showThTiSc={ y: this.bodyHeight + 'px'};
    }else if(window.screen.width<1366&&window.screen.width>=1000){
      this.bodyHeight = window.screen.height - 470;
      this.showThTit=['40px','40px','80px','130px','100px','90px','100px'];
      this.showThTiSc={ y: this.bodyHeight + 'px',x:'100%'};
    }else{
      this.bodyHeight = window.screen.height - 480;
      this.showThTit=['62px','62px','100px','100px','100px','100px','100px'];
      this.showThTiSc={ y: this.bodyHeight + 'px',x:'130%'};
    }
  }
  //初始化页面
  init(){
    this.pageIndex=1;
    let argsObj={
      pageNum:this.pageIndex,
      pageSize:this.pageSize,
    }
    this.queryRole(argsObj);
  }
  //查询列表数据
  queryRole (args) {
    this.isLoad=true;
    let url='/portal/content/Page?pageSize='+args.pageSize+'&&pageNum='+args.pageNum;
    let obj={type:'sysManage', args:{state:'4',orgId:this.orgId},}; 
    for(let i of this.thDatas){
        if(i.value!=''&&i.value!=null){
          obj[i.key]=i.value;
        }
    };
    this.api.handlePost(url,obj).subscribe(res => {
        if(res.code==200){
          this.displayData=res.data.list;
          this.pageTotal=res.data.total;
          this.isLoad=false;
        }else{
          this.notice.warning(this.nation.getMessage('general','msg'),res.msg);
        }
    },
    err =>{
      this.notice.warning(this.nation.getMessage('general','msg'),err.error.message);
    })
  }
  //表格从小到大序号
  changeIndexPage(args){
    let index=0;
    if(this.pageIndex==1){
      index=args;
    }else if(this.pageIndex>1){
      index=(Number(this.pageIndex)-1)*Number(this.pageSize)+Number(args);
    }
    return index;
  }
  //跳到某一页
  goPage(number) {
    this.pageIndex=number;
    let argsObj={
      pageNum:number,
      pageSize:this.pageSize,
      sort:this.sorts,
      order:this.order,
    }
    this.queryRole(argsObj);
  }
  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.displayData = $event;
    this.refreshStatus();
  }
//记录表格数据全选或者全不选
  refreshStatus(): void {
    const allChecked = this.displayData.filter(value => !value.disabled).every(value => value.checked === true);
    const allUnChecked = this.displayData.filter(value => !value.disabled).every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked); 
  }
  //改变页码
  changeSize(data){
    if(this.pageSize!=data){
      this.pageIndex=1;
      let argsObj={
        pageNum:1,
        pageSize:data,
        sort:this.sorts,
        order:this.order,
      }
      this.queryRole(argsObj);
    }
  }
  //全选
  checkAll(value: boolean): void {
    this.displayData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }
  isBlock(args){
    if(!args.checked){
      return 'none'
    }
  }
  sort(sort: { key: string, value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    //this.search();
  }
  sortDown(args,$event){
    $event.stopPropagation();
    this.pageIndex=1;
    let argsObj={
      pageNum:this.pageIndex,
      pageSize:this.pageSize,
      sort:args,
      order:'desc',
    }
      this.sorts=args;
      this.order="desc";
      this.queryRole(argsObj);
  }
  sortUp(args,$event){
    $event.stopPropagation();
    this.pageIndex=1;
    let argsObj={
      pageNum:this.pageIndex,
      pageSize:this.pageSize,
      sort:args,
      order:'asc',
    }
      this.sorts=args;
      this.order="asc";
      this.queryRole(argsObj);
  }
  checkTdChange(args,obj){
    args.stopPropagation();
    if(obj.checked){
      args.currentTarget.parentElement.style.backgroundColor="#EEF5FB";
    }else{
      args.currentTarget.parentElement.style.backgroundColor="";
    }
  }
  filterDatas(args,obj){
    let ary=args[obj];
    if(obj=='submitTime'){
      ary='';
    }else if(obj=='status'){
      ary=this.typeStatus[args[obj]].label;
    }else if(obj=='isTop'){
      ary=this.listOfBs[args[obj]].label;
    }else if(obj=='contentText'){
      ary='';
    }else if(obj=='topDeadline'){
      ary='';
    }
    return ary;
  }
  //显示详情
  showView(args){
    this.passDetails.mdmLists=[];
    this.passDetails.mdmLists=args;
    this.isDetails=true;
  }
  backDetails(args){
    this.isDetails=args;
  } 
}