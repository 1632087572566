import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { ServiceApiService} from '../../commons/services/service-api.service';
import { TokenServiceService} from '../../commons/services/token-service.service';
import { NzNotificationService,NzModalRef, NzModalService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    private http: HttpClient,
    private svApi:ServiceApiService,
    private tokens:TokenServiceService,
    private notice:NzNotificationService,
  ) { }
  /***
   * 接收的参数
   * parms:{
   * urlType:url请求类型，
   * url:请求地址
   * fileName:文件名称
   * nameType:文件类型
   * }
   * ** */
  importFile(args,formData): Observable<any> {
     let baseUrl = this.svApi.urlApi[args.urlType];
   // let baseUrl="http://10.238.113.33:8082"
    let url = baseUrl + args.url;
    let header = new HttpHeaders(
      {
        'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
      }
    );

    return this.http.post(url, formData,{headers:header,reportProgress: true, withCredentials: true});
  }
  handlePostLoad(args): Observable<any>{
      let baseUrl = this.svApi.urlApi[args.urlType];
      let tokens={
        'x-access-current-token' :this.tokens.getToken(),
        'x-access-refresh-token' :this.tokens.getToken1(),
      };
      let url = baseUrl + args.url;
     return this.http.post(url,args.parms,{headers:tokens,responseType:'blob'});
     //return this.http.post(url,args.parms,{headers:tokens,withCredentials: true,reportProgress: true,responseType:'blob'});
  }

  exportList(obj){
    let objs=obj;
    this.handlePostLoad(objs).subscribe(res => {
      const uA = window.navigator.userAgent;
      let isIE=''
      if (uA.indexOf("MSIE") >= 0
				|| (uA.indexOf("Windows NT 10.0;") >= 0 && uA
						.indexOf("Trident/7.0;") >= 0)
				|| (uA.indexOf("Windows NT 6.1;") >= 0 && uA
						.indexOf("Trident/7.0;") >= 0)) {
              isIE = 'ie'; //ie
		}
      //const isIE = /msie\s|trident\/|edge\//i.test(uA) && !!("uniqueID" in document || "documentMode" in document || ("ActiveXObject" in window) || "MSInputMethodContext" in window);
      const url = URL.createObjectURL(res);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display:none');
      a.setAttribute('id', 'download');
      a.setAttribute('href', url);
      a.setAttribute('target', '_blank');
      a.setAttribute('download', objs.fileName+'.'+objs.nameType); //这个name是下载名.后缀名
      if (isIE=='ie') {
        let blob = new Blob([res], { type: "application/msexcel;charset=UTF-8" });
        // 兼容IE11无法触发下载的问题
        navigator.msSaveBlob(blob,objs.fileName+'.'+objs.nameType);
      } else {
        a.click();
      }
      // 触发下载后再释放链接
      a.addEventListener('click', function() {
        URL.revokeObjectURL(url);
        document.getElementById('download').remove();
      });
    },
      err => {
        this.notice.warning( '提示',err.error.message)
      },);
  }
}
