import { Component, OnInit ,ViewChild} from '@angular/core';
import { NzFormatEmitEvent, NzTreeNode } from 'ng-zorro-antd';
import { MaterielServerService } from '../materiel-server.service';

@Component({
  selector: 'app-materiel-tables',
  templateUrl: './materiel-tables.component.html',
  styleUrls: ['./materiel-tables.component.css']
})
export class MaterielTablesComponent implements OnInit {
  nodes = [
    { title: 'Expand to load', key: '0' },
    { title: 'Expand to load', key: '1' },
    { title: 'Tree Node', key: '2', isLeaf: true }
  ];
  searchValue;
  pageIndex = 1;
  pageSize = 20;
  pageTotal = 0;
  matterData = [];
  arryRoleRank=['列表结构','树形结构'];
  testIndex=0;
  thData=[
   
      {
        key    : 'lbbm',
        title   : '类别代码',
        checked:true,
        disabled: false,
        value:""
      },
      {
        key    : 'lbmc',
        title   : '类别名称',
        checked:true,
        disabled: false,
        value:""
      },
      {
        key    : 'wzcpbz',
        title   : '类型',
        checked:true,
        disabled: false,
        value:""
      },
      {
        key    : 'jldw',
        title   : '计量单位',
        checked:true,
        disabled: false,
        value:""
      },
      {
        key    : 'yhbm',
        title   : '编辑说明',
        checked:true,
        disabled: false,
        value:""
      },
      {
        key    : 'state',
        title   : '状态',
        checked:true,
        disabled: false,
        value:""
      },
      {
        key    : 'creatorName',
        title   : '创建人',
        checked:true,
        disabled: false,
        value:""
      },
      {
        key    : 'createTime',
        title   : '创建时间',
        checked:true,
        disabled: false,
        value:""
      },
      {
        key    : 'opertype',
        title   : '类别',
        checked:true,
        disabled: false,
        value:""
      },
      {
        key    : 'Action',
        title   : '查询',
        checked:true,
        disabled: false,
        value:""
      },

  ];
  
  hadleTables={
    //datas:this.matterData,
    thData:this.thData,
    wh:'1400px',
    total:this.pageTotal,
    api:"",
  }
  passArry=[
    [{allowNextLevel: "0",
    beginDate: null,
    bmgz: "1",
    bzsm: null,
    cglx: "2G",
    checked: false,
    cpgroup: "99",
    createTime: "2014-02-28",
    creatorId: "1",
    creatorName: "系统管理员",
    draw: null,
    endDate: null,
    errtext: null,
    halfCheck: false,
    instanceId: 0,
    isParent: false,
    jldw: "吨",
    lbbm: "010104",
    lbbmId: "10000004",
    lbmc: "热轧优质碳素H型钢",
    lbmcjc: "热轧优质碳素H型钢",
    length: null,
    limit: null,
    lmzwm: "1",
    normt1: "0",
    normt2: "6",
    normt3: "0",
    normt4: "0",
    nouse: "0",
    open: true,
    opertype: null,
    order: null,
    pageNum: null,
    pid: "10000002",
    sort: null,
    start: null,
    state: "4",
    status: "1",
    submitTime: "2014-02-28",
    submitUid: null,
    submitUname: null,
    taskId: null,
    updateTime: null,
    updateUid: null,
    updateUname: null,
    version: 0,
    wzcpbz: "1",
    yhbm: null}],
    [{
      allowNextLevel: "0",
      beginDate: null,
      bmgz: "1",
      bzsm: null,
      cglx: "2G",
      checked: false,
      cpgroup: "99",
      createTime: "2014-02-28",
      creatorId: "1",
      creatorName: "系统管理员",
      draw: null,
      endDate: null,
      errtext: null,
      halfCheck: false,
      instanceId: 0,
      isParent: false,
      jldw: "吨",
      lbbm: "010112",
      lbbmId: "10000008",
      lbmc: "焊接低合金H型钢",
      lbmcjc: "焊接低合金H型钢",
      length: null,
      limit: null,
      lmzwm: "1",
      normt1: "0",
      normt2: "6",
      normt3: "0",
      normt4: "0",
      nouse: "0",
      open: true,
      opertype: null,
      order: null,
      pageNum: null,
      pid: "10000002",
      sort: null,
      start: null,
      state: "4",
      status: "1",
      submitTime: "2014-02-28",
      submitUid: null,
      submitUname: null,
      taskId: null,
      updateTime: null,
      updateUid: null,
      updateUname: null,
      version: 0,
      wzcpbz: "1",
      yhbm: null,
    },
    {allowNextLevel: "0",
      bmgz: "1",
      cglx: "2G",
      checked: false,
      cpgroup: "99",
      createTime: "2014-02-28 18:35:03.0",
      creatorId: "1",
      creatorName: "系统管理员",
      halfCheck: false,
      instanceId: 0,
      isParent: false,
      jldw: "吨",
      lbbm: "010302",
      lbbmId: "10000012",
      lbmc: "010302_普通碳素工字钢",
      lbmcjc: "普通碳素工字钢",
      lmzwm: "1",
      normt1: "0",
      normt2: "1",
      normt3: "0",
      normt4: "0",
      nouse: "0",
      open: true,
      pid: "10000011",
      state: "4",
      status: "1",
      submitTime: "2014-02-28 18:35:03.0",
      updateTime: "2016-06-03 14:02:11.0",
      updateUid: "10000046",
      updateUname: "丛培钊",
      version: 1,
      wzcpbz: "1"}
  ]
    ,[{
      allowNextLevel: "0",
        beginDate:null,
        bmgz: "1",
        bzsm: null,
        cglx: "2G",
        checked: false,
        cpgroup: "99",
        createTime: "2014-02-28",
        creatorId: "1",
        creatorName: "系统管理员",
        draw: null,
        endDate: null,
        errtext: null,
        halfCheck: false,
        instanceId: 0,
        isParent: false,
        jldw: "吨",
        lbbm: "010304",
        lbbmId: "10000013",
        lbmc: "优质碳素工字钢",
        lbmcjc: "优质碳素工字钢",
        length: null,
        limit: null,
        lmzwm: "1",
        normt1: "0",
        normt2: "6",
        normt3: "0",
        normt4: "0",
        nouse: "0",
        open: true,
        opertype: null,
        order: null,
        pageNum: null,
        pid: "10000011",
        sort: null,
        start: null,
        state: "4",
        status: "1",
        submitTime: "2014-02-28",
        submitUid: null,
        submitUname: null,
        taskId: null,
        updateTime: "2016-06-03",
        updateUid: "10000046",
        updateUname: "丛培钊",
        version: 1,
        wzcpbz: "1",
        yhbm: null,
    },
    {allowNextLevel: "0",
      beginDate: null,
      bmgz: "1",
      bzsm: null,
      cglx: "2G",
      checked: false,
      cpgroup: "99",
      createTime: "2014-02-28",
      creatorId: "1",
      creatorName: "系统管理员",
      instanceId: 0,
      isParent: false,
      jldw: "吨",
      lbbm: "010302",
      lbbmId: "10000012",
      lbmc: "普通碳素工字钢",
      lbmcjc: "普通碳素工字钢",
      length: null,
      limit: null,
      lmzwm: "1",
      normt1: "0",
      normt2: "6",
      normt3: "0",
      normt4: "0",
      nouse: "0",
      open: true,
      opertype: null,
      order: null,
      pageNum: null,
      pid: "10000011",
      sort: null,
      start: null,
      state: "4",
      status: "1",
      submitTime: "2014-02-28",
      submitUid: null,
      submitUname: null,
      taskId: null,
      updateTime: "2016-06-03",
      updateUid: "10000046",
      updateUname: "丛培钊",
      version: 1,
      wzcpbz: "1",
      yhbm: null},],
    [{allowNextLevel: "0",
      bmgz: "1",
      cglx: "2G",
      checked: false,
      cpgroup: "99",
      createTime: "2014-02-28 18:35:03.0",
      creatorId: "1",
      creatorName: "系统管理员",
      halfCheck: false,
      instanceId: 0,
      isParent: false,
      jldw: "吨",
      lbbm: "010302",
      lbbmId: "10000012",
      lbmc: "010302_普通碳素工字钢",
      lbmcjc: "普通碳素工字钢",
      lmzwm: "1",
      normt1: "0",
      normt2: "1",
      normt3: "0",
      normt4: "0",
      nouse: "0",
      open: true,
      pid: "10000011",
      state: "4",
      status: "1",
      submitTime: "2014-02-28 18:35:03.0",
      updateTime: "2016-06-03 14:02:11.0",
      updateUid: "10000046",
      updateUname: "丛培钊",
      version: 1,
      wzcpbz: "1"}],
  ];
  dataSet=[
    {
      name   : 'John Brown',
      age    : 32,
      address: 'New York No. 1 Lake Park'
    },
    {
      name   : 'Jim Green',
      age    : 42,
      address: 'London No. 1 Lake Park'
    },
    {
      name   : 'Joe Black',
      age    : 32,
      address: 'Sidney No. 1 Lake Park'
    },
    {
      name   : 'Jim Red',
      age    : 32,
      address: 'London No. 2 Lake Park'
    }
  ];
  @ViewChild('treeCom') treeCom;
  constructor(private mater: MaterielServerService) { }

  ngOnInit() {
    this.init();
  }
  init(){
    this.matterData=this.passArry[this.testIndex];
    //this.hadleTables.datas=this.matterData;
    this.testIndex++;
    // for (let i = 0; i < 100; i++) {
    //   this.dataSet.push({
    //     name   : `Edward King ${i}`,
    //     age    : 32,
    //     address: `London, Park Lane no. ${i}`
    //   });
    // }
 
    // let url='/dm/wlbmJldwController/queryAllWlbmJldwList';
    // let obj={
    //   order: 'desc',
    //   offset: 0,
    //   limit: 20,
    //   pageNum: 1,
    // }
    // this.mater.materielQueryPost(url,obj).subscribe(data => {
  
    
    // })
  }
  nzEvent(event: NzFormatEmitEvent): void {
    if(this.testIndex>4||this.testIndex<=0){
      this.testIndex=0;
    }
    
    if (event.eventName === 'expand') {
      setTimeout(_ => {
        if (event.node.getChildren().length === 0 && event.node.isExpanded) {
          event.node.addChildren([
            { title: 'Child Node', key: `${event.node.key}-0` },
            { title: 'Child Node', key: `${event.node.key}-1` } ]);
            this.matterData=[];
            this.matterData=this.passArry[this.testIndex];
            // this.hadleTables.datas=this.matterData;
            this.testIndex++;
        }else{
            this.matterData=[];
            this.matterData=this.passArry[this.testIndex];
            // this.hadleTables.datas=this.matterData;
            this.testIndex--;
        }
      }, 1000);
    }
  }

}
