import { Injectable } from '@angular/core';
import * as getApi from '../../../assets/config/packConfig';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class ServiceApiService {
  // urlApi={
  //   'matter':'http://10.246.151.188:8081',
  //   'sysManage':'http://10.246.151.188:8088',
  //   'mdmManage':'http://10.246.151.188:8082',
  //   'wf':'http://10.246.151.188:8083',
  //   'file':'http://10.246.151.188:8085',
  //   'metadata':'http://10.246.151.188:8087',
  //   'data-cleaning':''
  // }
  urlApi=getApi.getApi();
  // urlApi={
  //   'matter':'http://10.246.151.224:8081',
  //   'sysManage':'http://10.246.151.224:8088',
  //   'mdmManage':'http://10.246.151.224:8082',
  //   'wf':'http://10.246.151.224:8083',
  //   'file':'http://10.246.151.224:8085',
  //   'metadata':'http://10.246.151.224:8087',
  //   'data-cleaning':''
   
  // }
  //urlApi = this.getServer();
  sysName=getApi.getSystem();
  extData=getApi.getExt();
  constructor(private http:HttpClient) { }
  // getServer(){
  //   this.http.get("assets/packConfig.json").subscribe(res => {
  //       console.log(res['defalut'])
  //       return res['defalut'];
  //   })
  // }
}
