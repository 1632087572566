
import {Directive, ElementRef, OnInit, Output, EventEmitter} from '@angular/core';
declare var $: any;
@Directive({
  selector: '[appScrollbar]',
  host: {'class': 'mCustomScrollbar'}
})
export class ScrollbarDirective {
  @Output() psYReachEnd = new EventEmitter();
  el: ElementRef;
  constructor(el: ElementRef) {
    this.el = el;
  }
  ngOnInit() {
    const psYReachEnd = this.psYReachEnd;
    $(this.el.nativeElement).mCustomScrollbar({
      autoHideScrollbar: true,
      setHeight: '100%',
      theme: 'Default scrollbar',
      axis: 'xy',
      callbacks: {
          whileScrolling: function(){      　// 只要滚动条滚动，这个函数就会执行
              if (this.mcs.topPct >= 99) {    // 这表示当滚动条滚动到这个div的90%(当然这个值是可变的)的时候调用下面的代码，
                  psYReachEnd.emit();
              }
          },
          onTotalScroll: () => {
              this.psYReachEnd.emit();
          }
      }
    });
   
  }

}
