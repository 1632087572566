import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef, ViewChild } from '@angular/core';

@Component({
	selector: 'app-common-org-type',
	templateUrl: './common-org-type.component.html',
	styleUrls: ['./common-org-type.component.css']
})
export class CommonOrgTypeComponent implements OnInit {
	@ViewChild('drags') drags: ElementRef;
	@Input() isShown: boolean;
	@Input() showTit: any;
	@Output() backEventData = new EventEmitter();
	@Output() backEventState = new EventEmitter();
	isVisible = false;
	isDown = false;
	disX; // 记录鼠标点击事件的位置 X
	disY; // 记录鼠标点击事件的位置 Y
	totalOffsetX = 0; // 记录总偏移量 X轴
	totalOffsetY = 0; // 记录总偏移量 Y轴
	activeDom = '';
	modalTitle = '';
	codes='';
	names=''
	categoryId='';
	treeQueryArgs = {
		url: '/org/list',
		pid: '0'
	}
	constructor() { }

	ngOnInit() {
		this.changeTem();
	}
	ngOnChanges() {
		this.isVisible = this.isShown;
		this.modalTitle = this.showTit;
	}

	//点击树组件返回值
	getNode(args) {
		if (args.state) {
			if (args.datas.selected) {
				this.codes = args.datas.code;
				this.names = args.datas.name;
				this.categoryId = args.datas.id;
			} else {
				this.codes = '';
				this.names = ''
				this.categoryId = '';
			}
		} else {
			this.codes = '';
			this.names = ''
			this.categoryId = '';
		}
	}
	handleOk() {
		let obj = {
			code: this.codes,
			name: this.names,
			categoryId: this.categoryId,
		}
		this.isVisible = false;
		this.backEventData.emit(obj);
		this.backEventState.emit(this.isVisible);
	}
	handleCancel() {
		this.isVisible = false;
		this.backEventState.emit(this.isVisible);
	}
	// 点击事件
	@HostListener('document:mousedown', ['$event']) onMousedown(event) {
		if (event.path[0].localName != 'input') {
			if (this.activeDom != '') {
				this.isDown = true;
				this.disX = event.clientX;
				this.disY = event.clientY;
			}
		}
	}

	// 监听document移动事件事件
	@HostListener('document:mousemove', ['$event']) onMousemove(event) {
		// 判断该元素是否被点击了。
		if (this.isDown) {
			this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
			this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
		}
	}
	// 监听document离开事件
	@HostListener('document:mouseup', ['$event']) onMouseup(event) {
		// 只用当元素移动过了，离开函数体才会触发。
		if (this.isDown) {
			this.totalOffsetX += event.clientX - this.disX;
			this.totalOffsetY += event.clientY - this.disY;
			this.isDown = false;
		}
	}
	changeTem() {
		this.activeDom = this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
		this.activeDom['style'].position = 'relative';
	}
}
