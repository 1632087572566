import { Component, OnInit,TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { Routes, RouterModule,Router,ActivatedRoute,NavigationStart,NavigationEnd,ParamMap} from '@angular/router';
import { map, filter, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LeftMenuService} from '../services/left-menu.service';
import { AutoServiceService} from '../services/auto-service.service';
import {NzNotificationService} from 'ng-zorro-antd';
import { TokenServiceService} from '../services/token-service.service';
import { ButtonMenuService }from '../services/button-menu.service'
import { MenuManageService} from '../services/menu-manage.service';
import { ViewStateService}from '../services/view-state.service';
import { DmModalNameService }from '../../dm/services/dm-modal-name.service';
import { AutoInternationService } from '../services/auto-internation.service';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  menuItems: any = [];
  title='表格';
  modeTyle='top';
  langSv='ZH';
  // showLangSv={
  //   zh:'ZH',
  //   en:'EN',
  // };
  showTheme='showThemeOne';
  oneTitle="";
  twoTitle='';
  sysList=[];
  kjList=[];
  sys='';
  userId='';
  menuId='';
  isPassword=false;
  isInfo=false;
  appNameList=[{appName:""}]
  isHidden=true;
  //路由列表
  tabCollection: Array<{ title: string, url: string,active:boolean,data:any}> = [

  ];
  @ViewChild('tabs') tabs: ElementRef;
  @ViewChild('lfTabs') lfTabs: ElementRef;
  @ViewChild('layoutTem') element: ElementRef;
  @ViewChild('trigger') customTrigger: TemplateRef<void>;
  isCollapsed = false;
  triggerTemplate = null;
  isShow=false;
  sideWidth=220;
  isCollect:boolean = false;
  showNext: boolean = false;
  showPre: boolean = false;
  isClose: boolean = true;
  changeMenu=[];
  breadcrumbs=[];
  en=true;
  zh=false;
  userName='';
  showLangList={
    'ZH':'zh',
    'EN':'en',
  };
  showLan={
    'ZH':'首页',
    'EN':'index',
  }
  wfConfig={
    showTit:'工作流代理',
  }
  appId="";
  urlActive="";
  isNewedit=false;
  passNewedit={
    isEdit:false,
    showTit:'工作流代理',
    saveUrl:'/busPro/addApproveAgent',
  };
  updatePassword={
    showTit:'修改密码',
    saveUrl:'/sec/user/modifyPassword',
    verifyUrl:'/sec/user/passwordVerify',
  }
  updateInfo={
    showTit:'修改用户信息',
    saveUrl:'/sec/user/updateUser',
    verifyUrl:'',
  }
  helpedit={
    orgId:'2',
    showTit:'帮助文档',
  };
  ishelp=false;
  allLoading=false;
  constructor(
    private router:Router,
    private translate:TranslateService,
    private routerActive:ActivatedRoute,
    private auth:AutoServiceService,
    private notice: NzNotificationService,
    private token:  TokenServiceService,
    private LeftMenu:LeftMenuService,
    private buttonService:ButtonMenuService,
    private menuService: MenuManageService,
    private viewAttr:ViewStateService,
    private nation: AutoInternationService,
    private removeModel:DmModalNameService
    ) {
      //添加语言支持
      translate.addLangs(["en", "zh"]);
      //设置默认语言，一般在无法匹配的时候使用
      translate.setDefaultLang('zh');
      //translate.setDefaultLang(this.showLangList[this.token.getSysLang()]);

      //获取当前浏览器环境的语言比如en、 zh
      let browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|zh/) ? browserLang : 'zh');
//       this.router.events
//   .filter((event) => event instanceof NavigationEnd)
//   .subscribe((event:NavigationEnd) => {
//     //do something
// });

    //切换语言

   }
   ngOnChanges(){
   //this.userName=this.token.getUserInfo();

  }
 public routerChange() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      map(() => this.routerActive),
      map((route: any) => {
      while (route.firstChild) route = route.firstChild;
      return route;
      })
    )
    .subscribe((event) => {
    this.allLoading = true;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.routerActive),
      map((route: any) => {
      while (route.firstChild) route = route.firstChild;
      return route;
      })

    )

    .subscribe((event) => {
    this.allLoading = false;
    });
    });
    }

  ngOnInit() {
    this.langSv=this.token.getSysLang();
    let lang=this.langSv;
    lang=='EN'?lang='en':lang='zh';
    this.changeLang(lang);
    // let urlsInit=['main/index'];
    // this.router.navigate(urlsInit);
    this.breadcrumbs.push(this.showLan[this.langSv]);
    this.getSys();
    this.getPageStyle();
    this.userName=this.token.getUserInfo();
    const isTips=JSON.parse(localStorage.getItem('isTips'));
    if(isTips.status==true){
      this.notice.warning(this.nation.getMessage('general', 'msg'), isTips.value);
    }
    this.routerActive.url
      .subscribe(url => {
        let loc=window.location.href;
        let ary=loc.split("#");
        this.changeUrls(ary);
      });
    this.tabCollection.push(
      {title:this.showLan[this.langSv],
      url:'main/index',
      active:true,
      data:{
        isNode:0,
        breadcrumb:this.showLan[this.langSv]
      }
      })
  }
  getPageStyle(){
      this.auth.getMenuTree('/sec/sysParameter/getParameterByKey?paramKey=PAGE_SHOWTYPE', {}).subscribe(res => {
        if (res.code == 200&&res.data['paramValue']) {
          this.buttonService.getPageData(res.data.paramValue);
        } else {
          this.notice.warning(this.nation.getMessage('general', 'msg'), res.msg);
        }
      },err => {
        this.notice.warning(this.nation.getMessage('general', 'msg'), err.error.message);
      });
  }
 changeUrls(args){
    let flag = true;
    if(args[1]=="/main/index"){
      this.isHidden=true;
    }else{
      this.isHidden=false;
    }
    for(let i of this.kjList){
      if(args[1]==i.url){
        flag=false;
      }
    }
    if(flag){
       this.isCollect=false;
    }else{
      this.isCollect=true;
    }
  let mark=this.changeUrlsState(args[1]);
  if(!mark){
    let showMenu=this.getLfUrls(args[1]);
    if(showMenu!=""){
      let parms=args[1].substr(1);
      let obj={
        isNode:showMenu['isCrumb'],
        breadcrumb:showMenu['crumbUrl'],
      }
      let tab={
          id:showMenu['id'],
          title:showMenu['name'],
          url:showMenu['url'],
          active:true,
          data:obj,
          btnList:showMenu['childList'],

      };
      this.buttonService.getData(tab);
      this.breadcrumbs=[];
      this.breadcrumbChange(tab);
      //this.router.navigate([parms]);
      for(let m of this.tabCollection){
        if(m.active){
          m.active=false;
          break;
        }
      }
      this.tabCollection.push(tab);
    }


  }

 }
 changeUrlsState(args){
   let mark=false;
   let str=args.substr(1);
   for(let n of this.tabCollection){
     if(n.url==str){
       mark=true;
       break;
     }
   }
  return mark;
}
getLfUrls(args){
  let ary="";
  let str=args.substr(1);
   for(let n of this.changeMenu){
     if(n.url==str){
      ary=n;
     }
   }
   return ary;
}
  getLeftMenu(args){
    localStorage.setItem('sys_info',JSON.stringify(args));
    let lang=this.token.getSysLang();
    let indexUrls='';
    this.userId=args.creatorId;
    this.sys=args.id;
    if(args.name=='元数据系统'||args.name=='基础服务'){
      indexUrls='main/indexYuan';
    }else if(args.name=='主数据系统'){
      indexUrls='main/index';
    }
    let passUrl='/sec/menu/queryMenuTreeAllByLoginUser?appId='+this.sys+'&lanCode='+lang;
    this.auth.getUserMenuList(passUrl,{}).subscribe(res => {
      if(res.code==200){
        let testList= res;
        if(testList.data.length!=0){
          this.changeMenu=testList.data;
          let dast=this.auth.buildTreeChildren(testList.data);
          this.menuItems=this.LeftMenu.handleDatas(dast);
          this.tabCollection=[];
          //重定向方式跳转
        if(this.token.getRedirect()){
          this.tabCollection.push(
            {title:this.showLan[this.langSv],
            url:indexUrls,
            active:false,
            data:{
              isNode:0,
              breadcrumb:this.showLan[this.langSv],
              btnList:[],
            }
            }, {title:this.buttonService.returnTab()['title'],
              url:this.buttonService.returnTab()['url'],
              active:true,
              data:{
                isNode:0,
                breadcrumb:this.buttonService.returnTab()['breadcrumb'],
                btnList:[],
              },

              });
            this.breadcrumbs=[];
            this.breadcrumbs.push(this.buttonService.returnTab()['breadcrumb']);
            this.token.setRedirect(false);
            this.getKjList(true);
        }else{
          this.tabCollection.push(
            {title:this.showLan[this.langSv],
            // url:'main/index',
            url:indexUrls,
            active:true,
            data:{
              isNode:0,
              breadcrumb:this.showLan[this.langSv],
              btnList:[],
            },

            });
            let parms=[];
            // parms.push('main/index');
            parms.push(indexUrls);
            //跳首页
            this.router.navigate(parms);
            this.breadcrumbs=[];
            this.breadcrumbs.push(this.showLan[this.langSv]);
            this.getKjList(true);
        }
        }else{
          this.notice.warning(this.nation.getMessage('general', 'msg'),this.nation.getMessage('general', '10'));
          // this.token.removeToken();
          // this.userName="";
          // this.router.navigate(['login']);
        }

      }else{
        this.notice.warning( this.nation.getMessage('general', 'msg'),res.msg);
      }
      },
      err => {

        this.notice.warning( this.nation.getMessage('general', 'msg'),err.error.message)
        this.token.removeToken();
        this.userName="";
        this.router.navigate(['login']);
      },
      );
  }
  getSys(){
    let url="/sec/app/list?pageSize=20&&pageNum=1";
    let obj={
      lanCode:this.token.getSysLang()
    }
    this.auth.getMenuTree(url,obj).subscribe(data => {
      if(data.code==200){
        this.sysList=data.data.list;
        const sysId=JSON.parse(localStorage.getItem('sysId'));
        if(sysId){
          for(let item of this.sysList){
            if(sysId==item.id){
              this.getLeftMenu(item);
            }
          }
        }else{
          this.getLeftMenu(this.sysList[0]);
        }
      }
    },
    err => {
        this.notice.warning( this.nation.getMessage('general', 'msg'),err.error.message);
    },)
  }
  //查询快捷访问
  getKjList(args){
    //获取快捷访问系统名称
    this.getSysName();
    //获取快捷访问收藏
    this.getkjfw();
  }
  getSysName(){
    let url="/sec/app/list?pageSize=20&&pageNum=1";
    let obj={
      lanCode:this.token.getSysLang(),
      id:this.sys
    }
    this.menuService.handelPost(url,obj).subscribe(res =>{
      if(res.code==200){
        this.appNameList[0].appName=res.data.list[0].name;
      }else{
        this.notice.warning(this.nation.getMessage('general', 'msg'),res.msg);
      }
    },
    err =>{
      this.notice.warning(this.nation.getMessage('general', 'msg'),err.error.message);
    });
  }
  getkjfw(){
    let lang=this.token.getSysLang();
    let url="/sec/store/list?pageSize=20&&pageNum=1"
    let params={
       appId:this.sys,
       userId:this.userId,
       lanCode:lang
    };
    this.menuService.handelPost(url,params).subscribe(res =>{
      if(res.code==200){
        this.kjList=res.data.list;
        // if(this.kjList.length!=0){
        //   if(this.appNameList[0].appName=="" || this.appNameList[0].appName!=this.kjList[0].appName){
        //     this.appNameList[0].appName=this.kjList[0].appName;
        //   }
        // }
      }else{
        this.notice.warning(this.nation.getMessage('general', 'msg'),res.msg);
      }
    },
    err =>{
      this.notice.warning(this.nation.getMessage('general', 'msg'),err.error.message);
    });
  }
  //点击进入对应地址
  gotoUrl(args){
      let arr=[args.url];
      this.isCollect=true;
      this.router.navigate(arr);
  }
  //删除收藏地址
  deleteUrl(args,obj){
    args.stopPropagation();
    let url="/sec/store/delById";
    let params={
      "id":obj.id,
    }
    this.menuService.handelGet(url,params).subscribe(res =>{
      if(res.code==200){
        this.getKjList(true);
      }else{
        this.notice.warning(this.nation.getMessage('general', 'msg'),res.msg);
      }
    },
    err =>{
      this.notice.warning(this.nation.getMessage('general', 'msg'),err.error.message);
    }
    );
  }
  /** custom trigger can be TemplateRef **/
  changeTrigger(): void {
    this.triggerTemplate = this.customTrigger;
  }

  svChangeLang(data){
      this.langSv=data;
      this.token.setLang(this.langSv);
      let url="/sec/menu/queryMenuTreeAllByLoginUser?appId="+this.sys+"&&lanCode="+data;
      this.auth.getUserMenuList(url,{}).subscribe(res => {
        if(res.code==200){
          let testList= res;
          if(testList.data.length!=0){
            this.menuItems=[];
            this.changeMenu=testList.data;
            let dast=this.auth.buildTreeChildren(testList.data);
            this.menuItems=this.LeftMenu.handleDatas(dast);
            this.tabCollection=[];
            this.tabCollection.push(
              {title:this.showLan[this.langSv],
              url:'main/index',
              active:true,
              data:{
                isNode:0,
                breadcrumb:this.showLan[this.langSv],
                btnList:[],
              },

              });
              let parms=[];
              parms.push('main/index');
              this.router.navigate(parms);
              this.breadcrumbs=[];
              this.breadcrumbs.push(this.showLan[this.langSv]);
          }else{
            this.notice.warning( this.nation.getMessage('general', 'msg'),this.nation.getMessage('general', '10'));
          }

        }else{
          this.notice.warning( this.nation.getMessage('general', 'msg'),res.msg);
        }
        },
        err => {

          this.notice.warning( this.nation.getMessage('general', 'msg'),err.error.message)
        },
        );
        let lan
    this.langSv=='EN'?lan='en':lan='zh';
    this.changeLang(lan);
    this.getSys();
  }
  changeLang(lang) {
    this.translate.use(lang);
  }
  changeUrlShow(arg){
    if(arg=='main/index'){
      return
    }

  }
selectMune(data){
    var result = [];
    var obj = {};
     for(var i =0; i< this.tabCollection.length; i++){
         if(!obj[ this.tabCollection[i].title]){
           result.push( this.tabCollection[i]);
           obj[ this.tabCollection[i].title] = true;
         }
     }
     this.tabCollection=result;
}
breadcrumbChange(args){
  if(args.title=="首页"){
    this.breadcrumbs=['首页'];
  }else{
    this.breadcrumbs=[];
    let url='/sec/menu/queryMBXByMenuId?lanCode='+this.langSv+'&menuId='+args.id;
    let params={};
    this.menuService.handelPost(url,params).subscribe(res =>{
        if(res.code==200){
          this.breadcrumbs.push(res.data);
        }else{
          this.notice.warning(this.nation.getMessage('general', 'msg'),res.msg);
        }
      },
      err =>{
        this.notice.warning(this.nation.getMessage('general', 'msg'),err.error.message);
      }
      );
  }

  // if(args.data){
  //   if(args.data.isNode==0){
  //     this.breadcrumbs.push(args.data.breadcrumb);
  //   }else{
  //     let ary=args.data.breadcrumb.split("/");
  //     this.breadcrumbs=ary;
  //   }
  // }
}

menuClick(event) {
  this.routerChange();
  //this.token.loading(true);
  this.isHidden=false;
  if(event.cd.context.menuItem.label=="工作流维护"||event.cd.context.menuItem.label=="Workflow Maintenance"){
    window.open(event.cd.context.menuItem.routerLink);
  }else{
    this.buttonService.getData(event.cd.context.menuItem);
  this.breadcrumbs=[];
  this.breadcrumbChange(event.cd.context.menuItem);
  let url=event.cd.context.menuItem.routerLink.split("/");
if(url[0]=='main'){
  let parms=[];
    parms.push(event.cd.context.menuItem.routerLink);
    this.router.navigate(parms);
}else{
  //parent.location.href=event.cd.context.menuItem.routerLink;
  this.buttonService.getPage(event.cd.context.menuItem.routerLink);
  this.menuService.handelOpenLoad();
  this.router.navigate(['main/dm/outPage'])
}

    // let parms=[];
    // parms.push(event.cd.context.menuItem.routerLink);
    // this.router.navigate(parms);
    let tab={
        id:event.cd.context.menuItem.id,
        title:event.cd.context.menuItem.label,
        url:event.cd.context.menuItem.routerLink,
        active:false,
        data:event.cd.context.menuItem.data,
        btnList:event.cd.context.menuItem.btnList,
    };
    this.menuId=event.cd.context.menuItem.id;
    this.tabCollection.splice(1, 0, tab)
    this.selectMune(this.tabCollection)
    for(let a in this.tabCollection){
        if(event.cd.context.menuItem.label==this.tabCollection[a].title){
            this.tabCollection[a].active=true;
        }else{
            this.tabCollection[a].active=false;
        }
    }
  }
}
showRefresh(){
  this.menuService.handelOpenLoad();
}

 //递归方法处理菜单
 getMenuItem(menu: any) {
    if (menu.resType != 'MENU') {
        return;
    }

    if (menu.children == null || menu.children.length == 0) {
        let menuItem = {
            label: menu.name,
            icon: menu.icon,
            routerLink: [menu.url]
        };
        return menuItem;
    } else {
        let menuItem = {
            label: menu.name,
            icon: menu.icon,
            children: []
        }
        menu.children.forEach(element2 => {
            menuItem.children.push(this.getMenuItem(element2));
        });
        return menuItem;

    }
}


//递归判断url是不是存在路由中
checkUrl(menus: any, checkUrl: any, urlArr: any) {
    if (menus == null || menus.length == 0) {

    } else {
        menus.forEach(menu => {
            if (menu.children == null || menu.children.length == 0) {

                let link = menu.routerLink[0];
                urlArr.push(link);

            } else {

                this.checkUrl(menu.children, checkUrl, urlArr);


            }
        });
    }
    return urlArr;
}
onTabClick(node) {
    //this.menuClick(node);
    this.buttonService.getData(node);
    for(let a in this.tabCollection){
        this.tabCollection[a].active=false;
    }
    node.active=true;
    let url=node.url.split("/");
    if(url[0]=='main'||url[0]==''){
      this.router.navigate([node.url]);
    }else{
      this.buttonService.getPage(node.url);
      this.menuService.handelOpenLoad();
      this.router.navigate(['main/dm/outPage'])
    }
     this.breadcrumbs=[];
     if(node.title==this.showLan[this.langSv]){

      this.breadcrumbs.push(this.showLan[this.langSv]);
    }else{
      this.breadcrumbChange(node);
    }
  }
 //关闭选项标签
 closeTab(node) {
    let url = node.url;
    let title=node.title;
    let isSelect = node.active;
    //当前关闭的是第几个路由
    let index = this.tabCollection.findIndex(p => p.title == title);
    //如果只有一个不可以关闭
    if (this.tabCollection.length == 1) {
        this.tabCollection[0].active=true;
      return;
    }else{
      if(url=="main/dm/dmDataApply"){
        this.buttonService.removeModal();
      }else if(url=="main/dm/dmDataEdit"){
        this.buttonService.removeEdit();
      }else if(url=="main/dm/dmDataQuery"){
        this.buttonService.removeQuery();
      }else if(url=="main/dm/dmDataAudit"){
        this.buttonService.removeAudit();
      }else if(url=="main/dm/dmReferenceDataApply"){
        this.buttonService.removeModal1();
      }else if(url=="main/dm/dmReferenceDataEdit"){
        this.buttonService.removereEdit();
      }else if(url=="main/dm/dmReferenceDataQuery"){
        this.buttonService.removereQuery();
      }
    }

    this.tabCollection = this.tabCollection.filter(p => p.title!= title);
    if (!isSelect) {
      return;
    }
    // 显示上一个选中
    let menu = this.tabCollection[index - 1];
    if (!menu) { // 如果上一个没有下一个选中
      menu = this.tabCollection[index];
    }

    if (!menu) { // 下一个不存在,显示第一个
      menu = this.tabCollection[0];
      menu.active=true;
    }
    if (menu) {
        menu.active=true;
      // 显示当前路由信息
      this.router.navigate([menu.url]);
      if(menu.title=='首页' || menu.title =='index'){
        this.breadcrumbs=[];
        this.breadcrumbs.push(menu.title);
      }else{
        this.breadcrumbs=[];
        this.breadcrumbChange(menu);
      }

    } else {
      alert('error');
    }
  }
  next() {
    var prev = this.tabs.nativeElement;
    prev.scrollLeft = prev.scrollLeft - 200;
    if (prev.scrollLeft == 0) {
      this.showPre = false;
    }
    this.showNext = true;
  }
  pre() {

    var nxt = this.tabs.nativeElement;
    nxt.scrollLeft = nxt.scrollLeft + 200;
    if ((nxt.scrollWidth - nxt.offsetWidth - nxt.scrollLeft) <= 0) {
      this.showNext = false;
    }
    this.showPre = true;
  }
  chooseTab(){
      this.isShow=!this.isShow;

  }
  closeAllTab($event){
    $event.stopPropagation();
    this.isShow=!this.isShow;
    this.tabCollection=[];
    this.tabCollection=[
      {title:this.showLan[this.langSv],
        url:'main/index',
        active:true,
        data:{
          isNode:0,
          breadcrumb:this.showLan[this.langSv]
        }
      }];
    this.router.navigate(['main/index']);
    this.breadcrumbs=[];
    this.breadcrumbChange(this.tabCollection[0]);
      this.buttonService.removeModal();
      this.buttonService.removeEdit();
      this.buttonService.removeQuery();
      this.buttonService.removeAudit();
      this.buttonService.removeModal1();
      this.buttonService.removereEdit();
      this.buttonService.removereQuery();
  }
  closeOtherTab($event){
    $event.stopPropagation();
    this.isShow = !this.isShow;
    this.tabCollection = this.tabCollection.filter(item => item.active === true);
    //如果只有一个不可以关闭
    if (this.tabCollection.length == 1) {
      this.tabCollection[0].active=true;
      return;
    }
  }
  //主题变色
  changeTheme(){
      if(this.showTheme=='showThemeOne'){
        this.showTheme='showThemeTwo'
      }else if(this.showTheme=='showThemeTwo'){
        this.showTheme='showThemeThree'
      }else if(this.showTheme=='showThemeThree'){
        this.showTheme='showThemeOne'
      }
  }
  toLogin(){
    this.menuService.handelPost("/logout", {}).subscribe(res => {
      if (res.code == 200) {
      
        this.token.logout();
        this.token.removeToken();
        this.userName="";
        if(res.data==null){
          this.router.navigate(['login']);
        }else if(res.data['url']){
          window.location.href=res.data.url;
        }else{
          this.router.navigate(['login'])
        }
      } else {
        this.notice.warning(this.nation.getMessage('general', 'msg'), res.msg);
      }
    },)
     
  }
  //点击全屏1
  showAllTemplate(){
    this.launchFullscreen(this.element);
  }
  launchFullscreen(element) {
    if (element.elementRef.nativeElement.requestFullscreen) {
      element.elementRef.nativeElement.requestFullscreen();
    } else if (element.elementRef.nativeElement.mozRequestFullScreen) {
      element.elementRef.nativeElement.mozRequestFullScreen();
    } else if (element.elementRef.nativeElement.msRequestFullscreen) {
      element.elementRef.nativeElement.msRequestFullscreen();
    } else if (element.elementRef.nativeElement.webkitRequestFullscreen) {
      element.elementRef.nativeElement.webkitRequestFullScreen();
    }
  }
  collect() {
    //this.isCollect=!this.isCollect;
    let loc = window.location.href;
    let ary = loc.split('#');
    let flag = true;
    for (let i of this.kjList) {
      if (ary[1] == i.url) {
        flag = false;
      }
    }
    if (flag) {
      let params = {
        appId: this.sys,
        url: ary[1],
        userId: this.userId,
        menuId: this.menuId
      };
      this.menuService.handelPost("/sec/store/insert", params).subscribe(res => {
        if (res.code == 200) {
          this.notice.warning(this.nation.getMessage('general', 'msg'), "收藏成功!");
          this.isCollect = true;
          this.getKjList(true);
        } else {
          this.notice.warning(this.nation.getMessage('general', 'msg'), res.msg);
        }
      },
        err => {
          this.notice.warning(this.nation.getMessage('general', 'msg'), err.error.message);
        }
      );
    } else {
      this.notice.warning(this.nation.getMessage('general', 'msg'), "该菜单已收藏!");
    }
  }
   //跳转工作流代理页面
   toAgent(){
     this.isNewedit=true;
   }
   backNewedit(args){
     this.isNewedit=args;
   }
   //修改密码页面
   toChangePassword(){
      this.isPassword=true;
   }
   backUpdate(args){
     this.isPassword=args;
   }
   //修改用户信息
   toChangeinfo(){
     this.isInfo=true;
   }
   backUpdateInfo(args){
     this.isInfo=args;
   }
   //帮助文档
   toHelp(){
    this.ishelp=true;
   }
   backHelpedit(args){
     this.ishelp=args;
   }
}
