import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AutoServiceService } from '../../services/auto-service.service';
import { AutoInternationService } from '../../services/auto-internation.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { TokenServiceService } from '../../services/token-service.service';
import {Router} from '@angular/router';
@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
	@Input() isShown: boolean;
	@Input() selectValues: any;
	@Output() backEventState = new EventEmitter();
	isVisible = false;
	isforgotPwd: any;
	isConfirmLoading = false;
	forgotPwdFirst = true;
	forgotPwdSecond = false;
	forgotPwdThree = false;
	forgotPwdFour = false;
	forgotPwd: FormGroup;
	selectValue = '';
	account = '';
	uid=""
	constructor(
			public authService: AutoServiceService,
			private notice: NzNotificationService,
			private nation: AutoInternationService,
			private token: TokenServiceService,
			private fb: FormBuilder,
			private router:Router
		) {
		this.forgotPwd = this.fb.group({
			account: [null, [Validators.required]],
			password: [null, [Validators.required]],
			checkPassword: [null, [Validators.required, this.confirmationValidator]],
			verCode: [null ,[ Validators.required]]
		})
	}

	ngOnInit() {
		this.isVisible = this.isShown;
		this.selectValue = this.selectValues;
		if(window.location.href.indexOf('#') != -1){
			this.isforgotPwd = window.location.href.split('=')[1];
			if(this.isforgotPwd){
			  this.forgotPwdFirst = false;
			  this.forgotPwdThree = true;
			}
		}
		this.uid=this.getQueryString('uid')
	}
	 getQueryString(name){
		var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
		var r = window.location.href.substr(1).match(reg);
		if(r!=null)return  unescape(r[2]); return null;
   }
	handleCancel() {
		this.isVisible = false;
		this.backEventState.emit(this.isVisible);
	}
	toLogin(){
		this.router.navigateByUrl('/login');
	}
	forgotFirst() {

		this.submitForm();
		if (this.forgotPwd.value.account != null) {
			this.isConfirmLoading = true;
			this.reSendCode();
		}
	}
	updatePassword() {
		this.submitForm();
		if (this.forgotPwd.value.password != null && this.forgotPwd.value.checkPassword != null) {
			this.isConfirmLoading = true;
			let url = '/checkVerCodeRestPsw';
			let params = {
				verCode: this.forgotPwd.value.verCode,
				password: this.forgotPwd.value.password,
				account: this.account
			}
			this.authService.login(url,params).subscribe( res => {
				if (res.code == 200){
					this.forgotPwdThree = false;
					this.forgotPwdFour = true;
					this.account = this.forgotPwd.value.account
				}else {
					this.notice.warning(this.nation.getMessage('general', 'msg'), res.msg);
				}
				this.isConfirmLoading = false;
			}, err => {
				this.notice.warning(this.nation.getMessage('general', 'msg'), err.error.message)
			});
		}
	}
	reSendCode(){
		let url = '/forgetPassword?account=' + this.forgotPwd.value.account// + '&login-language=' + this.token.getSysLang();
		this.authService.login(url,{}).subscribe( res => {
			if (res.code == 200){
				this.forgotPwdFirst = false;
				this.forgotPwdSecond = true;
				this.account = this.forgotPwd.value.account
			}else {
				this.notice.error(this.nation.getMessage('general', 'msg'), res.msg);
			}
			this.isConfirmLoading = false;
		}, err => {
			this.notice.warning(this.nation.getMessage('general', 'msg'), err.error.message)
		});
	}
	saveNew(){
		this.submitForm();
		if (this.forgotPwd.value.password != null) {
			let url = '/checkVerCodeRestPsw';
			let obj={
				verCode: this.forgotPwd.value.verCode,
				password: this.forgotPwd.value.password,
				userId: this.uid
			}
			this.authService.login(url,obj).subscribe( res => {
				if (res.code == 200){
					this.forgotPwdFour=true;
					this.forgotPwdThree=false;
				}else {
					this.notice.warning(this.nation.getMessage('general', 'msg'), res.msg);
				}
				
			}, err => {
				this.notice.warning(this.nation.getMessage('general', 'msg'), err.error.message)
			});
			
		}
	}
	submitForm(): void {
		for (const i in this.forgotPwd.controls) {
			this.forgotPwd.controls[i].markAsDirty();
			this.forgotPwd.controls[i].updateValueAndValidity();
		}
	}
	handleOk(){}
	confirmPassword() {
		setTimeout(() => this.forgotPwd.controls.checkPassword.updateValueAndValidity());
	}
	confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
		if (!control.value) {
			return { required: true };
		} else if (control.value !== this.forgotPwd.controls.password.value) {
			return { confirm: true, error: true };
		}
		return {};
	};

}
