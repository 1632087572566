import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { ServiceApiService} from '../../commons/services/service-api.service';
import { TokenServiceService} from '../../commons/services/token-service.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  baseUrl=this.svApi.urlApi['file'];
  baseUrl1=this.svApi.urlApi['wf'];
  baseUrl2=this.svApi.urlApi['mdmManage'];
  baseUrl3=this.svApi.urlApi['matter'];
  baseUrl4=this.svApi.urlApi['sysManage'];
  token={
    'x-access-current-token' :this.tokens.getToken(),
    'x-access-refresh-token' :this.tokens.getToken1(),
  };
  constructor(
    private http: HttpClient,
    private svApi:ServiceApiService,
    private tokens:TokenServiceService,
  ) { }
  handlePost(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.baseUrl + url;
    return this.http.post(url, data,{headers:tokens});
  } 
  handlePost2(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.baseUrl2 + url;
    return this.http.post(url, data,{headers:tokens});
  }
  handlePost3(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.baseUrl3 + url;
    return this.http.post(url, data,{headers:tokens});
  }
  handlePost4(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.baseUrl4 + url;
    return this.http.post(url, data,{headers:tokens});
  }
  handleLog(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.baseUrl1 + url;
    return this.http.post(url, data,{headers:tokens});
  }
  handlePostFile(url, data): Observable<any> {
    url = this.baseUrl + url;
    let header = new HttpHeaders(
      {
        'content-type': 'multipart/form-data',
        'x-access-current-token' :this.tokens.getToken(),
        'x-access-refresh-token' :this.tokens.getToken1(),
      }
    );
     
    return this.http.post(url, data,{headers:header});
  }
  handlePostFileTest(url,formData): Observable<any> {
    url = this.baseUrl + url;
    let header = new HttpHeaders(
      {
        'x-access-current-token' :this.tokens.getToken(),
        'x-access-refresh-token' :this.tokens.getToken1(),
      }
    );
     
    return this.http.post(url, formData,{headers:header,reportProgress: true, withCredentials: true});
  }
  handleGet(url, data): Observable<any> {
    url = this.baseUrl + url;
    return this.http.get(url,{params: data,headers:this.token});
  }
  handleGetLoad(url): Observable<any> {
    url = this.baseUrl + url;
    return this.http.get(url,{withCredentials: true,reportProgress: true,responseType:'blob'});
  }
  handleImgLoad(url){
    url = this.baseUrl + url+'&token='+this.tokens.getToken();
    return url;
  }

    //物料导入
  handleWlImportFile(url, formData): Observable<any> {
      let baseUrl=this.svApi.urlApi['matter'];
      url = baseUrl + url;
      let header = new HttpHeaders(
        {
          'x-access-current-token' :this.tokens.getToken(),
          'x-access-refresh-token' :this.tokens.getToken1(),
        }
      );
      return this.http.post(url, formData,{headers:header,reportProgress: true, withCredentials: true});
  }
}
