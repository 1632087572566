import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { PreloadingStrategy } from '@angular/router';
import { Observable,of } from 'rxjs';
import {delay,flatMap} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SelectivePreloadingStrategyService implements PreloadingStrategy {
  constructor() { }
  preload(route: Route, fn: () => Observable<boolean>): Observable<boolean> {
    return of(true).pipe(delay(3000)).pipe(flatMap((_: boolean) => fn()));
}

  // preload(route: Route, load: () => Observable<any>): Observable<any> {
    
  //     if (route.data && route.data['preload']) {
  //         return load();
  //     } else {
  //         return of(null);
  //     }
  // }
}
