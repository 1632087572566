import { Component, OnInit,Input, EventEmitter,Output} from '@angular/core';

@Component({
  selector: 'app-mater-tables',
  templateUrl: './mater-tables.component.html',
  styleUrls: ['./mater-tables.component.css']
})
export class MaterTablesComponent implements OnInit {
  @Input() tableArgsDatas:any;
  @Input() mdmDatas:any;
  @Output() handleBackEvent = new EventEmitter();
  allChecked = false;
  indeterminate = false;
  isShownMod= false;
  thDatas=[];
  pageIndex = 1;
  pageSize = 10;
  pageTotal = 0;
  dataSet = [];
  displayData = [...this.dataSet];
  value;
  sortName = null;
  sortValue = null;
  constructor() { }
  /**** 
  表格接口文档说明
  mdmDatas:页面展示主要数据，数据格式数组
  tableArgsDatas:页面主数据展示需要的参数，数据格式对象；
  数据格式：
  tableArgsDatas{
    thData:表格表头信息,
    wh:表格的宽度,
    total:表格数据总数,
    api:表格数据查询接口地址主要用于分页,
  }
  


 ***** */
  ngOnChanges(){
    let mdmArgs=this.tableArgsDatas;
    this.thDatas=mdmArgs.thData;
    this.dataSet=this.mdmDatas;
    this.displayData = [...this.dataSet];
  }
  ngOnInit() {
    
    
  }
  sort(sort: { key: string, value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }
  sortDown(args,$event){
    $event.stopPropagation();
    if(args&&this.dataSet.length!=0){
      this.displayData=[];
      let das = this.dataSet.sort((a, b) =>  (b[ args] > a[ args ] ? 1 : -1));
      this.displayData=[...das];
    } 
  }
  sortUp(args,$event){
    $event.stopPropagation();
    if(args&&this.dataSet.length!=0){
      this.displayData=[];
      let das = this.dataSet.sort((a, b) =>  (a[ args ] > b[ args ] ? 1 : -1));
      this.displayData=[...das];
    } 
  }
  search(): void {
  
    if (this.sortName && this.sortValue) {
      this.displayData=[];
      let das = this.dataSet.sort((a, b) => (this.sortValue === 'ascend') ? (a[ this.sortName ] > b[ this.sortName ] ? 1 : -1) : (b[ this.sortName ] > a[ this.sortName ] ? 1 : -1));
      this.displayData=[...das];
      this.sortName = null;
      this.sortValue = null;
    } 
  }
  goPage(number) {
    this.queryRole(number);
  }
  queryRole (page:number) {
    let params:any = {};
    params.page = page-1;
    params.pageSize = this.pageSize;
    params.conditions = [];
    // if (this.roleSearch.typeCode) {
    //   let code = {"name":"typeCode","operator":"like","type":"string","value":this.roleSearch.typeCode};
    //   params.conditions.push(code);
    // }
    // if (this.roleSearch.typeName) {
    //   let name = {"name":"typeName","operator":"like","type":"string","value":this.roleSearch.typeName};
    //   params.conditions.push(name);
    // }
    // this.authAPI.getRightTypeFindByCondition(params).subscribe(data => {
    //   this.authData= data.data.content;
    //   this.pageTotal =data.data.totalElements;
    // })
  }
  /***多选功能** */
  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.displayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.displayData.filter(value => !value.disabled).every(value => value.checked === true);
    const allUnChecked = this.displayData.filter(value => !value.disabled).every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.displayData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }
  
    /********* */
    /****模态框的逻辑** */
    showModal(): void {
      this.isShownMod = true;
    }
    backMode(args){
      this.isShownMod=args.mark;
      this.thDatas=args.datas;
    }
    isBlock(args){
      if(!args.checked){
        return 'none'
      }
    }
  
}
