import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot,CanLoad, CanActivate, Router, RouterStateSnapshot,ActivatedRoute,Route,  Params} from '@angular/router';
import {User} from '../login/login/login.component';
import {Observable} from 'rxjs';
import { TokenServiceService} from '../commons/services/token-service.service';
import { ButtonMenuService } from '../commons/services/button-menu.service'
import { HttpClient } from '@angular/common/http';
import { DmModalNameService }from '../dm/services/dm-modal-name.service'
import {map} from 'rxjs/operators';
interface LoginGuard {
  data: any;
  msg: string;
  status: boolean;
}
@Injectable({providedIn: "root"})
export class LoginauthService implements CanActivate, CanLoad{
  obj={
    name:"",
    code:"",
    id:""
  }
  constructor(private router: Router, private activatedRoute: ActivatedRoute,private token:TokenServiceService,
    private http:HttpClient,
    private modelService:DmModalNameService,
    private btnService:ButtonMenuService) {
  }
  // 如果校验失败，不会加载该模块
  // 在需要校验的模块添加 canLoad: [AuthGuard] 配置
  canLoad(route: Route): boolean{
    const login = this.token.getToken();
    return true;
    // if (login) {
    //   return true;
    // } else {
    //   // 浙江投
    //   //this.router.navigateByUrl('login')
    //    //window.location.href = 'http://10.100.3.168/sso/login?appid=2ab3df7e-e407-4f7b-b5ab-641b8cdb5cd3&service=http%3A%2F%2F10.100.1.170%3A8088%2Fcico%2Fsso%2Flogin1'
    //      window.location.href = 'http://newportal.cncico.com/sso/login?appid=2ab3df7e-e407-4f7b-b5ab-641b8cdb5cd3&service=http%3A%2F%2F10.100.211.53%3A8088%2Fcico%2Fsso%2Flogin1'
    //      // window.location.href = 'http://10.100.218.6/sso/login?appid=2ab3df7e-e407-4f7b-b5ab-641b8cdb5cd3&service=http%3A%2F%2F10.100.1.170%3A8088%2Fcico%2Fsso%2Flogin1'
    //    return false;
    // } 
  }
  // 如果加载失败调转到登陆页面
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const login = this.token.getToken();
      if (login && login!="null") {
        return true;
      } else {
        const token={
          currentToken:route.queryParams.currentToken,
          refreshToken:route.queryParams.refreshToken
        }
        const account = route.queryParams.account;
        const passData = {
          account: route.queryParams.account,
          password: '',
          userName:route.queryParams.account
        };
        if(route.queryParams.busiId){
          this.token.setToken(token);
          this.token.setLang('ZH');
          this.token.setUserInfo(passData);

          if(route.queryParams.dataType=="1"){
         //物料
          }else{
            this.obj={
              name:route.queryParams.busiName,
              code:route.queryParams.modelCode,
              id:route.queryParams.busiId
            }
            if(route.queryParams.actionType=="0"){
              this.btnService.getAudit(this.obj);
              let obj1={title:"数据审核",
                url:'main/dm/dmDataAudit',
                active:true,
                data:{
                  isNode:0,
                  breadcrumb:'数据管理>主数据>数据审核',
                  btnList:[],
                }}
              this.btnService.getTab(obj1)
              this.btnService.getModalInfo(this.obj);
              this.router.navigateByUrl('main/dm/dmDataAudit')

            }else if(route.queryParams.actionType=="1"){
              let obj1={title:"数据新增",
                url:'main/dm/dmDataApply',
                active:true,
                data:{
                  isNode:0,
                  breadcrumb:'数据管理>主数据>数据新增',
                  btnList:[],
                }}
              this.btnService.getTab(obj1)
              this.btnService.getModalInfo(this.obj);
              this.router.navigate(['main/dm/dmDataApply'])
             // this.router.navigateByUrl('main/dm/dmDataApply');
            }
          }
          this.token.setRedirect(true);
        }else{
          if (token.currentToken && account) {
            this.token.setToken(token);
            this.token.setLang('ZH');
            this.token.setUserInfo(passData);
            this.router.navigateByUrl('/main/index');
            return true;
        } else {
          this.router.navigateByUrl('login')
          // 浙江投
           //window.location.href = 'http://newportal.cncico.com/sso/login?appid=2ab3df7e-e407-4f7b-b5ab-641b8cdb5cd3&service=http%3A%2F%2F10.100.211.53%3A8088%2Fcico%2Fsso%2Flogin1'
           //window.location.href = 'http://10.100.218.6/sso/login?appid=2ab3df7e-e407-4f7b-b5ab-641b8cdb5cd3&service=http%3A%2F%2F10.100.1.170%3A8088%2Fcico%2Fsso%2Flogin1'
           return false;
        }
      }
      
      return false;
  }
  }
}
