import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule,Router,ActivatedRoute,NavigationEnd,ParamMap} from '@angular/router';
import { ViewStateService}from '../services/view-state.service';
@Component({
  selector: 'app-index-yuan',
  templateUrl: './index-yuan.component.html',
  styleUrls: ['./index-yuan.component.css']
})
export class IndexYuanComponent implements OnInit {
  toDoTit='个人待办';
  toDoUrl='';
  toDoDatas=[
    { key:"NAME",
      title:'代办类型',
    },
    { key:"ONEDAYNUMS",
      title:'1天',
    },
    { key:"TWODAYNUMS",
      title:'2天',
    },
    { key:"THREEDAYNUMS",
    title:'3天以上',
    },
  
    { key:'TOTALNUMS',
      title:'总数',
    },
  ];
  informTit='通知';
  informUrl='';
  informDatas=[
    { key:"type",
      title:'代办类型',
    },
    { key:"dayOne",
      title:'1天',
    },
    { key:"dayTwo",
      title:'2天',
    },
    { key:"more",
    title:'3天以上',
    },
    { key:'sum',
      title:'总数',
    },
  ];
  documentTit='制度标准';
  documentUrl='';
  documentDatas=[
    { key:"type",
      title:'代办类型',
    },
    { key:"dayOne",
      title:'1天',
    },
    { key:"dayTwo",
      title:'2天',
    },
    { key:"more",
    title:'3天以上',
    },
    { key:'sum',
      title:'总数',
    },
  ];
  dataList = [
    {
      type   : '1',
      dayOne   : '1',
      dayTwo  : "2",
      more: '11111',
      sum:"111111"
    },
    {
      type   : '2',
      dayOne   : '1',
      dayTwo  : "2",
      more: '11111',
      sum:"111111"
    },
    {
      type   : '3',
      dayOne   : '1',
      dayTwo  : "2",
      more: '11111',
      sum:"111111"
    }
  ];
  chartOption = {
    title: {
        text: '堆叠区域图'
    },
    tooltip : {
        trigger: 'axis'
    },
    // legend: {
    //     data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎']
    // },
    toolbox: {
        feature: {
            saveAsImage: {}
        }
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis : [
        {
            type : 'category',
            boundaryGap : false,
            data : ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        }
    ],
    yAxis : [
        {
            type : 'value'
        }
    ],
    series : [
        {
            name: '邮件营销',
            type: 'line',
            stack: '总量',
            areaStyle: {normal: {}},
            data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
            name: '联盟广告',
            type: 'line',
            stack: '总量',
            areaStyle: {normal: {}},
            data: [220, 182, 191, 234, 290, 330, 310]
        },
        {
            name: '视频广告',
            type: 'line',
            stack: '总量',
            areaStyle: {normal: {}},
            data: [150, 232, 201, 154, 190, 330, 410]
        },
        {
            name: '直接访问',
            type: 'line',
            stack: '总量',
            areaStyle: {normal: {}},
            data: [320, 332, 301, 334, 390, 330, 320]
        },
        {
            name: '搜索引擎',
            type: 'line',
            stack: '总量',
            label: {
                normal: {
                    show: true,
                    position: 'top'
                }
            },
            areaStyle: {normal: {}},
            data: [820, 932, 901, 934, 1290, 1330, 1320]
        }
    ]
}
  constructor(
    private router:Router,
    private viewAttr:ViewStateService,
  ) { }

  ngOnInit() {
    if(this.viewAttr.isRoute){
        let parms=['main/dm/dmFiledSetting'];
        this.router.navigate(parms);
        this.viewAttr.coloseIsRoute();
    }
  }
}