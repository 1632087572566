import { BrowserModule } from '@angular/platform-browser';
import {HashLocationStrategy , LocationStrategy} from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonsModule } from './commons/commons.module';
import { LoginModule } from './login/login.module';
import { LoginauthService } from './commons/loginauth.service';
import { SelectivePreloadingStrategyService } from './commons/services/selective-preloading-strategy.service';
import { ServiceApiService} from './commons/services/service-api.service';
import { TokenServiceService} from './commons/services/token-service.service';
import { MenuManageService} from './commons/services/menu-manage.service';
import { ViewStateService} from './commons/services/view-state.service';
import { UploadService } from './commons/services/upload.service';
import { ExportService } from './commons/services/export.service';
import { DmApiService } from './dm/services/dm-api.service';
import { AppRoutingModule } from './app-routing.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N,  zh_CN } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient,HttpClientJsonpModule  } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NgxNeditorModule } from '@notadd/ngx-neditor';
registerLocaleData(zh);
export function createTranslateHttpLoader(http:HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    //AppRoutingModule.forRoot(routes, { useHash: true }), //切换为有#的url
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    CommonsModule,
    LoginModule,
    NgxEchartsModule,
    NgxNeditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateHttpLoader,
        deps: [HttpClient]
      }
    }),

  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},SelectivePreloadingStrategyService,LoginauthService,ServiceApiService,TokenServiceService, MenuManageService,UploadService,ExportService,ViewStateService,DmApiService],
  bootstrap: [AppComponent]
  //{provide: LocationStrategy, useClass: HashLocationStrategy}, //hash（#）风格。
})
export class AppModule { }
