import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient ,HttpHeaders } from '@angular/common/http';
import { ServiceApiService} from '../../commons/services/service-api.service';
import { TokenServiceService} from '../../commons/services/token-service.service';

@Injectable({
  providedIn: 'root'
})
export class WfApiService {

  constructor(private http: HttpClient,private svApi:ServiceApiService, private tokens:TokenServiceService,) { }
  treeUrl=this.svApi.urlApi['mdmManage'];
  baseUrl=this.svApi.urlApi['wf'];
  sysUrl=this.svApi.urlApi['sysManage'];
  token={
    'x-access-current-token' :this.tokens.getToken(),
    'x-access-refresh-token' :this.tokens.getToken1(),
  };
  treePost(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.treeUrl + url;
    return this.http.post(url, data,{headers:tokens});
  }
  handlePost(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.baseUrl + url;
    return this.http.post(url, data,{headers:tokens});
  }
  queryPost(url,data):Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.sysUrl + url;
    return this.http.post(url, data,{headers:tokens});
  }
}
