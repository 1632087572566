import { Injectable } from '@angular/core';
import { subUnit } from '../commonModel/subUnit';
import { Observable } from 'rxjs';
import { HttpClient ,HttpHeaders } from '@angular/common/http';
import { ServiceApiService} from '../../commons/services/service-api.service';
import { TokenServiceService} from '../../commons/services/token-service.service';
import { ViewStateService}from '../../commons/services/view-state.service';

@Injectable({
  providedIn: 'root'
})
export class ViewArrangeService {
  showListTest=[
    {
      name:'测试1',
      id:1,
      cType:'listCol'
    },
    {
      name:'测试2',
      id:2,
      cType:'listCol'
    },
    {
      name:'测试3',
      id:3,
      cType:'listCol'
    },
    {
      name:'测试4',
      id:4,
      cType:'listCol'
    },
    {
      name:'测试5',
      id:5,
      cType:'listCol'
    },
    {
      name:'测试6',
      id:6,
      cType:'listCol'
    },

  ]
  constructor(
    private http: HttpClient,
    private svApi:ServiceApiService,
    private tokens:TokenServiceService,
    private pageDatas:ViewStateService
  ) { }
  baseUrl=this.svApi.urlApi['mdmManage'];
  baseUrl1=this.svApi.urlApi['file'];
  baseUrl2=this.svApi.urlApi['sysManage']
  token={
    'x-access-current-token' :this.tokens.getToken(),
    'x-access-refresh-token' :this.tokens.getToken1(),
  };
  handlePost(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.baseUrl + url;
    return this.http.post(url, data,{headers:tokens});
  }
  handlePost1(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.baseUrl2 + url;
    return this.http.post(url, data,{headers:tokens});
  }
  handlePostFile(url,formData): Observable<any> {
    url = this.baseUrl1 + url;
    let header = new HttpHeaders(
      {
        'x-access-current-token' :this.tokens.getToken(),
        'x-access-refresh-token' :this.tokens.getToken1(),
      }
    );
    return this.http.post(url, formData,{headers:header,reportProgress: true, withCredentials: true});
  }
    
  getFile(url, data): Observable<any> {
    url = this.baseUrl1 + url;
    let header = new HttpHeaders(
      {
        'x-access-current-token' :this.tokens.getToken(),
        'x-access-refresh-token' :this.tokens.getToken1(),
      }
    );
    return this.http.post(url, data,{headers:header});
  }
 
  random(lower, upper) {
    return Math.floor(Math.random() * (upper - lower+1)) + lower;
  }
  getIds(){
    return Number(new Date())+this.random(1,100);
  }
  handleMain(args){
  
    let showId=args.id;
    let showPids='-1';
    let data=this.getCtypeDatas(args,showId,showPids);
    return data;
  }
  handleCtype(args,obj){
    let showId='';
    let showPids='';
    let data;
    if(obj['cType']=='grid'){
      showId=this.getIds();
      showPids=obj['id'];
      data=[];
      let oCol={cType:'col',
        id:this.getIds(),
        pid:showPids,
        tabType:'',
        attrList:{},
      }
      let resg=this.getCtypeDatas(args,showId,showPids);
      data.push(resg);
      data.push(oCol);
      
    }else if(obj['cType']=='col'){
      showPids=obj['pid'];
      showId=this.getIds();
      data=this.getCtypeDatas(args,showId,showPids);
    }else if(obj['cType']=='tabsCol'){
      showPids=obj['id'];
      showId=this.getIds();
      data=this.getCtypeDatas(args,showId,showPids);
    }else if(obj['cType']=='collapseCol'){
      showPids=obj['id'];
      showId=this.getIds();
      data=this.getCtypeDatas(args,showId,showPids);
    }else{
      // showPids='-1';
      showPids=obj['pid'];
      showId=this.getIds();
      data=this.getCtypeDatas(args,showId,showPids);
    }
    
   
    return data;

  }
  getCtypeDatas(args,showId,showPids){
  ;
    let obj=new subUnit('','',{},[]);
    if(args['cType']=='text'){
        obj['cType']='input';
        obj['id']=showId;
        obj['pid']=showPids;
        obj['modelId'] = args['modelId'];
        obj['tabType']='fileTab';
        obj['attrList']={
          basicSet:{
            bindtit:args['attrColumn'],
            cType:'input',
            key:args['realColumn'],
            attrName:args['name'],
            attrId:args['id']
          },
          dataSet:{
            tip:'',
            title: args['name'],
            inConent:'',
            contentFromType:'2',
            showType:'1',
            useableType:'1',
            isMulitValue:args.isMulitValue,
            isMulitLan:args.isMulitLan,
            bindQueryBtn: args.bindQueryBtn,
          },
          styleSet:{
            wh:'80',
            textAligan:'left',
            fontStyle:'normal-sty',
            fontSize:'12',
            fontClore:'#000',
            isImg:false,
            imgUrl:'',
            marLeft:"25"
          },
          ruleSet:{
            minLength:0,
            maxLength:args.attrZs,
            floatLength:args.attrXs,
            floatMinLength: 0, 
            nullType:'1',
            uniqueType:'0',
            uniqueExp:'',
            nullExp:'',
            rule:'0',
            ruleVal:'',
            errorText:'',
            uniqueDiffJTFT: '0',
            uniqueDiffBJQJ: '0'
          },
          saftSet:{
            safeLevel:'',
            safeType:'1',
            dataSaft:false,
            dataShow:false,
            dataTransfer:false,
            safeEncryptType:'',
            safeMaskType:'',
            markSeat:'',
            markStyle:'',
            safeReplaceType:'',
            replaceOld:'',
            replaceNew:'',
            safeResetType:'',
            safeMixType:'',
          }
        }
    }else if(args['cType']=='grid'){
      obj['cType']='grid';
      obj['id']=this.getIds();
      obj['pid']=showPids;
      obj['tabType']='layout';
      obj['attrList']={
          basicSet:{
            row:1,
            col:[
              {colSpan:12},
              {colSpan:12},
            ],
            align:'top',
            gutter:1,
            justify:'start',
          }
      };
      obj['datas']=[
        [{
            cType:'col',
            id:this.getIds(),
            pid:obj['id'],
            tabType:'',
            attrList:{}
        },{
          cType:'col',
            id:this.getIds(),
            pid:obj['id'],
            tabType:'',
            attrList:{}
        }],
      ];
    }else if(args['cType']=='divider'){
      obj['cType']='divider';
      obj['id']=this.getIds();
      obj['pid']=showPids;
      obj['tabType']='layout';
      obj['attrList']={
          basicSet:{
            bindtit:args['attrColumn'],
            cType:'divider',
            key:args['realColumn'],
            attrName:args['name'],
              text:'分割线',
              orientation:'left',
              lineTyle:0,
              lineClolor:'#e8e8e8',
              imgState:false,
              imgUrl:'',
          }
      };
      obj['datas']=[];
    }else if(args['cType']=='tabs'){
      obj['cType']='tabs';
      obj['id']=this.getIds();
      obj['pid']='-1';
      obj['tabType']='layout';
      obj['attrList']={
          basicSet:{
            tabStyle:'line',
            tabPosition:'top',
            rowList:[
              {name:'标签页',active:true,icon:'',id:this.getIds(),checked:true},
            ],
            relateLimit:false,
          }
      };
      let colObjs={};
      colObjs['cType']='tabsCol';
      colObjs['id']=this.getIds();
      colObjs['pid']=obj['id'];
      colObjs['tabId']=obj['attrList']['basicSet'].rowList[0].id;
      colObjs['tabType']='';
      colObjs['attrList']={};
      colObjs['datas']=[];
      obj['datas']=[];
      obj['datas'].push(colObjs); 
     
    }else if(args['cType']=='collapse'){
      obj['cType']='collapse';
      obj['id']=this.getIds();
      obj['pid']='-1';
      obj['tabType']='layout';
      obj['attrList']={
        basicSet:{
          isOnly:false,
          isBorder:true,
          background:'#fff',
          isIcon:false,
          iconsUrl:'',
          rowList:[
            {name:'折叠面板',active:true,disabled:false,id:this.getIds()},
          ],
        }
      };
      let colObjs={};
      colObjs['cType']='collapseCol';
      colObjs['id']=this.getIds();
      colObjs['pid']=obj['id'];
      colObjs['tabId']=obj['attrList']['basicSet'].rowList[0].id;
      colObjs['tabType']='';
      colObjs['attrList']={};
      colObjs['datas']=[];
      obj['datas']=[];
      obj['datas'].push(colObjs);
    }else if(args['cType']=='listRow'){
      obj['cType']='listRow';
      obj['id']=this.getIds();
      obj['pid']='-1';
      obj['tabType']='dataTab';
      obj['attrList']={
        dataSet:{
          rowTit:[],
          orderList:[],
        }
      };

      for(let j=0,jl=this.showListTest.length;j<jl;j++){
        this.showListTest[j]['pid']=obj['id'];
      }
      obj['datas']=this.showListTest;
    }else if(args['cType']=='tree'){
      let fontList=this.pageDatas.getAttrTem();
      let moduName=this.pageDatas.temView['data'].name;
      let treeDatas=this.bulidTree(fontList);
      obj['cType']='tree';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId'] = args['modelId'];
      obj['tabType']='selTab';
      obj['attrList']={
        basicSet:{
          bindtit:moduName,
          cType:'tree',
          cName:moduName,
          key:args['realColumn'],
          attrName:args['name'],
          attrId:args['id']
        },
        dataSet:{
          tip:'',
          title: args['name'],
          dataFromType:'0',
          allShowSelList:fontList,
          pFont:fontList[0].id,
          newShowSelList:[],
          oldShowSelList:treeDatas,
          newMoudType:'',
          reactOwn:'',
          reactOther:'',
        },
        ruleSet: {
          maxLength:args.attrZs,
          floatLength:args.attrXs,
          nullType:'1',
          uniqueType:'0',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        }
      }
    }else if(args['cType']=='datePicker'||args['cType']=='date'){
      obj['cType']='datePicker';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId'] = args['modelId'];
      obj['tabType']='pickerTab';
      obj['attrList']={
        basicSet:{
          bindtit:args['attrColumn'],
          cType:'datePicker',
          key:args['realColumn'],
          attrName:args['name'],
          attrId:args['id']
        },
        dataSet:{
          tip:'',
          wh: '80',
          title: args['name'],
          inConent:'',
          dateType:'0',
          contentFromType:'2',
          showType:'1',
          useableType:'1',
          isMulitValue:args.isMulitValue,
          isMulitLan:args.isMulitLan,
          bindQueryBtn: []
        },
        ruleSet:{
          maxLength:args.attrZs,
          floatLength:args.attrXs,
          nullType:'1',
          rule:'0',
          ruleVal:'',
          errorText:'',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        },
      }
    }else if(args['cType']=='timePicker'){
      obj['cType']='timePicker';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId'] = args['modelId'];
      obj['tabType']='pickerTab';
      obj['attrList']={
        basicSet:{
          bindtit:args['attrColumn'],
          cType:'timePicker',
          key:args['realColumn'],
          attrName:args['name'],
          attrId:args['id']
        },
        dataSet:{
          tip:'',
          title: args['name'],
          inConent:null,
          wh: '80',
          dateType:'0',
          contentFromType:'2',
          showType:'1',
          useableType:'1',
          isMulitValue:args.isMulitValue,
          isMulitLan:args.isMulitLan,
          bindQueryBtn: []
        },
        ruleSet:{
          maxLength:args.attrZs,
          floatLength:args.attrXs,
          nullType:'1',
          rule:'0',
          ruleVal:'',
          errorText:'',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        },
      }
    }else if(args['cType']=='upload'){
      obj['cType']='upload';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId'] = args['modelId'];
      obj['tabType']='basicTab';
      obj['attrList']={
        basicSet:{
          bindtit:args['attrColumn'],
          cType:'upload',
          cName:args['name'],
          key:args['realColumn'],
          attrName:args['name'],
          attrId:args['id']
        },
        dataSet:{
          title:args['name'],
          tip:'',
          inConent:[],
          dateType:'0',
          contentFromType:'2',
          showType:'1',
          useableType:'1',
          isMulitValue:args.isMulitValue,
          isMulitLan:args.isMulitLan,
          btnText:"",
          bindQueryBtn: []
        },
        ruleSet:{
          maxLength:args.attrZs,
          floatLength:args.attrXs,
          nullType:'1',
          number:1,
          size:1,
          ruleVal:'',
          errorText:'',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        },
        styleSet:{
          fileType:"",
          fileValue:""
        }
      }
    }else if(args['cType']=='popupTable'){
      obj['cType']='popupTable';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId'] = args['modelId'];
      obj['tabType']='pickerTab';
      obj['attrList']={
          basicSet:{
            bindtit:args['attrColumn'],
            cType:'popupTable',
            cName:args['name'],
            key:args['realColumn'],
            attrName:args['name'],
            attrId:args['id']
          },
          dataSet:{
            title:args['name'],
            tip:'',
            inConent:{id:'',name:''},
            modeConent:{id:'',name:''},
            showFontOrder:[],
            dateType:'0',
            contentFromType:'2',
            showType:'1',
            useableType:'1',
            isMulitValue:args.isMulitValue,
            modelSelVal: [],
            bindQueryBtn: []
          },
          ruleSet:{
            maxLength:args.attrZs,
            floatLength:args.attrXs,
            nullType:'1',
            rule:'0',
            ruleVal:'',
            errorText:'',
            uniqueDiffJTFT: '0',
            uniqueDiffBJQJ: '0'
          },
      }
    }else if(args['cType']=='subTable'||args['cType']=='fontList'){
      obj['cType']='subTable';
      obj['id']=args['id'];
      obj['pid']=showPids;
      obj['modelId'] = args['modelId'];
      obj['tabType']='selTab';
      obj['attrList']={
        basicSet:{
          bindtit:args['attrColumn'],
          cType:'subTable',
          cName:args['name'],
          key:args['realColumn'],
          attrName:args['name'],
          attrId:args['id']
        },
        dataSet:{
          title:args['name'],
          tip:'',
          inConent:{name:'',id:''},
          fontList:[],
          showType:'1',
          useableType:'1',
          fontListValue:{name:'',id:''},
          showFontOrder:[],
          bindQueryBtn: []
        },
        ruleSet: {
          maxLength:args.attrZs,
          floatLength:args.attrXs,
          nullType:'1',
          uniqueType:'0',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        }
      }
      obj['datas']=[];
      obj['rowDatas']=[];
      for(let i=0,il=args.children.length;i<il;i++){
        let passIds=args.children[i]['id'];
        let passObj=args.children[i];
        let arys=this.getCtypeDatas(passObj,passIds,args['id']);
        obj['datas'].push(arys);
      }
    }else if(args['cType']=='password'){
      obj['cType']='password';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId'] = args['modelId'];
      obj['tabType']='fileTab';
      obj['attrList']={
        basicSet:{
          bindtit:args['attrColumn'],
          cType:'password',
          key:args['realColumn'],
          attrName:args['name'],
          attrId:args['id']
        },
        dataSet:{
          tip:'',
          title: args['name'],
          inConent:'',
          contentFromType:'2',
          showType:'1',
          useableType:'1',
          isMulitValue:args.isMulitValue,
          isMulitLan:args.isMulitLan,
          bindQueryBtn: []
        },
        styleSet:{
          wh:'80',
          textAligan:'left',
          fontStyle:'normal-sty',
          fontSize:'12',
          fontClore:'#000',
          isImg:false,
          imgUrl:'',
          marLeft:"25"
        },
        ruleSet:{
          minLength:0,
          maxLength:args.attrZs,
          floatLength:args.attrXs,
          floatMinLength: 0,
          nullType:'1',
          uniqueType:'0',
          uniqueExp:'',
          nullExp:'',
          rule:'0',
          ruleVal:'',
          errorText:'',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        },
        saftSet:{
          safeLevel:'',
          safeType:'1',
          dataSaft:false,
          dataShow:false,
          dataTransfer:false,
          safeEncryptType:'',
          safeMaskType:'',
          markSeat:'',
          markStyle:'',
          safeReplaceType:'',
          replaceOld:'',
          replaceNew:'',
          safeResetType:'',
          safeMixType:'',
        }
      }
    }else if(args['cType']=='treeSelect'){
      let fontList=this.pageDatas.getAttrTem();
      let moduName=this.pageDatas.temView['data'].name;
      let treeDatas=this.bulidTree(fontList);
      obj['cType']='treeSelect';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId'] = args['modelId'];
      obj['tabType']='pickerTab';
      obj['attrList']={
        basicSet:{
          bindtit:moduName,
          cType:'treeSelect',
          cName:moduName,
          key:args['realColumn'],
          attrName:args['name'],
          attrId:args['id']
        },
        dataSet:{
          tip:'',
          title: args['name'],
          dataFromType:'1',
          inConent: '',
          inConent1: '',
          allShowSelList:fontList,
          pFont:fontList[0].id,
          newShowSelList:[],
          oldShowSelList:treeDatas,
          newMoudType:'',
          reactOwn:'',
          reactOther:'',
          useableType: '1',
          showType: '1',
          isMulitValue:args.isMulitValue,
          bindQueryBtn: [],
          treeRootId:""
        },
        ruleSet:{
          maxLength:args.attrZs,
          floatLength:args.attrXs,
          nullType:'1',
          rule:'0',
          ruleVal:'',
          errorText:'',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        },
      }
    }
    return obj;
  }
  getAttrDatas(args){
    let showId=args.id;
    let showPids=args.pid;
    let modelId=args.modelId;
    const cType=args.cType;
    const datas=args.datas;
    let obj=new subUnit('','',{},[]);
    if(cType=='select'){
      obj['cType']='select';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId'] = modelId;
      obj['tabType']='selTab';
      obj['attrList']={
        basicSet:{
          bindtit:datas.attrList.basicSet.bindtit,
          cType:'select',
          attrName:datas.attrList.basicSet.attrName,
          key:datas.attrList.basicSet.key,
          attrId:datas.attrList.basicSet.attrId
        },
        dataSet:{
          tip:'',
          title: datas.attrList.basicSet.attrName,
          inConent:'',
          contentFromType:'6',
          fromModel:'',
          showField:'',
          cascadedAttr:'',
          cascadedForeignKey:'',
          isCustomize:false,
          customizeOpt:[
            {lable:'',check:true,val:''}
          ],
          customizeVal:[],
          modelSel: {id: '', name:''},
          modelSelVal: [],
          modelOpt:[{lable:'',val:'', check: false}],
          modelSelOrder: [],
          reactModelSel: {id: '', name: ''},
          termBegin:'',
          termEquel:'',
          reactModelVal: [],
          reactOpt:[
            {lable:'',check:true,val:''}
          ],
          showType:'1',
          useableType:'1',
          nullType:'1',
          isMulitValue:datas.attrList.dataSet.isMulitValue,
          isMulitLan:datas.attrList.dataSet.isMulitLan,
          defaultValue:'',
          bindQueryBtn: datas.attrList.dataSet.bindQueryBtn
        },
        styleSet:{
          wh:'80',
          marLeft:"25"
        },
        ruleSet: {
          minLength:0,
          maxLength:datas.attrList.ruleSet.maxLength,
          floatLength:datas.attrList.ruleSet.floatLength,
          floatMinLength: 0,
          nullType:'1',
          uniqueType:'0',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0',
          rule: '0'
        }
      }
    }else if(cType=='input'){
      obj['cType']='input';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId']=modelId;
      obj['tabType']='fileTab';
      obj['attrList']={
        basicSet:{
          bindtit:datas.attrList.basicSet.bindtit,
          cType:'input',
          key:datas.attrList.basicSet.key,
          attrName:datas.attrList.basicSet.attrName,
          attrId:datas.attrList.basicSet.attrId
        },
        dataSet:{
          tip:'',
          title: datas.attrList.basicSet.attrName,
          inConent:'',
          contentFromType:'2',
          showType:'1',
          useableType:'1',
          isMulitValue:datas.attrList.dataSet.isMulitValue,
          isMulitLan:datas.attrList.dataSet.isMulitLan,
          bindQueryBtn: datas.attrList.dataSet.bindQueryBtn
        },
        styleSet:{
          wh:'80',
          textAligan:'left',
          fontStyle:'normal-sty',
          fontSize:'12',
          fontClore:'#000',
          isImg:false,
          imgUrl:'',
          marLeft:"25"
        },
        ruleSet:{
          minLength:0,
          maxLength:datas.attrList.ruleSet.maxLength,
          floatLength:datas.attrList.ruleSet.floatLength,
          floatMinLength: 0,
          nullType:'1',
          uniqueType:'0',
          uniqueExp:'',
          nullExp:'',
          rule:'0',
          ruleVal:'',
          errorText:'',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        },
        saftSet:{
          safeLevel:'',
          safeType:'1',
          dataSaft:false,
          dataShow:false,
          dataTransfer:false,
          safeEncryptType:'',
          safeMaskType:'',
          markSeat:'',
          markStyle:'',
          safeReplaceType:'',
          replaceOld:'',
          replaceNew:'',
          safeResetType:'',
          safeMixType:'',
        }
      }
    }else if(cType=='radio'){
      obj['cType']='radio';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId']=modelId;
      obj['tabType']='selTab';
      obj['attrList']={
        basicSet:{
          bindtit:datas.attrList.basicSet.bindtit,
          cType:'radio',
          cName:datas.attrList.basicSet.bindtit,
          key:datas.attrList.basicSet.key,
          attrName:datas.attrList.basicSet.attrName,
          attrId:datas.attrList.basicSet.attrId,
          marLeft:"25"
        },
        dataSet:{
          title:datas.attrList.basicSet.attrName,
          tip:'',
          inConent:'',
          radioData:[],
          customizeVal:[],
          contentFromType:'6',
          modelSel: {id: '', name:''},
          modelSelVal:[],
          showType:'1',
          useableType:'1',
          isMulitValue:datas.attrList.dataSet.isMulitValue,
          isMulitLan:datas.attrList.dataSet.isMulitLan,
          bindQueryBtn: datas.attrList.dataSet.bindQueryBtn
        },
        ruleSet: {
          minLength:0,
          maxLength:datas.attrList.ruleSet.maxLength,
          floatLength:datas.attrList.ruleSet.floatLength,
          floatMinLength: 0,
          nullType:'1',
          uniqueType:'0',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0',
          rule:'0'
        }
      }
    }else if(cType=='textarea'){
      obj['cType']='textarea';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId']=modelId;
      obj['tabType']='field';
      obj['attrList']={
        basicSet:{
          bindtit:datas.attrList.basicSet.bindtit,
          cType:'textarea',
          cName:datas.attrList.basicSet.bindtit,
          key:datas.attrList.basicSet.key,
          attrName:datas.attrList.basicSet.attrName,
          attrId:datas.attrList.basicSet.attrId
        },
        dataSet:{
          showTite:datas.attrList.basicSet.attrName,
          title:datas.attrList.basicSet.attrName,
          tip:'',
          inConent:'',
          contentFromType:'2',
          showType:'1',
          useableType:'1',
          isMulitValue:datas.attrList.dataSet.isMulitValue,
          isMulitLan:datas.attrList.dataSet.isMulitLan,
          bindQueryBtn: datas.attrList.dataSet.bindQueryBtn
        },
        styleSet:{
          row:'4',
          wh: '80',
          textAligan:'left',
          fontStyle:0,
          fontSize:'12',
          fontClore:'#000',
          isImg:false,
          imgUrl:'',
          marLeft:"25"
        },
        ruleSet: {
          minLength:0,
          maxLength:datas.attrList.ruleSet.maxLength,
          floatLength:datas.attrList.ruleSet.floatLength,
          floatMinLength: 0,
          nullType:'1',
          uniqueType:'0'
        }
      }
    }else if(cType=='label'){
      obj['cType']='label';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId']=modelId;
      obj['tabType']='field';
      obj['attrList']={
        basicSet:{
          bindtit:datas.attrList.basicSet.bindtit,
          cType:'label',
          cName:datas.attrList.basicSet.bindtit,
          key:datas.attrList.basicSet.key,
          attrName:datas.attrList.basicSet.attrName,
          attrId:datas.attrList.basicSet.attrId
        },
        dataSet:{
          showTite:datas.attrList.basicSet.attrName,
          title:datas.attrList.basicSet.attrName,
          tip:'',
          inConent:'',
          contentFromType:'2',
          showType:'1',
          useableType:'1',
          isMulitValue:datas.attrList.dataSet.isMulitValue,
          isMulitLan:datas.attrList.dataSet.isMulitLan,
          bindQueryBtn: datas.attrList.dataSet.bindQueryBtn
        },
        styleSet:{
          textAligan:'left',
          fontStyle:'normal-sty',
          fontSize:'12',
          fontClore:'#000',
          isImg:false,
          imgUrl:'',
          marLeft:"25"
        },
        ruleSet: {
          minLength:0,
          maxLength:datas.attrList.ruleSet.maxLength,
          floatLength:datas.attrList.ruleSet.floatLength,
          floatMinLength: 0,
          nullType:'1',
          uniqueType:'0'
        }
      }
    }else if(cType=='datePicker'){
      obj['cType']='datePicker';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId']=modelId;
      obj['tabType']='pickerTab';
      obj['attrList']={
        basicSet:{
          bindtit:datas.attrList.basicSet.bindtit,
          cType:'datePicker',
          key:datas.attrList.basicSet.key,
          attrName:datas.attrList.basicSet.attrName,
          attrId:datas.attrList.basicSet.attrId
        },
        dataSet:{
          tip:'',
          title:datas.attrList.basicSet.attrName,
          inConent:'' ,
          wh: '80',
          dateType:'0',
          contentFromType:'2',
          showType:'1',
          useableType:'1',
          isMulitValue:datas.attrList.dataSet.isMulitValue,
          isMulitLan:datas.attrList.dataSet.isMulitLan,
          bindQueryBtn: datas.attrList.dataSet.bindQueryBtn
        },
        ruleSet:{
          minLength:0,
          maxLength:datas.attrList.ruleSet.maxLength,
          floatLength:datas.attrList.ruleSet.floatLength,
          floatMinLength: 0,
          nullType:'1',
          rule:'0',
          ruleVal:'',
          errorText:'',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        },
      }
    }else if(cType=='upload'){
      obj['cType']='upload';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId']=modelId;
      obj['tabType']='basicTab';
      obj['attrList']={
        basicSet:{
          bindtit:datas.attrList.basicSet.bindtit,
          cType:'upload',
          key:datas.attrList.basicSet.key,
          attrName:datas.attrList.basicSet.attrName,
          attrId:datas.attrList.basicSet.attrId
        },
        dataSet:{
          tip:'',
          title:datas.attrList.basicSet.attrName,
          inConent: [],
          dateType:'0',
          contentFromType:'2',
          showType:'1',
          useableType:'1',
          isMulitValue:datas.attrList.dataSet.isMulitValue,
          isMulitLan:datas.attrList.dataSet.isMulitLan,
          btnText:"",
          bindQueryBtn: datas.attrList.dataSet.bindQueryBtn
        },
        ruleSet:{
          minLength:0,
          maxLength:datas.attrList.ruleSet.maxLength,
          floatLength:datas.attrList.ruleSet.floatLength,
          floatMinLength: 0,
          nullType:'1',
          number:1,
          size:1,
          ruleVal:'',
          errorText:'',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        },
        styleSet:{
          fileType:"",
          fileValue:""
        }
      }
    }else if(cType=='popupTable'){
      obj['cType']='popupTable';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId']=modelId;
      obj['tabType']='pickerTab';
      obj['attrList']={
          basicSet:{
            bindtit:datas.attrList.basicSet.bindtit,
            cType:'popupTable',
            key:datas.attrList.basicSet.key,
            attrName:datas.attrList.basicSet.attrName,
            attrId:datas.attrList.basicSet.attrId
          },
          dataSet:{
            title:datas.attrList.basicSet.attrName,
            tip:'',
            inConent:{id:'',name:''},
            modeConent:{id:'',name:''},
            showFontOrder:[],
            dateType:'0',
            contentFromType:'2',
            showType:'1',
            useableType:'1',
            isMulitValue:datas.attrList.dataSet.isMulitValue,
            modelSelVal: [],
            bindQueryBtn: datas.attrList.dataSet.bindQueryBtn
          },
          ruleSet:{
            minLength:0,
            maxLength:datas.attrList.ruleSet.maxLength,
            floatLength:datas.attrList.ruleSet.floatLength,
            floatMinLength: 0,
            nullType:'1',
            rule:'0',
            ruleVal:'',
            errorText:'',
            uniqueDiffJTFT: '0',
            uniqueDiffBJQJ: '0'
          },
      }
    }else if(cType=='timePicker'){
      obj['cType']='timePicker';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId']=modelId;
      obj['tabType']='pickerTab';
      obj['attrList']={
        basicSet:{
          bindtit:datas.attrList.basicSet.bindtit,
          cType:'timePicker',
          key:datas.attrList.basicSet.key,
          attrName:datas.attrList.basicSet.attrName,
          attrId:datas.attrList.basicSet.attrId
        },
        dataSet:{
          tip:'',
          title:datas.attrList.basicSet.attrName,
          inConent:null,
          dateType:'0',
          contentFromType:'2',
          showType:'1',
          useableType:'1',
          wh: '80',
          isMulitValue:datas.attrList.dataSet.isMulitValue,
          isMulitLan:datas.attrList.dataSet.isMulitLan,
          bindQueryBtn: datas.attrList.dataSet.bindQueryBtn
        },
        ruleSet:{
          minLength:0,
          maxLength:datas.attrList.ruleSet.maxLength,
          floatLength:datas.attrList.ruleSet.floatLength,
          floatMinLength: 0,
          nullType:'1',
          rule:'0',
          ruleVal:'',
          errorText:'',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        },
      }
    }else if(cType=='password'){
      obj['cType']='password';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId']=modelId;
      obj['tabType']='fileTab';
      obj['attrList']={
        basicSet:{
          bindtit:datas.attrList.basicSet.bindtit,
          cType:'password',
          key:datas.attrList.basicSet.key,
          attrName:datas.attrList.basicSet.attrName,
          attrId:datas.attrList.basicSet.attrId
        },
        dataSet:{
          tip:'',
          title:datas.attrList.basicSet.attrName,
          inConent:'',
          contentFromType:'2',
          showType:'1',
          useableType:'1',
          isMulitValue:datas.attrList.dataSet.isMulitValue,
          isMulitLan:datas.attrList.dataSet.isMulitLan,
          bindQueryBtn: datas.attrList.dataSet.bindQueryBtn
        },
        styleSet:{
          wh:'80',
          textAligan:'left',
          fontStyle:0,
          fontSize:'12',
          fontClore:'#000',
          isImg:false,
          imgUrl:'',
          marLeft:"25"
        },
        ruleSet:{
          minLength:0,
          maxLength:datas.attrList.ruleSet.maxLength,
          floatLength:datas.attrList.ruleSet.floatLength,
          floatMinLength: 0,
          nullType:'1',
          uniqueType:'0',
          uniqueExp:'',
          nullExp:'',
          rule:'0',
          ruleVal:'',
          errorText:'',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        },
        saftSet:{
          safeLevel:'',
          safeType:'1',
          dataSaft:false,
          dataShow:false,
          dataTransfer:false,
          safeEncryptType:'',
          safeMaskType:'',
          markSeat:'',
          markStyle:'',
          safeReplaceType:'',
          replaceOld:'',
          replaceNew:'',
          safeResetType:'',
          safeMixType:'',
        }
      }
    }else if(cType=='checkbox'){
      obj['cType']='checkbox';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId']=modelId;
      obj['tabType']='selTab';
      obj['attrList']={
        basicSet:{
          bindtit:datas.attrList.basicSet.bindtit,
          cType:'checkbox',
          cName:datas.attrList.basicSet.bindtit,
          key:datas.attrList.basicSet.key,
          attrName:datas.attrList.basicSet.attrName,
          attrId:datas.attrList.basicSet.attrId
        },
        dataSet:{
          title:datas.attrList.basicSet.attrName,
          tip:'',
          inConent:[],
          checkData:[],
          customizeVal:[],
          modelSel:{id:'',name:''},
          modelSelVal:[],
          contentFromType:'6',
          showType:'1',
          useableType:'1',
          isMulitValue: datas.attrList.dataSet.isMulitValue,
          isMulitLan:datas.attrList.dataSet.isMulitLan,
          bindQueryBtn: datas.attrList.dataSet.bindQueryBtn
        },
        ruleSet: {
          minLength:0,
          maxLength:datas.attrList.ruleSet.maxLength,
          floatLength:datas.attrList.ruleSet.floatLength,
          floatMinLength: 0,
          nullType:'1',
          uniqueType:'0',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0',
          rule:'0'
        }
      }
    }else if(cType=='treeSelect'){
      let fontList=this.pageDatas.getAttrTem();
      let moduName=this.pageDatas.temView['data'].name;
      let treeDatas=this.bulidTree(fontList);
      obj['cType']='treeSelect';
      obj['id']=showId;
      obj['pid']=showPids;
      obj['modelId']=modelId;
      obj['tabType']='pickerTab';
      obj['attrList']={
        basicSet:{
          bindtit:datas.attrList.basicSet.bindtit,
          cType:'treeSelect',
          cName:datas.attrList.basicSet.bindtit,
          key:datas.attrList.basicSet.key,
          attrName:datas.attrList.basicSet.attrName,
          attrId:datas.attrList.basicSet.attrId
        },
        dataSet:{
          tip:'',
          title:datas.attrList.basicSet.attrName,
          dataFromType:'1',
          allShowSelList:fontList,
          pFont:'',
          inConent: '',
          inConent1: '',
          newShowSelList:[],
          oldShowSelList:treeDatas,
          newMoudType:{id:'',name: ''},
          reactOwn:'',
          reactOther:'',
          showType: '1',
          useableType: '1',
          isMulitValue: datas.attrList.dataSet.isMulitValue,
          bindQueryBtn: datas.attrList.dataSet.bindQueryBtn,
          treeRootId:datas.attrList.dataSet.treeRootId
        },
        ruleSet:{
          minLength:0,
          maxLength:datas.attrList.ruleSet.maxLength,
          floatLength:datas.attrList.ruleSet.floatLength,
          floatMinLength: 0,
          nullType:'1',
          rule:'0',
          ruleVal:'',
          errorText:'',
          uniqueDiffJTFT: '0',
          uniqueDiffBJQJ: '0'
        },
      }
    }
    return obj;
  }
  bulidTree(args){
    let nodes=[];
    let obj={
      title: args[0].name,
      key: args[0].id,
      expanded: true,
      children: [],
    }
    for(let i=1,n=args.length;i<3;i++){
      if(args[i]){
        let nodeObj={
          title:args[0].name+"--"+args[0].name,
          key: args[i].id,
        }
        obj.children.push(nodeObj);
      }
    }
    nodes.push(obj);
    return nodes;
  }
}
