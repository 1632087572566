import { Injectable } from '@angular/core';
import { TokenServiceService} from '../services/token-service.service';

@Injectable({
  providedIn: 'root'
})
export class AutoInternationService {
  /**
   * 国际化方法获取中英文提示
   */
  constructor(
    private token:TokenServiceService,
  ) { }
  messages={
    general:{
       ZH:{
        msg:'提示',
        dels:"删除成功",
        delf:"删除失败",
        y:"是",
        n:"否",
        add:'新增',
        adds:'新增成功',
        addf:'新增失败',
        edit:'编辑',
        delete:'删除',
        copy:'复制',
        copys:'复制成功',
        copyf:'复制失败',
        modifys:'修改成功',
        modifyf:'修改失败',
        saves:'保存成功',
        savef:'保存失败',
        operations:'操作成功',
        operationf:'操作失败',
        resets:'重置成功',
        resetf:'重置失败',
        detailsofTodolist:'待办明细',
        notices:'通知',
        enable:'启用',
        disable:'停用',
        1:'你确定要删除吗?',
        2:'请选择要操作的数据',
        3:'请选择一条要操作的数据',
        4:'删除模型将清空所有业务数据,不可恢复，是否执行?',
        5:'请填写完整信息',
        6:'请选择小类代码',
        7:'请填写关键字',
        8:'当前数据停用状态不可操作!',
        9:'新密码为',
        10:'没有系统权限，请联系管理员',
        query:"数据查询",
        new:"数据新增",
        modification:"数据修改",
        review:"数据审核"
       },
       EN:{
        msg:'Prompt',
        dels:"Delete success",
        delf:"Delete failed",
        y:"Yes",
        n:"No",
        add:'Add',
        adds:'Add success',
        addf:'Add failed',
        edit:'Edit',
        delete:'Delete',
        copy:'Copy',
        copys:'Copy success',
        copyf:'Copy failure',
        modifys:'Modify success',
        modifyf:'Modify failed',
        saves:'Save success',
        savef:'Save failed',
        operations:'Operation success',
        operationf:'Operation failed',
        resets:'Reset success',
        resetf:'Reset failed',
        detailsofTodolist:'details of to-do list',
        notices:'notices',
        enable:'Enable',
        disable:'Disable',
        1:'Are you sure you want to delete it?',
        2:'Select the data you want to manipulate',
        3:'Select a piece of data to manipulate',
        4:'Deleting the model will empty all business data, unrecoverable, whether executed or not?',
        5:'Please complete the information',
        6:'Please select a small class code',
        7:'Please fill in the key words',
        8:'The current data outage state is not operational!',
        9:'The new password',
        10:'No system permissions, please contact the administrator',
        query:"Data query",
        new:"The new data",
        modification:"Data modification",
        review:"Data review"
       }
    },
    dm:{
      ZH:{
        code:'模型代码',
        name:'模型名称',
        categoryName:'所属分类',
        modelType:'模型类型',
        descs:'模型描述',
        refStandard:'参考标准',
        tableName:'数据表',
        file:'附件',
        attribute:'属性操作',
        code1:'分类代码',
        name1:'分类名称',
        orderNum:'排序',
        creatorName:'创建人',
        createTime:'创建时间',
        pageVersion:'页面版本',
        listSet:'列表页面操作',
        pageSet:'详情页面操作',
        sysName:'接口名称',
        serviceName:'服务提供方',
        remark:'接口说明',
        tbcl:'同步策略设定',
        glmxzd:'关联模型/字段',
        compName:'公司名称',
        regNo:'注册号',
        belongOrg:'登记机关',
        operName:'法人',
        clrq:'成立日期',
        dxrq:'吊销日期',
        gxrq:'更新日期',
        sf:'省份',
        creditCode:'信用代码',
        regCapi:'注册资本',
        econKing:'类型',
        address:'地址',
        keyNo:'根据此字段获取详细信息',
        scope:'营业范围',
        termStart:'营业期限始',
        termEnd:'营业期限至',
        checkDate:'发照日期',
        orgNo:'组织机构代码',
        isOnstock:'是否IPO上市',
        stockNumber:'证券号',
        stockType:'证券类型',
        logoUrl:'Logo地址',
        entType:'企业类型',
        recCap:'实缴资本',
        insureCount:'参保人（人）',
        englishName:'英文名',
        personScope:'人员规模',
        tel:'联系电话',
        bank:'开户行',
        bankAccount:'开户行帐号',
        interfaceManage:'接口管理',
        interfaceURL:'接口URL',
        seeVersion:'查看版本',
        dw:'删除警告!',
         1:'数据表创建成功!',
         2:'确定要进行数据表操作?',
         3:'数据表创建信息',
      },
      EN:{
        code:'model code',
        name:'model name',
        categoryName:'category',
        modelType:'model type',
        descs:'model describes',
        refStandard:'reference standard',
        tableName:'data tables',
        file:'files',
        attribute:'Attribute operation',
        code1:'class code',
        name1:'class name',
        orderNum:'the sorting',
        creatorName:'creator',
        createTime:'creation time',
        pageVersion:'page version',
        listSet:'list set',
        pageSet:'page set',
        remark:'remark',
        sysName:'system name',
        serviceName:'service name',
        tbcl:'synchronization strategy',
        glmxzd:'model/field',
        compName:'company name',
        regNo:'reg number',
        belongOrg:'belong org',
        operName:'oper name',
        clrq:'start date',
        dxrq:'end date',
        gxrq:'updated date',
        sf:'province',
        creditCode:'credit code',
        regCapi:'regist capi',
        econKing:'econ king',
        address:'address',
        keyNo:'key no',
        scope:'scope',
        termStart:'term start',
        termEnd:'term end',
        checkDate:'check date',
        orgNo:'org no',
        isOnstock:'isOnstock',
        stockNumber:'stock number',
        stockType:'stock type',
        logoUrl:'logo url',
        entType:'ent type',
        recCap:'rec cap',
        insureCount:'insure count',
        englishName:'english name',
        personScope:'person scope',
        tel:'telphone',
        bank:'bank',
        bankAccount:'bank account',
        seeVersion:'version view',
        interfaceManage:'Interface management',
        interfaceURL:'interfaceURL',
        dw:'Delete warning!',
        1:'Data table created successfully!',
        2:'Are you sure you want to do table manipulation?',
        3:'Data table creation information',
      }
    },
    matter:{
      ZH:{
        status:"状态",
        state:'申请状态',
        apprState:'审核状态',
        opertype:'操作类型',
        xz:"量的名称",
        jldw:'计量单位',
        fh:'单位符号',
        zq:'是否准确',
        xs:'系数',
        fhname:'符号',
        fhtype:'符号类型',
        lbbm:'类别编码',
        lbmc:'类别名称',
        normt2:'层级',
        wzcpbz:'物料用途',
        bzsm:'编制说明',
        bm:'别名',
        mbname:'模板名称',
        mbremark:'模板说明',
        oper:'操作',
        subtemplate:'子模板',
        wlbm:'物料代码',
        qc:"长描述",
        jc:"短描述",
        wlmc:"全描述",
        thumbnail:'缩略图',
        wym:'申请唯一码',
        remark:'备注',
        errtext:'错误信息',
        see:"相似度",
        creatorName:'创建人',
        createTime:'创建时间',
        submitUname:'提交人',
        submitTime:'提交时间',
        approveDate:"审核时间",
        name:"名称",
        descs:"描述",
        fatherCode:"父级模块Code",
        fatherName:"父级模块名称",
        childCode:"子级视图模块Code",
        childName:"子级视图模块名称",
        childModelId:"子级视图模型Id",
        enabledCreate:"是否启用创建或拓展",
        enabledNecessary:"是否必填",
        dataRelationship:"数据关系",
        applicationReason:"申请原因",
        fieldName:"字段名称",
        fieldType:"字段类型",
        wh:"数据维护",
        xh:"显示顺序",
        sfbdxs:"是否在物料表单显示",
        sflbxs:"是否在物料列表显示",
        updateName:"变更人",
        updateTime:"变更时间",
        securityLevelName:"密级",
        sm: "说明",
        archiveTime:"归档完成时间",
        filingTime:"归档申请时间",
        filingApplicant:"归档申请人"

      },
      EN:{
        status:"status",
        state:'apply status',
        apprState:'approve status',
        opertype:'oper type',
        xz:"quantity name",
        jldw:'metering',
        fh:'unit symbol',
        zq:'accurate',
        xs:'modulus',
        fhname:'symbol',
        fhtype:'symbol type',
        lbbm:'category code',
        lbmc:'category name',
        normt2:'hierarchy',
        wzcpbz:'material used',
        bzsm:'manual',
        bm:'alias',
        mbname:'template name',
        mbremark:'template desc',
        oper:'operate',
        subtemplate:'subtemplate',
        wlbm:'material code',
        qc:"long desc",
        jc:"short desc",
        wlmc:"describe all",
        thumbnail:'thumbnail',
        wym:"unique code",
        remark:"remark",
        errtext:'errtext',
        see:'similarity',
        creatorName:'creator',
        createTime:'create time',
        submitUname:'submit name',
        submitTime:'submit time',
        approveDate:"approve time",
        name:"name",
        descs:"descs",
        fatherCode:"father Code",
        fatherName:"father Name",
        childCode:"child Code",
        childName:"child Name",
        childModelId:"child ModelId",
        enabledCreate:"enabled Create",
        enabledNecessary:"enabled Necessary",
        dataRelationship:"data Relationship",
        applicationReason:"application Reason",
        fieldName:"field name",
        fieldType:"field type",
        wh:"data maintain",
        xh:"display order",
        sfbdxs:"whether display in the form",
        sflbxs:"Whether display in the list",
        updateName:"update name",
        updateTime:"update time",
        securityLevelName:"security level",
        sm: 'explain',
        archiveTime:"Filing completion time",
        filingTime:"Filing Time",
        filingApplicant:"Filing Applicant"
      }
    },
    system:{
      ZH:{
        ywlxdm:'业务类型代码',
        ywlxmc: '业务类型名称',
        lbbq:'列表标签',
        xqbq: '详情标签',
        sfglfs:'是否设置过滤方式',
        setUp: '设置',
        glfs:'过滤方式',
        glzd: '过滤字段',
        name: '姓名',
        roleName: '角色名称',
        appName: '应用名称',
        userGroupName: '用户组名称',
        account: '用户账号',
        email: '邮箱',
        telephone: '手机',
        phone:'电话',
        orgName:'组织名称',
        orgCode: '组织机构代码',
        orgFullName: '组织机构全称',
        orgShortName: '组织机构简称',
        typeName: '单位类型',
        pName: '上级组织机构',
        virtualUnit:'是否虚拟单位',
        supPer:'超级权限',
        modelPer:'模型权限操作',
        rowPer:'行级权限操作',
        columnPer:'列级权限操作',
        creatorName: '创建人',
        createTime: '创建时间',
        descs:"用户组描述",
        unitPer:"单位权限",
        interfaceType:"服务提供方",
        interfaceDescription:"服务提供方说明",
        accessSettings:"访问设置"
      },
      EN:{
        ywlxdm: 'bussiness code',
        ywlxmc: 'bussiness name',
        lbbq:'list tags',
        xqbq: 'details tags',
        sfglfs:'set filter way',
        setUp: 'setUp',
        glfs:'filter way',
        glzd: 'filter field',
        name: 'name',
        roleName: 'role name',
        appName: 'app name',
        userGroupName: 'user group name',
        account: 'account',
        email: 'email',
        telephone: 'telephone',
        phone:'phone',
        orgName:'organization name',
        orgCode: 'org code',
        orgFullName: 'org full name',
        orgShortName: 'org short name',
        typeName: 'type name',
        pName: 'pname',
        virtualUnit:'virtual unit',
        supPer:'sup permission',
        modelPer:'model permission',
        rowPer:'row permission',
        columnPer:'column permission',
        creatorName: 'creator name',
        createTime: 'create time',
        descs:"User group description",
        unitPer:"Unit permissions",
        interfaceType:"External interface type",
        interfaceDescription:"External interface description",
        accessSettings:"Access Settings"
      }
    },
    viewLay: {
      ZH:{
        treeNav: '树导航字段不能为空',
      },
      EN:{
        treeNav: 'The tree navigation field cannot be empty',
      }
    },
    wf:{
      ZH:{
        busiName:'业务类型名称',
        procdefName:'流程名称',
        procdefVersion:'流程版本',
        operType:'操作类型',
        orgName:'组织机构名称',
        condText: '条件'
      },
      EN:{
        busiName:'busi name',
        procdefName:'procdef name',
        procdefVersion:'procdef version',
        operType:'oper type',
        orgName:'org name',
        condText: 'cond text'
      }
    }
  } 
  getMessage(obj,args):string{
    let langSv=this.token.getSysLang();
    let msg=this.messages[obj][langSv];
    return msg[args];
  }
}
