const apiConfig = {
    "isCustomize":"188",
    "customizeDatas":{
        'matter':'http://10.100.211.53:8081',
        'sysManage':'http://mdm.cncico.com/api/basic',
        'mdmManage':'http://mdm.cncico.com/api/mdm',
        'wf':'http://mdm.cncico.com/api/workflow',
        'file':'http://mdm.cncico.com/api/upload',
        'metadata':'http://10.100.211.53:8087',
        'qualityassurance':'http://10.100.211.53:8086',
        'data-cleaning':'',
        'ext-data': 'http://10.100.211.53:8092',
        'data-report':'http://mdm.cncico.com/api/datareport',//浙江投生产npm run build-prod
    },
    // "defaultDatas":{
    //     //浙江投测试
    //     'matter':'http://127.0.0.1:8081',
    //     'sysManage':'http://127.0.0.1:8088',
    //     'mdmManage':'http://127.0.0.1:8082',
    //     'wf':'http://127.0.0.1:8083',
    //     'file':'http://127.0.0.1:8085',
    //     'metadata':'http://127.0.0.1:8087',
    //     'qualityassurance':'http://127.0.0.1:8086',
    //     'data-cleaning':'',
    //     'ext-data': 'http://127.0.0.1:8092',
    //     'data-report': 'http://127.0.0.1:8091'
    // }
    // "defaultDatas":{
    //     //浙江投测试
    //     'matter':'http://59.110.173.99:8081',
    //     'sysManage':'http://59.110.173.99:8088',
    //     'mdmManage':'http://59.110.173.99:8082',
    //     'wf':'http://59.110.173.99:8083',
    //     'file':'http://59.110.173.99:8085',
    //     'metadata':'http://59.110.173.99:8087',
    //     'qualityassurance':'http://59.110.173.99:8086',
    //     'data-cleaning':'',
    //     'ext-data': 'http://59.110.173.99:8092',
    //     'data-report': 'http://59.110.173.99:8091'
    // }
    "defaultDatas":{
        //浙江投测试
        'matter':'http://123.249.39.234:8081',
        'sysManage':'http://123.249.39.234:8088',
        'mdmManage':'http://123.249.39.234:8082',
        'wf':'http://123.249.39.234:8083',
        'file':'http://123.249.39.234:8085',
        'metadata':'http://123.249.39.234:8087',
        'qualityassurance':'http://123.249.39.234:8086',
        'data-cleaning':'',
        'ext-data': 'http://123.249.39.234:8092',
        'data-report': 'http://123.249.39.234:8091'
    }
}
//针对晨光定制化开发驳回意见分类
const apiSystemName="CG";//MDM主数据4.0  CG晨光项目
const extData=true;
function getApi(){
    if(apiConfig.isCustomize=='188'){
        return apiConfig.defaultDatas
    }else if(apiConfig.isCustomize=='224'){
        return apiConfig.customizeDatas
    }
}
// 针对晨光定制化开发驳回意见分类
function getSystem(){
    return apiSystemName;
}
function getExt(){
    return extData;
}

export {getApi,getSystem,getExt}

