import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { NzFormatEmitEvent, NzTreeNode,NzTreeSelectModule  } from 'ng-zorro-antd';
import { AutoServiceService } from "../../../commons/services/auto-service.service";
import { WfApiService } from './../../../wf/services/wf-api.service';

@Component({
  selector: 'app-business-tree',
  templateUrl: './business-tree.component.html',
  styleUrls: ['./business-tree.component.css']
})
export class BusinessTreeComponent implements OnInit {
  nodes = [];
  searchValue;
  matterData = [];
  @ViewChild('treeCom') treeCom;
  @Output() handleTreeEvent = new EventEmitter();
  @Output() handleIsLoadState = new EventEmitter();
  @Input() isLoad:any;
  @Input() queryArgs:any;
  /**
   * isLoad：是否重新加载树的数据
   * matyerData是树请求到的数据给表格使用的
   * queryArgs类型是对象，属性有url, status,
   * url:是请求地址。
   * handleIsLoadState:返回树的是否重新加载的状态，主要是对全局刷新
   */
    urls='';
    pid='';
    isSyn='';
    isLoads=false;
    isAllTreeNodes=false;
    isCollapseds=false;
  constructor(private api:WfApiService,private autoSerive:AutoServiceService) { }

  ngOnInit() {
    this.urls=this.queryArgs.url;
    this.pid=this.queryArgs.pid;
    this.isSyn=this.queryArgs.isSyn;
    this.isLoads=this.isLoad;
  }
  ngOnChanges(){
    this.urls=this.queryArgs.url;
    this.pid=this.queryArgs.pid;
    this.isSyn=this.queryArgs.isSyn;
    this.isLoads=this.isLoad;
    this.init();
    if(this.isLoads){
      this.nodes=[];
      this.init();
      this.isLoads=false;
      this.handleIsLoadState.emit(this.isLoads);
    }
  }
  init(){
    let urls=this.urls;
    let params:any = {};
    // params['pid']=this.pid;
    // params['isSyn']=this.isSyn;
    if(this.queryArgs['busType']){
      params['busType']="R1";
    }
    this.api.treePost(urls,params).subscribe(data => {
      if(data.code==200){
        //this.nodes=this.autoSerive.buildTree(data.data);
        this.nodes=this.filterDatas(data.data,'');
      }
    })
  }
  filterDatas(args,key){
    for(let i=0,lens=args.length;i<lens;i++){
      args[i]['title']=args[i].busName;  
      // args[i].isParent==true?(args[i]['isLeaf']=false):(args[i]['isLeaf']=true);
      // isL==true?(args[i]['isLeaf']=true):(args[i]['isLeaf']=false);
      if(args[i].busTable==null){
        args[i]['isLeaf']=false
        args[i]['disabled']=true;
      }else{
        args[i]['isLeaf']=true;
        args[i]['disabled']=false;
      }
      if(key!==''){
        args[i]['key']=key+"-"+i+'';
      }else{
        args[i]['key']=i+'';
      }
    }
    return args;
  }
  queryNode(args,nodes,key){
    let urls=this.queryArgs.url;
    let params:any = {};
    if(this.queryArgs['busType']){
      params['busType']="R1";
    }
    params['pid']=args;
    params['isSyn']=this.isSyn;
    this.api.treePost(urls,params).subscribe(data => {
      let datas=this.filterDatas(data.data,key);
      nodes.addChildren(datas);
    })
  }
  nzPass(event: NzFormatEmitEvent){
     /***
     * 这部分不管树是否是子节点还是父节点一律传数据，
     * 是否选中根据isSelected判定，true是选中，反之亦然
     * *** */
    let node={
      state:event.node.isSelected,
      datas:event.node.origin
    }
    //let node=event.node.origin;
    this.handleTreeEvent.emit(node);
    //this.handleAppId.emit(this.appId);
}
nzCheck(event: NzFormatEmitEvent){
  let node=event.node.origin;
  this.handleTreeEvent.emit(node);
}
nzEvent(event: NzFormatEmitEvent): void {
  if (event.eventName === 'expand') {
    if(event.node.children.length == 0){
      if(event.node.origin.busId&&event.node.isExpanded&&event.node.isLeaf==false){
        this.queryNode(event.node.origin.busId,event.node,event.node.origin.key);
      }
    }
  }
}
searchTree(){
  if(this.searchValue!=""&&this.searchValue!=undefined){
    //let url=this.urls+"?name="+this.searchValue;
    let obj={busName:this.searchValue};
    if(this.queryArgs['busType']){
      obj['busType']="R1";
    }
    this.api. treePost(this.urls,obj).subscribe(data => {
      if(data.code==200){
        // for (let i = 0; i < data.data.length; i++) {
        //   if (data.data[i].pid == '' || data.data[i].pid == null) {
        //     data.data[i].pid = '0';
        //   }
        //   data.data[i].isParent = true;
        // }
        let datas = this.buildTree(this.filterDatas(data.data,false));
        this.nodes=datas;
      }
    })
  }else{
    this.init();
  }
}
  buildTree(list) {
    let temp = {};
    let tree = [];
    for (let i in list) {
      temp[list[i].id] = list[i];
    }
    for (let i in temp) {
      if (temp[i].pid) {
        if (temp[i].pid != '0' && temp[temp[i].pid]) {
            if (!temp[temp[i].pid]['children']) {
              temp[temp[i].pid]['children'] = [];
            }
            temp[temp[i].pid]['children'].push(temp[i]);
        }else {
          let obj = temp[i];
          obj['expanded'] = true
          tree.push(obj);
        }
      }

    }
    return tree;
  }
}