import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDirective } from './directive/drag.directive';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { MaterielServerService } from './materiel-server.service';
import { LeftMenuService} from './services/left-menu.service';
import { NgxEchartsModule } from 'ngx-echarts';
import { Routes, RouterModule } from '@angular/router';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { LayoutComponent } from './layout/layout.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { TablesComponent } from './tables/tables.component';
import { TreeComponent } from './tree/tree.component';
import { MaterielTablesComponent } from './materiel-tables/materiel-tables.component';
import { MaterTablesComponent } from './components/mater-tables/mater-tables.component';
import { ModelTableComponent } from './components/model-table/model-table.component';
import { MaterTablesCheckComponent } from './components/mater-tables-check/mater-tables-check.component';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { IndexComponent } from './index/index.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient ,HTTP_INTERCEPTORS} from '@angular/common/http';
import { MdmTablesComponent } from './mdm-tables/mdm-tables.component';
import {PerfectScrollbarDirective} from './directive/perfect-scrollbar.directive';
import { ClickOutSideDirective } from './directive/click-out-side.directive';
import { UploadComponent } from './components/upload/upload.component';
import { ScrollbarDirective } from './directive/scrollbar.directive';
import { IndexGeneralComponent } from './components/index-general/index-general.component';
import { IndexYuanComponent } from './index-yuan/index-yuan.component';
import { AdvancedSearchComponent } from './components/advanced-search/advanced-search.component';
import { ApprovalLogComponent } from './components/approval-log/approval-log.component';
import { UploadTableComponent } from './components/upload-table/upload-table.component';
import { WfAgentModelComponent } from './components/wf-agent-model/wf-agent-model.component';
import { WfAgentShowTableComponent } from './components/wf-agent-show-table/wf-agent-show-table.component';
import { BusinessTreeComponent } from './components/business-tree/business-tree.component';
import { WfAgentClassTypeComponent } from './components/wf-agent-class-type/wf-agent-class-type.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { WfAgentManageComponent } from './components/wf-agent-manage/wf-agent-manage.component';
import { UpdateUserinfoComponent } from './components/update-userinfo/update-userinfo.component';
import { CommonOrgTypeComponent } from './components/common-org-type/common-org-type.component';
import { CommonOrgTreeComponent } from './components/common-org-tree/common-org-tree.component';
import { HelpManageComponent } from './components/help-manage/help-manage.component';
import { CommonDataLifecycleComponent } from './components/common-data-lifecycle/common-data-lifecycle.component';
import { ExpEditorComponent } from './components/exp-editor/exp-editor.component';
import { HelpDetailsComponent } from './components/help-details/help-details.component';
import { CommonIndexTodolistsComponent } from './components/common-index-todolists/common-index-todolists.component';
import { CommonIndexNoticesComponent } from './components/common-index-notices/common-index-notices.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ModelSinpleTableComponent } from './components/model-sinple-table/model-sinple-table.component';
import { CommonIndexSystemstandardComponent } from './components/common-index-systemstandard/common-index-systemstandard.component';
import { IndexGeneralDocumentDetailComponent } from './components/index-general-document-detail/index-general-document-detail.component';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { LengthPipe } from './length.pipe';
import { TdSqComponent } from './components/td-sq/td-sq.component';
export function createTranslateHttpLoader(http:HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    ClickOutSideDirective,
    DragDirective,PerfectScrollbarDirective,
    LayoutComponent,
    MenuItemComponent, 
    TablesComponent,  TreeComponent, 
    MaterielTablesComponent, 
    MaterTablesComponent, ModelTableComponent, 
    MaterTablesCheckComponent, CheckboxListComponent, 
    IndexComponent, 
    MdmTablesComponent, 
    ClickOutSideDirective, 
    UploadComponent, 
    ScrollbarDirective,  
    IndexGeneralComponent, 
    IndexYuanComponent, 
    AdvancedSearchComponent, 
    ApprovalLogComponent, 
    UploadTableComponent,
    WfAgentModelComponent, 
    WfAgentShowTableComponent, 
    BusinessTreeComponent, 
    WfAgentClassTypeComponent, 
    UpdatePasswordComponent, 
    WfAgentManageComponent,
    UpdateUserinfoComponent,
    CommonOrgTypeComponent,
    CommonOrgTreeComponent,
    HelpManageComponent, 
    ExpEditorComponent,
    CommonDataLifecycleComponent,
    HelpDetailsComponent,
    CommonIndexTodolistsComponent,
    CommonIndexNoticesComponent,
    ForgotPasswordComponent,
    ModelSinpleTableComponent,
    CommonIndexSystemstandardComponent,
    IndexGeneralDocumentDetailComponent,
    LengthPipe,
    TdSqComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgZorroAntdModule,
    NgxEchartsModule,
    TranslateModule.forChild({
      loader:{
      provide:TranslateLoader,
      useFactory:createTranslateHttpLoader,
      deps:[HttpClient]
      }
  })
  ],
  providers: [MaterielServerService,LeftMenuService,{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }],
  exports:[
    LengthPipe, TdSqComponent,
    ClickOutSideDirective,DragDirective,LayoutComponent,PerfectScrollbarDirective, MenuItemComponent, TablesComponent,  TreeComponent, MaterielTablesComponent, MaterTablesComponent, ModelTableComponent, MaterTablesCheckComponent, CheckboxListComponent,IndexComponent, MdmTablesComponent,UploadComponent, UploadTableComponent, ScrollbarDirective, AdvancedSearchComponent,ApprovalLogComponent,WfAgentModelComponent,UpdatePasswordComponent,CommonDataLifecycleComponent, ExpEditorComponent,CommonIndexTodolistsComponent,CommonIndexNoticesComponent,ForgotPasswordComponent,ModelSinpleTableComponent,CommonIndexSystemstandardComponent]
})
export class CommonsModule { }
