export class subUnit{
    cType: string;
    tabType: string;
    attrList:object;
    datas:object[];
    constructor(cType:string,tabType:string,attrList:{},datas:object[]) { 
        this.cType=cType;
        this.tabType=tabType;
        this.attrList=attrList;
        this.datas=datas;
    }
}
/****
 * 布局组件模型数据结构
 * cType：组件类型
 * tabType：组件属性标签类型
 * attrList：组件属性用于设置组件属性
 * datas：组件展示数据
 * ** **/