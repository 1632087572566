import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { LoginRoutingModule } from './login-routing/login-routing.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { LoginComponent } from './login/login.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {CommonsModule} from '../commons/commons.module';
export function createTranslateHttpLoader(http:HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    CommonsModule,
    TranslateModule.forChild({
      loader:{
      provide:TranslateLoader,
      useFactory:createTranslateHttpLoader,
      deps:[HttpClient]
      }
    })
  ],
  exports:[LoginComponent]
})
export class LoginModule { }
