import { Component, OnInit,Input, EventEmitter,Output,TemplateRef, ViewChild, ElementRef,HostListener} from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
@Component({
  selector: 'app-model-table',
  templateUrl: './model-table.component.html',
  styleUrls: ['./model-table.component.css']
})
export class ModelTableComponent implements OnInit {
  @Input() isShown:any;
  @Input() tbDatas:any;
  @Output() handleBackEventTb = new EventEmitter();
  @ViewChild('drags') drags: ElementRef;
  /***
   * 功能：该模块功能是设置表格表头展示影藏
   * tbDatas:是表格表头信息，
   * isShown：是控制是否显示隐藏，
   * handleBackEventTb：方法是返回表头数据以展示那些操作
   *  **/
  isDown = false;
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom='';
  allChecked = false;
  indeterminate = false;
  isVisible = false;
  displayData=[];
  tableBox=[];
  chonseIndex=-1;
  constructor(private message: NzMessageService) { }
  ngOnChanges(){
    this.isVisible=this.isShown;
    this.allChecked=true;
  }
  ngOnInit() {
    this.tableBox=this.arrDeepCopy(this.tbDatas);
    this.displayData=[];
    this.changeTem()
    this.displayData=this.tableBox;
  }
  arrDeepCopy(source){
    let sourceCopy = [];
    for (var item in source){
      source[item]['isMove']=false;
      sourceCopy.push(source[item]);
    }
    return sourceCopy;
 }
  // 点击事件
  @HostListener('document:mousedown', ['$event']) onMousedown(event) {
    this.isDown = true;
    this.disX = event.clientX;
    this.disY = event.clientY;
  }

  // 监听document移动事件事件
  @HostListener('document:mousemove', ['$event']) onMousemove(event) {
    // 判断该元素是否被点击了。
    if (this.isDown) {
      this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
      this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
    }
  }

  // 监听document离开事件
  @HostListener('document:mouseup', ['$event']) onMouseup(event) {
    // 只用当元素移动过了，离开函数体才会触发。
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
      this.isDown = false;
    }
  }
 changeTem(){
  this.activeDom=this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
  this.activeDom['style'].position = 'relative';
 }
  currentPageDataChange($event: Array<{checked: boolean; disabled: boolean; }>): void {
    this.displayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    let lens=this.displayData.length;
    let mark=true;
    let add=0,indexs=0;
    for(let i=0;i<lens;i++){
      if(!this.displayData[i].checked){
        add++;
      }else{
        indexs=i;
        this.displayData[indexs].disabled=false;
      }
    }
    if(add==(lens-1)){
      this.displayData[indexs].disabled=true;
      mark=false;
    }
    if(mark){
      const allChecked = this.displayData.filter(value => !value.disabled).every(value => value.checked === true);
    const allUnChecked = this.displayData.filter(value => !value.disabled).every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    }
    
  }

  checkAll(value: boolean): void {
    this.displayData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }
  showModal(): void {
    this.isVisible = true;
  }
  handleOk(): void {
    this.isVisible = false;
    let passDatas=this.filterDatas(this.displayData);
    let obj={
      mark:this.isVisible,
      datas:passDatas,
    }
    this.handleBackEventTb.emit(obj);
  }
  filterDatas(args){
    let datas=args;
    if (datas.length!=0){
      for(let i=0,lens=datas.length;i<lens;i++){
        delete datas[i].isMove;
      }
    }
    return datas;
  }
  handleCancel(): void {
    this.isVisible = false;
    let passDatas=this.filterDatas(this.displayData);
    let obj={
      mark:this.isVisible,
      datas: passDatas,
    }
    this.handleBackEventTb.emit(obj);
  }
  chaoseTr(args){
    for(let i=0,lens=this.displayData.length;i<lens;i++){
      if(i==args){
        this.chonseIndex=i;
        this.displayData[i].isMove=true;
        // this.displayData[i].checked=!this.displayData[i].checked;
      }else{
        this.displayData[i].isMove=false;
      }
    }
  }
  /***移动功能* */
  toForemost(){
    if(this.chonseIndex==-1){
      this.message.info('请选择操作项');
      return
    }
    if(this.chonseIndex==0){
      this.message.info('已经是第一项');
      return
    }
    let dataIndex =this.displayData[this.chonseIndex];
    dataIndex.isMove=false;
    this.displayData=this.displayData.filter((value,index) => index!=this.chonseIndex);
    this.displayData.unshift(dataIndex);
    this.chonseIndex=-1;

  }
  toUp(){
    if(this.chonseIndex==-1){
      this.message.info('请选择操作项');
      return
    }
    if(this.chonseIndex==0){
      this.message.info('已经是第一项');
      return
    }
    let ary=this.displayData[this.chonseIndex];
		let befor=this.displayData[this.chonseIndex-1];
		this.displayData[this.chonseIndex]=befor;
    this.displayData[this.chonseIndex-1]=ary;
    let arys=this.displayData;
    this.displayData=[];
    this.displayData = [ ...arys];
    this.chonseIndex--;
  }
  toDown(){
    let lens=this.displayData.length-1;
    if(this.chonseIndex==-1){
      this.message.info('请选择操作项');
      return
    }
    if(this.chonseIndex==lens){
      this.message.info('已经是最后一项');
      return
    }
    let ary=this.displayData[this.chonseIndex];
		let befor=this.displayData[this.chonseIndex+1];
		this.displayData[this.chonseIndex]=befor;
    this.displayData[this.chonseIndex+1]=ary;
    let arys=this.displayData;
    this.displayData=[];
    this.displayData = [ ...arys];
    this.chonseIndex++;
  }
  toLast(){
    let lens=this.displayData.length-1;
    if(this.chonseIndex==-1){
      this.message.info('请选择操作项');
      return
    }
    if(this.chonseIndex==lens){
      this.message.info('已经是最后一项');
      return
    }
    let dataIndex =this.displayData[this.chonseIndex];
    dataIndex.isMove=false;
    this.displayData=this.displayData.filter((value,index) => index!=this.chonseIndex);
    this.displayData.push(dataIndex);
    this.chonseIndex=-1;
  }
}
