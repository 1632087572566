import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenServiceService } from '../../commons/services/token-service.service';
import { AutoServiceService } from '../../commons/services/auto-service.service';
import { NzNotificationService } from 'ng-zorro-antd';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { AutoInternationService } from 'src/app/commons/services/auto-internation.service';
import * as JsEncryptModule from 'jsencrypt';
export class User {
  public remember: boolean;
  public userName: any;
  public password: any;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;
  user: User;
  selectValue = "ZH";
  selectTem = ""
  sysLangList = [
    {
      id: 'ZH', name: '中文'
    },
    {
      id: 'EN', name: 'English'
    }
  ]
  isPassword = false;
  isForgotPwd = false;
  updatePassword = {
    showTit: '修改密码',
    saveUrl: '/sec/user/modifyPassword',
    verifyUrl: '/sec/user/passwordVerify',
  }
  systemList=[];
  constructor(private fb: FormBuilder,
    public router: Router,
    public token: TokenServiceService,
    public authService: AutoServiceService,
    private notice: NzNotificationService,
    private nation: AutoInternationService
  ) {
    this.user = { userName: null, remember: true, password: null };
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      sysLang: [null, [Validators.required]],
      sysTem: [null, [Validators.required]],
      remember: [true]
    });
  }

  ngOnInit() {
    if (window.location.href.indexOf('#') != -1) {
      let pwd = window.location.href.split('=')[1];
      if (pwd) {
        this.isForgotPwd = true;
      }
    }
    this.getSys();
  }
  changeLang(data) { }
  login() {
    let mark = true;
    this.submitForm();
    if (this.validateForm.status === 'VALID') {
      delete this.validateForm.value.remember;
      for (const i in this.validateForm.value) {
        if (this.validateForm.value[i] == null) {
          mark = false;
          break;
        }
        this.user[i] = this.validateForm.value[i];

      };
      if (mark) {
        let url = '/login';
        let encrypt = new JsEncryptModule.JSEncrypt();
  	    encrypt.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCkJMCohZ/bl+5krrYviddcN3zgHASZu6AB/8Zb5DL/uL87gnVIS1JHGavALzsNyrhmYE6HWDaaSaOdSvGsUMJcztBL1wALz2LEBI9m9Vp069NcdZVDTL83LBbZUlv0D12UJ1DJLIDsOcBOq0WbRByvS70KfWrs844djlGw9zXNxQIDAQAB'); // 公钥
        let passData = {
          account: encrypt.encrypt(this.validateForm.value.userName.trim()),
          password: encrypt.encrypt(this.validateForm.value.password.trim()),
        }
        this.token.setLang(this.validateForm.value['sysLang']);
        localStorage.setItem('sysId', JSON.stringify(this.selectTem));
        this.authService.login(url, passData).subscribe(res => {
          let tipsObject = { status: false, value: '' }
          localStorage.setItem('isTips', JSON.stringify(tipsObject));
          if (res.code == 1002) {
            this.notice.warning(this.nation.getMessage('general', 'msg'), res.msg);
            this.isPassword = true;
            this.token.setToken(res.data);
          } else if ((res.code == 200) || (res.code == 1001)) {
            if (res.code == 1001) {
              tipsObject.status = true;
              tipsObject.value = res.msg;
              localStorage.setItem('isTips', JSON.stringify(tipsObject));
            }
            let code = res;
            let token = code.data;
            passData['userName']=res.data.userName;
            passData['account']=res.data.account;
            this.token.setUserInfo(passData);
            this.token.setToken(token);
            this.getLeftMenu(this.selectTem);
          } else {
            this.notice.warning(this.nation.getMessage('general', 'msg'), res.msg);
          }
        }, err => {
          this.notice.warning(this.nation.getMessage('general', 'msg'), err.error.message)
        });
      }
    }

  }
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }
  getSys() {
    let url = "/appAPI/queryAppList?pageSize=20&&pageNum=1";
    let obj = {
      lanCode: 'ZH'
    }
    this.authService.login(url, obj).subscribe(data => {
        this.systemList=data;
        this.selectTem=data[0]['id'];
    })
  }
  changeSystem(id){
    this.selectTem=id;
  }
  getLeftMenu(args) {
    let lang = this.token.getSysLang();
    let passUrl = '/sec/menu/queryMenuTreeAllByLoginUser?appId=' + args + '&lanCode=' + lang;
    this.authService.getUserMenuList(passUrl, {}).subscribe(res => {
      if (res.code == 200) {
        if (res.data.length == 0) {
          this.notice.warning(this.nation.getMessage('general', 'msg'), this.nation.getMessage('general', '10'));
          this.token.removeToken();
         // this.validateForm.reset();
          // this.validateForm.patchValue({
          //   sysLang: this.token.getSysLang()
          // });
          this.selectValue = this.token.getSysLang();
        } else {
          if(this.selectTem==this.systemList[0]['id']){
            let parms = [];
            parms.push('main/index');
            this.router.navigate(parms);
          }else{
            let parms = [];
            parms.push('main/indexYuan');
            this.router.navigate(parms);
          }
          
        }
      }
    })
  }
  backUpdate(args, type?) {
    if (type == 'forgot') {
      this.isForgotPwd = args;
      this.router.navigateByUrl('/login');

    } else {
      this.isPassword = args;
    }
  }
  fotgotPwd() {
    this.isForgotPwd = true;
  }
}
