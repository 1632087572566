import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ButtonMenuService {
  showList=[];
  pageUrl="";
  tabInfo={};
  obj={}
  queryObj={}
  editObj={}
  auditObj={}
  requeryObj={}
  reeditObj={}
  reapplyObj={}
  paramsValue='1'
  constructor() { }
  getPageData(args){
    this.paramsValue=args;
  }
  returnPageData(){
    return this.paramsValue;
  }
  getData(args){
    this.showList=args.btnList;
  }
  returnData(){
    return this.showList
  }
  getPage(args){
    this.pageUrl=args
  }
  returnPage(){
    return this.pageUrl
  }
  getTab(args){
    this.tabInfo=args
  }
  returnTab(){
    return this.tabInfo
  }
  getQuery(data){
    this.queryObj=data  
}
returnQuery(){
  return this.queryObj;
}
removeQuery(){
  this.queryObj={};
}
getEdit(data){
  this.editObj=data
}
returnEdit(){
  return this.editObj;
}
removeEdit(){
  this.editObj={};
}
getModalInfo(data){
  this.obj=data
}
removeModal(){
  this.obj={}
}
returnModal(){
  return this.obj;
}

getAudit(data){
  this.auditObj=data  
}
returnAudit(){
  return this.auditObj;
}
removeAudit(){
  this.auditObj={};
}
getModalInfo1(data){
  this.reapplyObj=data
}
returnModal1(){
  return this.reapplyObj;
}
removeModal1(){
  this.reapplyObj={}
}
getreQuery(data){
  this.requeryObj=data
}
returnreQuery(){
  return this.requeryObj;
}
removereQuery(){
  this.requeryObj={}
}
getreEdit(data){
  this.reeditObj=data
}
returnreEdit(){
  return this.reeditObj;
}
removereEdit(){
  this.reeditObj={};
}
}
