import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient ,HttpHeaders } from '@angular/common/http';
import { ServiceApiService} from '../../commons/services/service-api.service';
import { TokenServiceService} from '../../commons/services/token-service.service';

@Injectable({
  providedIn: 'root'
})
export class DmApiService {

  constructor(private http: HttpClient,private svApi:ServiceApiService, private tokens:TokenServiceService,) { }
  baseUrl=this.svApi.urlApi['mdmManage'];
  baseUrl1=this.svApi.urlApi['file'];
  baseUrl2=this.svApi.urlApi['sysManage'];
  token={
    'x-access-current-token' :this.tokens.getToken(),
    'x-access-refresh-token' :this.tokens.getToken1(),
  };
  treePost(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.baseUrl + url;
    return this.http.post(url, data,{headers:tokens});
  }
  handlePost(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.baseUrl + url;
    return this.http.post(url, data,{headers:tokens});
  }
  handleGetLoad(url): Observable<any> {
    url = this.baseUrl1 + url;
    return this.http.get(url,{withCredentials: true,reportProgress: true,responseType:'blob'});
  }
  getFile(url, data): Observable<any> {
    url = this.baseUrl1 + url;
    let header = new HttpHeaders(
      {
        'x-access-current-token' :this.tokens.getToken(),
        'x-access-refresh-token' :this.tokens.getToken1(),
      }
    );
    return this.http.post(url, data,{headers:header});
  }
  handlePost1(url, data): Observable<any> {
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = this.baseUrl2 + url;
    return this.http.post(url, data,{headers:tokens});
  }
}
