import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NzFormatEmitEvent, NzNotificationService } from 'ng-zorro-antd';
import { HttpService } from '../../services/http.service';
import { AutoInternationService } from '../../services/auto-internation.service';
import { AutoServiceService } from '../../services/auto-service.service';

@Component({
	selector: 'app-common-org-tree',
	templateUrl: './common-org-tree.component.html',
	styleUrls: ['./common-org-tree.component.css']
})
export class CommonOrgTreeComponent implements OnInit {
	@Output() handleBackEventTree = new EventEmitter();
	@Output() handleIsLoadState = new EventEmitter();
	@Input() queryArgs: any;
	@Input() isLoad: boolean;
	@Input() orgType: any;
	isLoads = false;
	searchValue = '';
	urls = '';
	pid = '';
	nodes = [];
	defaultSelectedKeys = [];
	defaultExpandedKeys = ['0'];
	parentNode = [{
		id: '0',
		name: '组织机构目录',
		shortName: '组织机构目录',
		code: "0",
		creatorName: "管理员",
		descs: "组织机构目录",
		expanded: true,
		field1: "1",
		field2: "2",
		field3: "3",
		field4: "4",
		field5: "5",
		keyWord: null,
		orderNum: 0,
		pcode: "",
		pid: '-1',
		pname: "",
		state: "4",
		status: "1",
		typeName: "0",
		virtualUnit: "0"
	}]
	constructor(
		private http: HttpService,
		private nation: AutoInternationService,
		private notice: NzNotificationService,
		private auto: AutoServiceService
	) { }

	ngOnInit() {
		this.urls = this.queryArgs.url;
		this.pid = this.queryArgs.pid;
		this.isLoads = this.isLoad;
	}

	ngOnChanges() {
		this.urls = this.queryArgs.url;
		this.pid = this.queryArgs.pid;
		this.isLoads = this.isLoad;
		this.init();
		if (this.isLoads) {
			this.nodes = [];
			this.init();
			this.isLoads = false;
			this.handleIsLoadState.emit(this.isLoads);
		}
	}
	init() {
		let urls = this.urls;
		let params: any = {
			pid: this.pid
		};
		this.http.httpPost(urls, params).subscribe(data => {
			if (data.code == 200) {
				let datas = [];
				if (this.orgType != undefined) {
					datas = this.auto.buildTree(this.parentNode.concat(data.data));
				} else {
					datas = data.data;
				}
				this.nodes = this.filterDatas(datas);

			} else {
				this.notice.warning(this.nation.getMessage('general', 'msg'), data.msg);
			}
		}, err => {
			this.notice.warning(this.nation.getMessage('general', 'msg'), err.error.message);
		})
	}
	nzPass(event: NzFormatEmitEvent) {
		let node = {
			state: event.node.isSelected,
			datas: event.node.origin
		}
		this.handleBackEventTree.emit(node);
	}
	nzEvent(event: NzFormatEmitEvent) {
		if (event.eventName == 'expand') {
			if (event.node.children.length == 0) {
				if (event.node.origin.id && event.node.isExpanded && event.node.isLeaf == false) {
					this.queryNode(event.node.origin.id, event.node);
				}
			}
		}
	}
	queryNode(args, nodes) {
		let urls = this.urls;
		let params: any = {};
		params['pid'] = args;
		this.http.httpPost(urls, params).subscribe(data => {
			let datas = this.filterDatas(data.data);
			nodes.addChildren(datas);
		})
	}
	filterDatas(args) {
		for (let i = 0, il = args.length; i < il; i++) {
			args[i]['title'] = args[i].code + '_' + args[i].name;
			args[i]['key'] = args[i].id;
			if (args[i].children) {
				this.filterDatas(args[i].children);
			}
		}
		return args;
	}

	searchTree() {
		if (this.searchValue != "" && this.searchValue != undefined) {
			let obj = { keyWord: this.searchValue };
			this.http.httpPost(this.urls, obj).subscribe(data => {
				if (data.code == 200) {
					let datas = this.buildTree(this.filterDatas(data.data));
					this.nodes = datas;
				}
			})
		} else {
			this.init();
		}
	}
	buildTree(list) {
		let temp = {};
		let tree = [];
		for (let i in list) {
			temp[list[i].id] = list[i];
		}
		for (let i in temp) {
			if (temp[i].pid) {
				if (temp[i].pid != '0' && temp[temp[i].pid]) {
					if (!temp[temp[i].pid]['children']) {
						temp[temp[i].pid]['children'] = [];
					}
					temp[temp[i].pid]['children'].push(temp[i]);
				} else {
					let obj = temp[i];
					obj['expanded'] = true
					tree.push(obj);
				}
			}

		}
		return tree;
	}
}
