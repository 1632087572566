import { Component, OnInit,Input, EventEmitter,Output,ViewChild, ElementRef,HostListener} from '@angular/core';
import { NzNotificationService} from 'ng-zorro-antd';
import { AutoInternationService } from '../../../commons/services/auto-internation.service';
import {FormBuilder,FormControl,FormGroup,Validators} from '@angular/forms';
import { WfApiService } from './../../../wf/services/wf-api.service';

@Component({
  selector: 'app-wf-agent-model',
  templateUrl: './wf-agent-model.component.html',
  styleUrls: ['./wf-agent-model.component.css']
})
export class WfAgentModelComponent implements OnInit {
  @ViewChild('drags') drags: ElementRef;
  @Input() isShow:boolean;
  @Input() isEdit:boolean;
  @Input() showTit:any;
  // @Input() mdm:any;
  @Input() saveUrl:any;
  // @Input() saveParms:any;
  @Output() backEventState = new EventEmitter();
  @Output() backEventData = new EventEmitter();
  /*****
   * isShow:组件显示隐藏
   * isEdit：是不是编辑
   * showTit:显示标题
   * mdm:编辑时需要传的回显数据
   * backEventState：返回组件显示隐藏状态
   * backEventData：返回组件操作完的数据---保留
   * ***** */
  validateForm: FormGroup;
  isVisible=false;
  modalTitle='';
  saveUrls='';
  saveParm='';
  pid='0';
  agentId='';
  busiId='';
  busiTable='';
  showMdm='';
  isEdits=false;
  isDown = false;
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom='';
  showType=[
    {
      label:"新增",
      value:"1"
    },
    {
      label:"变更",
      value:"2"
    },
    {
      label:"解冻",
      value:"3"
    },
    {
      label:"冻结",
      value:"4"
    }
  ]
  isAgentShow=false;
  selectProxy={
    tit:'选择代理人',
    showWh:'900px',
    urls:'/sec/user/queryUserListByPage',
    isOk:true,
    thData:[],
    postBody:[{key:'status',value:'1'}],
  };
  isNewedit=false;
  showTreeTit='业务类型';
  constructor(
    private notice:NzNotificationService,
    private nation:AutoInternationService,
    private fb: FormBuilder,
   private api:WfApiService,
  ) {
    this.validateForm = this.fb.group({
      rangePicker:[ null, [Validators.required]],
      busiName  :[ null, [Validators.required]],
      operType  :[ null, [Validators.required]],
      agentName :[null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.changeTem();
  }
  ngOnChanges(){
    this.isVisible=this.isShow;
    this.modalTitle=this.showTit;
    this.saveUrls=this.saveUrl;
    this.isEdits=this.isEdit;
    if(this.saveParm['pid']!=''){
      this.pid=this.saveParm['pid'];
    }
    this.init();
  }
  init(){
    if(!this.isEdits){
      this.validateForm.patchValue({
        codeParent:this.saveParm['code'],
        nameParent:this.saveParm['name'],
        code:this.saveParm['code'],
      });
    }else{
      this.validateForm.patchValue({
        codeParent:this.showMdm['codeParent'],
        nameParent:this.showMdm['nameParent'],
        code:this.showMdm['code'],
        name:this.showMdm['name'],
        orderNum:this.showMdm['orderNum'],
      });
    }
  }
  resetForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }
  handleCancel(){
    this.isVisible=false;
    this.backEventState.emit(this.isVisible);
  }

  handleOk(){
    this.resetForm();
    let obj={};
    let startTime=this.validateForm.value.rangePicker[0];
    let endTime=this.validateForm.value.rangePicker[1];
    if(this.validateForm.status=="VALID"){
      let url=this.saveUrl;
        obj={
          "agentId":this.agentId,
          "agentName":this.validateForm.value.agentName,
          "busiId":this.busiId,
          "busiName":this.validateForm.value.busiName,
          "busiTable":this.busiTable,
          "startTime":startTime,
          "endTime":endTime,
          "operType":this.validateForm.value.operType.toString(),
        };
      this.api.handlePost(url,obj).subscribe(res => {
        if(res.code==200){
          if(this.isEdits){
            this.notice.success(this.nation.getMessage('general','msg'),this.nation.getMessage('general','modifys'));
          }else{
            this.notice.success(this.nation.getMessage('general','msg'),this.nation.getMessage('general','saves'));
          }
          this.isVisible=false;
          this.backEventState.emit(this.isVisible);
        }else{
          this.notice.warning(this.nation.getMessage('general','msg'),res.msg);
        }
      },
      err => {
        this.notice.warning(this.nation.getMessage('general','msg'),err.error.message)
      })
    }else{
      this.notice.warning(this.nation.getMessage('general','msg'),this.nation.getMessage('general','5'));
    }

  }
  // 点击事件
  @HostListener('document:mousedown', ['$event']) onMousedown(event) {
    if(event.path[0].localName!='input'){
       if(this.activeDom!=''){
       this.isDown = true;
       this.disX = event.clientX;
       this.disY = event.clientY;
       }
    }
 }

 // 监听document移动事件事件
 @HostListener('document:mousemove', ['$event']) onMousemove(event) {
   // 判断该元素是否被点击了。
   if (this.isDown) {
     this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
     this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
   }
 }
 // 监听document离开事件
 @HostListener('document:mouseup', ['$event']) onMouseup(event) {
   // 只用当元素移动过了，离开函数体才会触发。
   if (this.isDown) {
     this.totalOffsetX += event.clientX - this.disX;
     this.totalOffsetY += event.clientY - this.disY;
     this.isDown = false;
   }
 }
 changeTem(){
  this.activeDom=this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
  this.activeDom['style'].position = 'relative';
 }

 //选择代理人
 selectAgent(){
    this.isAgentShow=true;
    this.selectProxy.thData=[
    { key:"account",
      title:'用户账号',
      checked:true,
      disabled: false,
      value:"",
      type:"input"
    },
    { key:"name",
      title:'姓名',
      checked:true,
      disabled: false,
      value:"",
      type:"input"
    },
    { key:"email",
    title:'邮箱',
    checked:true,
    disabled: false,
    value:"",
    type:"input"
    },
    { key:'telephone',
      title:'手机',
      checked:true,
      disabled: false,
      value:"",
      type:"input"
    },
    { key:'phone',
      title:'电话',
      checked:true,
      disabled: false,
      value:"",
      type:"input"
    },
    { key:'orgName',
    title:'组织名称',
    checked:true,
    disabled: false,
    value:"",
    type:"input"
    },
   ]
 }
 getInitState(args){
    let name='';
    for(let value of args){
      if(this.agentId==''){
        this.agentId=value.id;
      }else{
        this.agentId=this.agentId+','+value.id;
      }
      if(name==''){
        name=value.name;
      }else{
        name=name+","+value.name;
      }
    }
    this.validateForm.patchValue({
      agentName:name,
    });
 }
 backAgentShow(args){
  this.isAgentShow=args;
 }
 //选择业务类型
 selectBus(){
  this.isNewedit=true;
 }
 backNewedit(args){
  this.isNewedit=args;
 }
 backDate(args){
  this.busiId=args.busId;
  this.busiTable=args.busTable;
  this.validateForm.patchValue({
    busiName:args.busName,
  })
 }
}
