import { Component, OnInit,Input, EventEmitter,Output,ViewChild, ElementRef,HostListener} from '@angular/core';
import { NzNotificationService} from 'ng-zorro-antd';
import { AutoInternationService } from '../../services/auto-internation.service';
import { FormBuilder,FormControl, FormGroup, Validators } from '@angular/forms';
import { IndexApiService } from '../../services/index-api.service';
import { TokenServiceService} from '../../services/token-service.service';

@Component({
  selector: 'app-update-userinfo',
  templateUrl: './update-userinfo.component.html',
  styleUrls: ['./update-userinfo.component.css']
})
export class UpdateUserinfoComponent implements OnInit {
  @ViewChild('drags') drags: ElementRef;
  @Input() isShow:boolean;
  @Input() showTit:any;
  @Input() saveUrl:any;
  @Input() verifyUrl:any;
  @Output() backEventState = new EventEmitter();
  @Output() backEventData = new EventEmitter();
  /*****
   * isShow:组件显示隐藏
   * showTit:显示标题
   * saveUrl:保存地址
   * verifyUrl：验证地址
   * backEventState：返回组件显示隐藏状态
   * backEventData：返回组件操作完的数据---保留
   * ***** */
  validateForm: FormGroup;
  isVisible=false;
  modalTitle='';
  saveUrls='';
  verifyUrls='';
  saveParm='';
  pid='0';
  agentId='';
  busiId='';
  busiTable='';
  showMdm='';
  isEdits=false;
  isDown = false;
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom='';
  isOrgType: boolean=false;
  showTreeTit: string;
  passwordVisible = false;
  userinfo: any;
  constructor(
    private notice:NzNotificationService,
    private nation:AutoInternationService,
    private fb: FormBuilder,
    private api:IndexApiService,
    private token:TokenServiceService,
  ) {

this.validateForm = this.fb.group({
  account            : [ null, [ Validators.required ,Validators.maxLength(30)] ],
  name         : [ null, [ Validators.required ,Validators.maxLength(20)] ],
  password      : [ null, [ Validators.required,Validators.maxLength(20) ]],
  telephone      : [ null, [this.mobileValidator,Validators.maxLength(11),Validators.minLength(11)]],
  email          : [null,this.emailValidator],
  orgName: [null, [Validators.required]],
  orgCode: [],
  orgId:[],
  phone:[],
  descs:[]
});
  }

  ngOnInit() {
    this.changeTem();
    this.getUserinfo();
  }
  ngOnChanges(){
    this.isVisible=this.isShow;
    this.modalTitle=this.showTit;
    this.saveUrls=this.saveUrl;
    this.verifyUrls=this.verifyUrl;
    //this.init();
  }
  getUserinfo(){
    this.api.getPost("/sec/user/queryUserByAccount?account="+this.token.getUserAccount(),{}).subscribe(res => {
      if(res.code==200){
        this.userinfo =res.data[0];
        this.validateForm.patchValue({
          name: this.userinfo.name,
          account:this.userinfo.account,
          telephone:this.userinfo.telephone,
          email:this.userinfo.email,
          orgName:this.userinfo.orgName,
          orgCode:this.userinfo.orgCode,
          orgId:this.userinfo.orgId,
          phone:this.userinfo.phone,
          descs:this.userinfo.descs
          });
        // this.api.getPost("/sec/user/queryUserById?userId ="+res.data.id,{}).subscribe(res => {
        //   if(res.code==200){
        //     console.log('用户信息',res);

        //   }else{
        //     this.notice.error( this.nation.getMessage('general','msg'),res.msg);
        //   }
        // },
        // err => {
        //   this.notice.error( this.nation.getMessage('general','msg'),err.error.message)
        // })
      }else{
        this.notice.error( this.nation.getMessage('general','msg'),res.msg);
      }
    },
    err => {
      this.notice.error( this.nation.getMessage('general','msg'),err.error.message)
    })
  }
  handleCancel(): void {
    this.isVisible=false;
    this.backEventState.emit(this.isVisible);
  }
  resetForm(): void {
    this.validateForm.reset();
  }
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[ i ].markAsDirty();
      this.validateForm.controls[ i ].updateValueAndValidity();
    }
  }
  //新增编辑保存
  handleOk(): void {
    let isPass=true;
    if(this.validateForm.value.email != null){
      isPass = this.checkEmail(this.validateForm.value.email);
    }
    if(isPass){

        //编辑
        this.submitForm();
        if(this.validateForm.status=="INVALID"){
          let basicSecUser ={
            name:this.validateForm.value.name,
            account:this.validateForm.value.account,
            telephone:this.validateForm.value.telephone,
            email:this.validateForm.value.email,
            orgId:this.validateForm.value.orgId,
            orgCode:this.validateForm.value.orgCode,
            orgName:this.validateForm.value.orgName,
            phone:this.validateForm.value.phone,
            descs:this.validateForm.value.descs,
            id:this.userinfo.id,
          }
          this.api.getPost("/sec/user/updateOnlyUser",basicSecUser).subscribe(data => {
            if(data.code=="200"){
              this.notice.success( this.nation.getMessage('general','msg'), this.nation.getMessage('general','modifys'))
                this.resetForm();
                this.isVisible=false;
                this.backEventState.emit(this.isVisible);
            }else{
              this.notice.error( this.nation.getMessage('general','msg'),data.msg)
            }
          },
          err => {
            this.notice.error( this.nation.getMessage('general','msg'),err.error.message)
          })
        }
    }else{
      this.notice.warning(this.nation.getMessage('general','msg'),"邮箱格式错误不可保存!");
    }


  }
  mobileValidator(control:FormControl):any {
    let myReg = /^1(3|4|5|6|7|8|9)+\d{9}$/;
    let valid = myReg.test(control.value);
    return valid ? null : {mobile:true};//如果valid是true 返回是null
  }
  emailValidator(control:FormControl):any {
    let myReg =  /^([a-zA-Z0-9\-_\.])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
    let valid = myReg.test(control.value);
    return valid ? null : {emailError:true};//如果valid是true 返回是null
  }
  checkEmail(args){
    let myReg =  /^([a-zA-Z0-9\-_\.])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
    let check = myReg.test(args);
    return check;
  };
  // 点击事件
  @HostListener('document:mousedown', ['$event']) onMousedown(event) {
    if(event.path[0].localName!='input'){
       if(this.activeDom!=''){
       this.isDown = true;
       this.disX = event.clientX;
       this.disY = event.clientY;
       }
    }
 }

 // 监听document移动事件事件
 @HostListener('document:mousemove', ['$event']) onMousemove(event) {
   // 判断该元素是否被点击了。
   if (this.isDown) {
     this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
     this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
   }
 }
 // 监听document离开事件
 @HostListener('document:mouseup', ['$event']) onMouseup(event) {
   // 只用当元素移动过了，离开函数体才会触发。
   if (this.isDown) {
     this.totalOffsetX += event.clientX - this.disX;
     this.totalOffsetY += event.clientY - this.disY;
     this.isDown = false;
   }
 }
 changeTem(){
  this.activeDom=this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
  this.activeDom['style'].position = 'relative';
 }
 validateConfirmPassword(): void {
  setTimeout(() => this.validateForm.controls.confirm.updateValueAndValidity());
}
selectCategroy(args) {
  args.stopPropagation();
  this.showTreeTit = '上级组织机构选择';
  this.isOrgType = true;
}
backDate(args) {
  this.validateForm.patchValue({
    orgName: args.name,
    orgCode: args.code,
    orgId: args.categoryId
  })
}
backNewedit(args) {
  this.isOrgType = args;
}
}
