import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewChecked, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-td-sq',
  templateUrl: './td-sq.component.html',
  styleUrls: ['./td-sq.component.css']
})
export class TdSqComponent implements OnInit, AfterViewChecked {
  @Input() tag;
  @Input() dataInfo = '';
  @Output() onClick: EventEmitter<void> = new EventEmitter();
  @ViewChild('box') box: ElementRef;
  showAddStr = false;
  dataOut = '';
  addStr = '...';
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.dataOut = this.dataInfo || '';
  }

  ngAfterViewChecked() {
    if (this.box.nativeElement) {
      const _height = ((this.box.nativeElement as HTMLElement)).offsetHeight;
      if (!_height) {
        return;
      }
      if (_height > 36) {
        this.showAddStr = true;
        if (this.dataOut) {
          this.dataOut = this.dataOut.slice(0, this.dataOut.length - 1);
          this.cd.detectChanges();
        }
      }
    }
  }
  onAClick(ev: Event) {
    ev.preventDefault();
    ev.stopPropagation();
    this.onClick.emit();
  }
}
