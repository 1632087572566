import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MaterielServerService {
  materielApi='http://10.246.151.188:8091';
  constructor(private http: HttpClient) { }
  materielAdd(url, data): Observable<any> {
      url = this.materielApi + url;
      return this.http.post(url, data);
  }
  materielQueryPost(url, data): Observable<any> {
    url = this.materielApi + url;
    return this.http.post(url, {params: data})
}
  materielQuery(url, data): Observable<any> {
      url = this.materielApi + url;
      return this.http.get(url, {params: data})
  }
  materielUpdate(url, data): Observable<any> {
      url = this.materielApi + url;
      return this.http.put(url, data);
  }
  materielDelete(url, data): Observable<any> {
      url = this.materielApi + url;
      return this.http.delete(url, { params: data});
  }
}
