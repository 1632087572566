import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViewStateService {
  isState=false;
  isRoute=false;
  temView={};
  attrTem=[];
  listSetView = [];
  constructor() { }
  openIsState(){
    this.isState=true;
  }
  coloseIsState(){
    this.isState=false;
  }
  openIsRoute(){
    this.isRoute=true;
  }
  coloseIsRoute(){
    this.isRoute=false;
  }
  setTemView(args){
    this.temView['data']=args.data;
    this.temView['type']=args.type;
    this.temView['isLock']=args.isLock;
    this.temView['isVersion']=args.isVersion;
  }
  setAttrTem(args){
    this.attrTem=args;
  }
  removeTemView(){
    this.temView={};
    this.attrTem=[];
  }
  getTemView(){
    return this.temView;
  }
  getAttrTem(){
    return this.attrTem;
  }
  setListSetView(args){
    this.listSetView = args;
  }
  getListSetView(){
    return this.listSetView
  }
}
