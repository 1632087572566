import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { ServiceApiService} from '../../commons/services/service-api.service';
import { TokenServiceService} from '../../commons/services/token-service.service';

@Injectable({
  providedIn: 'root'
})
export class IndexApiService {
  baseUrl=this.svApi.urlApi['file'];
  token={
    'x-access-current-token' :this.tokens.getToken(),
    'x-access-refresh-token' :this.tokens.getToken1(),
  };
  constructor(
    private http: HttpClient,
    private svApi:ServiceApiService,
    private tokens:TokenServiceService,
  ) { }
  handlePost(url, data): Observable<any> {
    let baseUrl=this.svApi.urlApi[data.type];
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = baseUrl + url;
    return this.http.post(url, data.args,{headers:tokens});
  }
  getPost(url,data): Observable<any> {
    let baseUrl=this.svApi.urlApi['sysManage'];
    let tokens={
      'x-access-current-token' :this.tokens.getToken(),
      'x-access-refresh-token' :this.tokens.getToken1(),
    };
    url = baseUrl + url;
    return this.http.post(url, data,{headers:tokens});
  }
}
