import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NzModalService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class TokenServiceService {

    ACCESS_TOKEN:string = 'x-access-current-token';//x-access-current-token
    REFRESH_TOKEN:string = 'x-access-refresh-token';
    SYS_LANG:string = 'sys-lang';
    notoken = false;
    jwtService:JwtHelperService;
    isRedirect=false;
    constructor(
      private modal: NzModalService, 
    ){
        this.jwtService = new JwtHelperService();
    }

    setToken(token){
      // sessionStorage localStorage
        // if(token == null || token.length == 0){
        //   sessionStorage.removeItem(this.ACCESS_TOKEN);
        // }else{
        //   sessionStorage.setItem(this.ACCESS_TOKEN, token);
        // }
        if(token.currentToken == null || token.currentToken.length == 0){
          sessionStorage.removeItem(this.ACCESS_TOKEN);
        }else{
          sessionStorage.setItem(this.ACCESS_TOKEN, token.currentToken);
          sessionStorage.setItem(this.REFRESH_TOKEN, token.refreshToken);
        }
    }
    removeToken(){
      sessionStorage.setItem(this.ACCESS_TOKEN, null);
      sessionStorage.setItem(this.REFRESH_TOKEN, null);
    }
    setLang(lang){
      if(lang == null || lang.length == 0){
        sessionStorage.setItem(this.SYS_LANG,'ZH');
      }else{
        sessionStorage.setItem(this.SYS_LANG, lang);
      }
    }
    getToken():string{
        return sessionStorage.getItem(this.ACCESS_TOKEN);
    }
    getToken1():string{
      return sessionStorage.getItem(this.REFRESH_TOKEN);
  }
    getSysLang():string{
      return sessionStorage.getItem(this.SYS_LANG);
    }

    // getUserInfo():Object{
    //     let userInfo = localStorage.getItem(this.USER_INFO);
    //     let userInfoObj = JSON.parse(userInfo);
    //     if(!userInfoObj){
    //         userInfoObj = {};
    //     }
    //     return userInfoObj;
    // }
    setUserInfo(data){
      sessionStorage.setItem('userName',data.userName);
      sessionStorage.setItem('account',data.account);
    }
    logout(){
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('account');
      localStorage.removeItem('sysId');
    }
    getUserInfo():string{
        return sessionStorage.getItem('userName');

    }
    getUserAccount():string{
      return sessionStorage.getItem('account');

  }
    isTokenExpired(token):boolean{
        return this.jwtService.isTokenExpired(token);
    }
    setRedirect(data){
      this.isRedirect=data
    }
    getRedirect(){
      return this.isRedirect
    }
    isNoToken(isTrue: boolean, cb: () => void) {
      if (this.notoken === isTrue) {
        return;
      }
      this.notoken = isTrue;
      if(this.notoken){
        this.modal.confirm({
          nzTitle     : '',
          nzContent   : '用户会话过期',
          nzOkText    : '确定',
          nzOkType    : 'danger',
          nzOnOk      : () => {
            cb();
            this.notoken = false;
            },
          nzCancelText: '否',
          nzOnCancel  : () =>{
            this.notoken = false;
          }
        });
    }
    }
}
