import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ViewArrangeService } from '../../../view-lay/services/view-arrange.service';
// import { ViewAttrKeepService } from '../../../view-lay/services/view-attr-keep.service';
import { AutoInternationService } from '../../services/auto-internation.service';
import { TokenServiceService } from '../../services/token-service.service';
import { HttpService } from '../../../sys-manage/services/http.service';

@Component({
	selector: 'app-exp-editor',
	templateUrl: './exp-editor.component.html',
	styleUrls: ['./exp-editor.component.css']
})
export class ExpEditorComponent implements OnInit {
	@Input() shown: boolean;
	@Input() info: any;
	@Output() handleBack = new EventEmitter();
	@ViewChild('drags') drags: ElementRef;
	isVisible = false;
	isDown = false;
	isLoadCode = false;
	isMode = false;
	disX; // 记录鼠标点击事件的位置 X
	disY; // 记录鼠标点击事件的位置 Y
	totalOffsetX = 0; // 记录总偏移量 X轴
	totalOffsetY = 0; // 记录总偏移量 Y轴
	activeDom = '';
	showString = '';
	newShowString = '';
	oldShowString = '';
	type = '';
	tbDatas = {};
	passTh = [];
	thList = [];
	showList = [];
	expString = '';
	expList = [];
	nodes = [];
	showAttrListDatas = []
	showFontOrder = [];
	expDatas = [];
	constructor(
		private notice: NzNotificationService,
		private api: ViewArrangeService,
		private nation: AutoInternationService,
		private token: TokenServiceService,
		private http: HttpService
	) { }

	ngOnInit() {
		this.changeTem();
		this.init();
		this.isVisible = this.shown;
		if (this.info.id == '1111' || this.info.id == '1101' || this.info.id == '1102' || this.info.id == '1103' || this.info.id == '1104') {
			this.type == 'WL'
		} else {
			this.type == '';
		}
		if (this.info.condText) {
			this.showString = this.info.condText;
			this.showList.push(this.info.condText);
			this.expList.push(this.info.condExp);
		}
	}
	init() {
		this.queryNode(this.info);
	}
	handleCancel() {
		this.isVisible = false;
		let obj = {
			mark: this.isVisible
		}
		this.handleBack.emit(obj);
	}
	handleOk() {
		this.isVisible = false;
		let obj = '';
		for (let i = 0, il = this.expList.length; i < il; i++) {
			if (this.expList[i].realColumn) {
				obj = obj + "`" + this.expList[i].realColumn + "`";
			} else if (this.expList[i] == '=') {
				obj = obj + '==';
			} else {
				obj = obj + this.expList[i];
			}
		}
		let objs = {
			showStr: this.showString,
			mark: this.isVisible,
			expStr: obj,
		}
		this.check(obj);
		this.handleBack.emit(objs);
	}
	getFunc(data) {
		let obj = '.' + data;
		this.showList.push(obj);
		this.expList.push(obj);
		this.showString = this.toString(this.showList);
		this.oldShowString = this.toString(this.showList);
	}
	onBlur() {
		this.newShowString = this.showString;
		if (this.newShowString.trim() !== this.oldShowString.trim()) {
			if (this.oldShowString.indexOf('.substring()') != -1) {
				let num = this.patch('.substring', this.newShowString);
				let oldLen = this.oldShowString.indexOf('.');
				let newLen = this.newShowString.length;
				if (num == 2) {
					oldLen = this.findIndexOf(this.newShowString, '.', 1);
				}
				if (this.oldShowString.slice(oldLen, oldLen + 12) == '.substring()') {
					this.expList.forEach((item, index) => {
						if (typeof (item) == 'string' && item.slice(0, 12) == '.substring()') {
							this.expList[index] = this.newShowString.slice(oldLen, newLen);
						}
					});
					this.showList.forEach((item, index) => {
						if (typeof (item) == 'string' && item.slice(0, 12) == '.substring()') {
							this.showList[index] = this.newShowString.slice(oldLen, newLen);
						}
					});
				} else if (this.oldShowString.slice(oldLen, 13) == '.trim()') {
					this.expList.forEach((item, index) => {
						if (typeof (item) == 'string' && item.slice(0, 5) == '.trim') {
							this.expList[index] = this.newShowString.slice(oldLen, newLen);
						}
					});
					this.showList.forEach((item, index) => {
						if (typeof (item) == 'string' && item.slice(0, 5) == '.trim') {
							this.showList[index] = this.newShowString.slice(oldLen, newLen);
						}
					});
				}
			}else if(this.oldShowString.indexOf('.split(') != -1){
				let num = this.patch('.split', this.newShowString);
				let oldLen = this.oldShowString.indexOf('.split');
				let newLen = this.newShowString.length;
				if (num == 2) {
					oldLen = this.findIndexOf(this.newShowString, '.split', 1);
				}
				 if (this.oldShowString.slice(oldLen, oldLen + 7) == '.split(') {
					this.expList.forEach((item, index) => {
						if (typeof (item) == 'string' && item.slice(0, 7) == '.split(') {
							this.expList[index] = this.newShowString.slice(oldLen, newLen);
						}
					});
					this.showList.forEach((item, index) => {
						if (typeof (item) == 'string' && item.slice(0, 7) == '.split(') {
							this.showList[index] = this.newShowString.slice(oldLen, newLen);
						}
					});
				}
			}else {
				let len = this.oldShowString.length;
				let newString='';
				if((this.oldShowString.indexOf("!=''")!=-1&&this.newShowString.indexOf("!=''")==-1)||(this.oldShowString.indexOf("!=''")!=-1&&this.newShowString.indexOf("!=''")==-1)){
					newString = this.newShowString.slice(len-2)
				}else{
					newString = this.newShowString.slice(len)
				}
				if (newString.indexOf('!=') > 0) {
					if (newString.match(/(\S*)!=/)[1] != '') {
						this.showList.push(newString.match(/(\S*)!=/)[1]);
						this.expList.push(newString.match(/(\S*)!=/)[1]);
					}
					this.showList.push('!=')
					this.expList.push('!=')
					this.showList.push(newString.match(/!=(\S*)/)[1]);
					this.expList.push(newString.match(/!=(\S*)/)[1]);
				} else if (newString.indexOf('+') > 0) {
					if (newString.match(/(\S*)\+/)[1] != '') {
						this.showList.push(newString.match(/(\S*)\+/)[1]);
						this.expList.push(newString.match(/(\S*)\+/)[1]);
					}
					this.showList.push('+')
					this.expList.push('+')
					this.showList.push(newString.match(/\+(\S*)/)[1]);
					this.expList.push(newString.match(/\+(\S*)/)[1]);
				} else if (newString.indexOf('-') > 0) {
					if (newString.match(/(\S*)-/)[1] != '') {
						this.showList.push(newString.match(/(\S*)-/)[1]);
						this.expList.push(newString.match(/(\S*)-/)[1]);
					}
					this.showList.push('-')
					this.expList.push('-')
					this.showList.push(newString.match(/-(\S*)/)[1]);
					this.expList.push(newString.match(/-(\S*)/)[1]);
				} else if (newString.indexOf('/') > 0) {
					if (newString.match(/(\S*)\//)[1] != '') {
						this.showList.push(newString.match(/(\S*)\//)[1]);
						this.expList.push(newString.match(/(\S*)\//)[1]);
					}
					this.showList.push('/')
					this.expList.push('/')
					this.showList.push(newString.match(/\/(\S*)/)[1]);
					this.expList.push(newString.match(/\/(\S*)/)[1]);
				} else if (newString.indexOf('=') > 0) {
					if (newString.match(/(\S*)=/)[1] != '') {
						this.showList.push(newString.match(/(\S*)=/)[1]);
						this.expList.push(newString.match(/(\S*)=/)[1]);
					}
					this.showList.push('=')
					this.expList.push('==')
					this.showList.push(newString.match(/=(\S*)/)[1]);
					this.expList.push(newString.match(/=(\S*)/)[1]);
				} else if (newString.indexOf('>') > 0) {
					if (newString.match(/(\S*)>/)[1] != '') {
						this.showList.push(newString.match(/(\S*)>/)[1]);
						this.expList.push(newString.match(/(\S*)>/)[1]);
					}
					this.showList.push('>')
					this.expList.push('>')
					this.showList.push(newString.match(/>(\S*)/)[1]);
					this.expList.push(newString.match(/>(\S*)/)[1]);
				} else if (newString.indexOf('<') > 0) {
					if (newString.match(/(\S*)</)[1] != '') {
						this.showList.push(newString.match(/(\S*)</)[1]);
						this.expList.push(newString.match(/(\S*)</)[1]);
					}
					this.showList.push('<')
					this.expList.push('<')
					this.showList.push(newString.match(/<(\S*)/)[1]);
					this.expList.push(newString.match(/<(\S*)/)[1]);
				} else if (newString.indexOf('>=') > 0) {
					if (newString.match(/(\S*)>=/)[1] != '') {
						this.showList.push(newString.match(/(\S*)>=/)[1]);
						this.expList.push(newString.match(/(\S*)>=/)[1]);
					}
					this.showList.push('>=')
					this.expList.push('>=')
					this.showList.push(newString.match(/>=(\S*)/)[1]);
					this.expList.push(newString.match(/>=(\S*)/)[1]);
				} else if (newString.indexOf('<=') > 0) {
					if (newString.match(/(\S*)<=/)[1] != '') {
						this.showList.push(newString.match(/(\S*)<=/)[1]);
						this.expList.push(newString.match(/(\S*)<=/)[1]);
					}
					this.showList.push('<=')
					this.expList.push('<=')
					this.showList.push(newString.match(/<=(\S*)/)[1]);
					this.expList.push(newString.match(/<=(\S*)/)[1]);
				} else {
					if((this.oldShowString.indexOf("!=''")!=-1&&this.newShowString.indexOf("!=''")==-1)||(this.oldShowString.indexOf("!=''")!=-1&&this.newShowString.indexOf("!=''")==-1)){
						this.showList[this.showList.length-1]=newString;
						this.expList[this.expList.length-1]=newString;
					}else{
						this.showList.push(newString);
						this.expList.push(newString);
					}
					
				}
			}
		}
	}
	getNode(args) {
		let obj = {}
		if (args.realColumn) {
			obj = {
				name: args.name,
				realColumn: args.realColumn
			}
		} else {
			obj = {
				name: args.NAME,
				realColumn: args.CODE,
			}
		}
		this.showList.push(obj);
		this.expList.push(obj);
		this.showString = this.toString(this.showList);
		this.oldShowString = this.toString(this.showList);
	}
	expBtn(args) {
		let datas = "";
		if (args == '单引号') {
			datas = "''";
		} else
		 if (args == '=') {
			datas = '=';
		} else if (args == 'and') {
			datas = ' and ';
		} else if (args == 'or') {
			datas = ' or ';
		} else if (args == 'not') {
			datas = ' not ';
		} else {
			datas = args;
		}
		this.showList.push(datas);
		this.expList.push(datas);
		this.showString = this.toString(this.showList);
		this.oldShowString = this.toString(this.showList);
		
	}
	queryNode(args) {
		if (args.id == '1111' || args.id == '1101' || args.id == '1102' || args.id == '1103' || args.id == '1104') {
			let url = '/integ/diTab/getColumnsByTabName?tableName=' + args.code + '&login-language=' + this.token.getSysLang();
			this.http.httpPost(url, {}).subscribe( res => {
				if (res.code == 200) {
					this.nodes = res.data;
				} else {
					this.notice.warning(this.nation.getMessage('general','msg'), res.msg);
				}
			}, err => {
				this.notice.warning(this.nation.getMessage('general','msg'), err.error.message);
			})
		} else {
			let url = '/modelAttrConf/list';
			let params = {
				modelId: args.id
			}
			this.api.handlePost(url, params).subscribe( res => {
				if (res.code == 200) {
					this.nodes = res.data;
				} else {
					this.notice.warning(this.nation.getMessage('general','msg'), res.msg);
				}
			}, err => {
				this.notice.warning(this.nation.getMessage('general','msg'), err.error.message);
			})
		}
	}
	toString(args) {
		let datas = '';
		for (let i = 0, il = args.length; i < il; i++) {
			if (args[i].name) {
				datas = datas + args[i].name;
			} else {
				datas = datas + args[i];
			}
		}
		return datas;
	}
	open(args) {
		this.tbDatas = {
			url: '/modelData/page',
			modelId: args.fromModel,
			fromPage: '1'
		}
		this.thList = args.showField.split(',');
		this.getModelField(args.fromModel);
	}
	getMoule(args) {
		this.isMode = args.isShow;
		let arr = [];
		let ary = [];
		for (let i in args.datas) {
			if (i.substr(0, 2) == "c_") {
				let obj = {
					key: i,
					val: args.datas[i]
				}
				arr.push(obj);
			}
		}
		for (let j = 0, jl = this.thList.length; j < jl; j++) {
			for (let k of arr) {
				if (this.thList[j] == k.key) {
					ary.push(k.val);
					break;
				}
			}
		}
		this.showList.push("'" + ary.join('_') + "'");
		this.expList.push("'" + args.datas.id + "'");
		this.showString = this.toString(this.showList);
		this.oldShowString = this.toString(this.showList);
	}

	changeTit(args) {
		this.passTh = [];
		for (let i = 0; i < this.thList.length; i++) {
			for (let j = 0; j < args.length; j++) {
				if (this.thList[i] == args[j].realColumn) {
					let obj = {
						key: args[j].realColumn,
						title: args[j].name,
						checked: true,
						disabled: false,
						value: "",
						type: "input"
					}
					this.passTh.push(obj);
					break;
				}
			}
		}
		this.isMode = true;
	}
	getModelField(args){
		let url = '/modelAttr/list';
		this.api.handlePost(url, {"modelId": args}).subscribe( res => {
			if (res.code == 200) {
				this.changeTit(res.data);
			} else {
				this.notice.warning(this.nation.getMessage('general','msg'), res.msg);
			}
		}, err => {
			this.notice.warning(this.nation.getMessage('general','msg'), err.error.message);
		})
	}
	allClear() {
		this.showString = '';
		this.showList = [];
		this.expList = [];
	}
	reCall() {
		if (this.showList.length > 0) {
			this.showList.pop();
			this.expList.pop();
			this.showString = this.toString(this.showList);
		}
	}
	// 函数出现的次数
	patch(args, string) {
		let substr = eval("/" + args + "/ig");
		return string.match(substr).length;
	}
	// 函数第二次出现的位置
	findIndexOf(str, cha, num) {
		var x = str.indexOf(cha);
		for (var i = 0; i < num; i++) {
			x = str.indexOf(cha, x + 1);
		}
		return x;
	}
	check(str) {
		let leftBracketNum = 0,strLength = str.length;
		for (let i = 0; i < strLength; i++) {
			let temp = str.charAt(i);
			if (temp === '(') {
				leftBracketNum++;
			}
			if (temp === ')') {
				leftBracketNum--;
			}
		}
		if (leftBracketNum === 0) {
			return true;
		} else {
			this.notice.warning(this.nation.getMessage('general','msg'),'表达式缺少一个括号');
			return false;
		}
	}
	changeTem() {
		this.activeDom = this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
		this.activeDom['style'].position = 'relative';
	}
	// 点击事件
	@HostListener('document:mousedown', ['$event']) onMousedown(event) {
		if (event.path[0].localName != 'input' && event.path[0].localName != 'select' && event.path[0].localName != 'button') {
			this.isDown = true;
			this.disX = event.clientX;
			this.disY = event.clientY;
		}
	}

	// 监听document移动事件事件
	@HostListener('document:mousemove', ['$event']) onMousemove(event) {
		// 判断该元素是否被点击了。
		if (this.isDown) {
			this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
			this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
		}
	}

	// 监听document离开事件
	@HostListener('document:mouseup', ['$event']) onMouseup(event) {
		// 只用当元素移动过了，离开函数体才会触发。
		if (this.isDown) {
			this.totalOffsetX += event.clientX - this.disX;
			this.totalOffsetY += event.clientY - this.disY;
			this.isDown = false;
		}
	}
}
