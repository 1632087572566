import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'overflowLength'
})
export class LengthPipe implements PipeTransform {

  // transform(value: any, args?: number): any {
  //   const length = args || 10;
  //   if (typeof value === 'string') {
  //     if (value.length > length) {
  //       value = `${value.slice(0, length - 3)}...`;
  //       return value;
  //     } else {
  //       return value;
  //     }
  //   }
  //   return value;
  // }
  transform(num): string { 
    
		let prefix:string="";
		if(num<0){
			num *= -1;
			prefix = "-";
		}
		let DIGIT_PATTERN = /(^|\s)\d+(?=\.?\d*($|\s))/g;
		let MILI_PATTERN = /(?=(?!\b)(\d{3})+\.?\b)/g;
		let str:string = num.toString().replace(DIGIT_PATTERN, (m) => m.replace(MILI_PATTERN, ','));
    return prefix+str;
  }
}
