import { Component, OnInit,Input, EventEmitter,Output } from '@angular/core';

@Component({
  selector: 'app-mater-tables-check',
  templateUrl: './mater-tables-check.component.html',
  styleUrls: ['./mater-tables-check.component.css']
})
export class MaterTablesCheckComponent implements OnInit {
  @Input() tableArgsDatas:any;
  @Input() mdmDatas:any;
  @Output() handleBackEvent = new EventEmitter();
   /**** 
  表格接口文档说明
  mdmDatas:页面展示主要数据，数据格式数组
  tableArgsDatas:页面主数据展示需要的参数，数据格式对象；
  数据格式：
  tableArgsDatas{
    thData:表格表头信息,
    wh:表格的宽度,
    total:表格数据总数,
    api:表格数据查询接口地址主要用于分页,
  }
 ***** */
  allChecked = false;
  indeterminate = false;
  thDatas=[];
  allChecks={
    checked1 : false,
    checked2 : false,
    checked3 : false,
  }
  isShownMod= false;
  displayData = [];
  data = [];
    
  
  constructor() { }
  ngOnChanges(){
    let mdmArgs=this.tableArgsDatas;
    this.thDatas=mdmArgs.thData;
    this.data=this.mdmDatas;
  }
  ngOnInit() {
  }
  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.displayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.displayData.filter(value => !value.disabled).every(value => value.checked === true);
    const allUnChecked = this.displayData.filter(value => !value.disabled).every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.displayData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }
  handleCheckedState(args){
    let lens=this.data.length;
    for(let i=0;i<lens;i++){
      if(args=="checked1"){
        this.data[i].checked2=false;
        this.data[i].checked3=false;
        this.data[i].checked1=this.allChecks[args];
      }else if(args=="checked2"){
        this.data[i].checked1=false;
        this.data[i].checked3=false;
        this.data[i].checked2=this.allChecks[args];
      }else{
        this.data[i].checked1=false;
        this.data[i].checked2=false;
        this.data[i].checked3=this.allChecks[args];
      }
    }
  }
  handleCheckValAll(args,i){
    this.allChecks[i]=args;
    if(i=="checked1"){
      this.allChecks.checked2=false;
      this.allChecks.checked3=false;
     
    }else if(i=="checked2"){
      this.allChecks.checked1=false;
      this.allChecks.checked3=false;
    }else{
      this.allChecks.checked1=false;
      this.allChecks.checked2=false;
    }
    this.handleCheckedState(i);
   
  }
  handleCheckVal(args,obj,i){
    obj[i]=args;
    if(i=="checked1"){
      obj.checked2=false;
      obj.checked3=false;
    }else if(i=="checked2"){
      obj.checked1=false;
      obj.checked3=false;
    }else{
      obj.checked1=false;
      obj.checked2=false;
    }
  }
   /****模态框的逻辑** */
   showModal(): void {
    this.isShownMod = true;
  }
  backMode(args){
    this.isShownMod=args.mark;
    this.thDatas=args.datas;
  }
  isBlock(args){
    if(!args.checked){
      return 'none'
    }
  }
}
