import { Component, OnInit, Input,EventEmitter,Output, TemplateRef, ViewChild, ElementRef,} from '@angular/core';
import { UploadService } from '../../services/upload.service';
import { NzNotificationService,NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
   /***
   * 功能：上传，删除，预览，下载
   * isShow:是否显示，
   * isEdit：是否是编辑状态，一般有新增和编辑两种状态默认是新增
   * thDatas：表格表头信息
   * showTit：展示标题
   * wh：宽度
   * getUrls：得到所有file的所有数据的url
   * seeUrls：预览的url
   * delUrls：删除的url
   * uploadUrls：上传的url
   * downloadUrls:下载
   * showId:主键
   * handleBackEvent：方法是返回组件状态
   * 注记：此组件只是适合物料代码申请附件上传
   * 如需对所有都使用，需要增加属性
   * surveyType：物料代码固定值为WL_WL
      fileColumn：物料代码固定值为wl_files
      operType：值分为submit[新增]、update[变更]。
      mainId：仅变更时需要传该值。
   *  **/
  @Input() isShow:boolean;
  @Input() isEdit:boolean;
  @Input() thDatas:any;
  @Input() showTit:any;
  @Input() showId:any;
  @Input() busId:any;
  @Input() fileColumn:any;
  @Input() wh:any;
  @Input() getUrls:any;
  @Input() seeUrls:any;
  @Input() delUrls:any;
  @Input() uploadUrls:any;
  @Input() downloadUrls:any;
  @Input() surveyType:any;
  @Output() handleBackEvent= new EventEmitter();
  @Output() handleBackSate= new EventEmitter();
  @ViewChild('inputFile') inputFile: ElementRef;
  isVisible = false;
  toHref="#";
  isEdits=false;
  surveyTypes='';
  showIds="";
  fileIds=[];
  displayData=[];
  tableBox=[];
  thData=[];
  title="附件上传";
  whs="800";
  getUrl="";
  seeUrl="";
  delUrl="";
  uploadUrl="";
  downloadUrl="";
  uploadStatus=1;
  showBut=false;
  isSee=false;
  imgUrls='';
  constructor(
    private fileUploadSv:UploadService,
    private notice:NzNotificationService,
    private model:NzModalService,
  ) { }

  ngOnInit() {
  }
  ngOnChanges(){
    if(this.getUrls!=""){
      this.isVisible=this.isShow;
      this.isEdits=this.isEdit;
      this.thData=this.thDatas;
      this.title=this.showTit;
      this.whs=this.wh;
      this.showIds=this.showId;
      this.getUrl=this.getUrls;
      this.seeUrl=this.seeUrls;
      this.delUrl=this.delUrls;
      this.uploadUrl=this.uploadUrls;
      this.downloadUrl=this.downloadUrls;
      this.surveyTypes=this.surveyType;
      // if(this.isEdits){
      //   this.init();
      // }
      if(this.title=='附件详情'){
         this.showBut=true;
      }else {
        this.showBut=false;
      }
      if(this.surveyTypes=='WL_WL'&&this.showIds!=null){
          this.fileIds=this.showIds.split('⊙');
      }
      if(this.surveyTypes=='WD_WD'&&this.showIds!=null){
        this.fileIds=this.showIds.split('⊙');
    }
      this.init();
    }

  }
  init(){
    let params={};
    let flag =true;
    if(this.surveyTypes=='WL_WL'){
      let fileId = this.getFileIds();
      //if(fileId!=''){
        params={
          ids: fileId,
          busId:"1111",
          fileColumn:"wl_files"
        }
      // }else{
      //   flag=false;
      // }
    }else if(this.surveyTypes=='DM_DM'){
      if(this.showIds!=null){
        params={
          ids: this.showIds,
        }
      }else{
        flag=false;
      }
    }else if(this.surveyTypes=='WD_WD'){
      let fileId = this.getFileIds();
       params={
         ids: fileId,
         busId:"1202",
         fileColumn:"wd_files"
       }
    }
    // else if (this.surveyTypes=='DM_DMD') {
    //   params={
    //     dataId: this.showIds,
    //     busId: this.busId,
    //     fileColumn: this.fileColumn
    //   }
    // }
   if(flag){
      this.fileUploadSv.handlePost("/file/list",params).subscribe(res => {
        if(res.code=="200"){
          if(res.data!=null){
            let fileList=res.data;
            if(fileList.fileList.length!=0){
              this.showFileList(fileList.fileList);
            }
          }else{
            this.showFileList(null);
          }
        }else{
          this.notice.error( '提示',"失败")
        }
      },
      err => {
        this.notice.warning( '提示',err.error.message)
      },);
    }
  }
    //获取附近ids拼接成字符串
    getFileIds(){
      let arr = '';
      for(let i=0;i<this.fileIds.length;i++){
          if(i==0){
            arr=this.fileIds[i];
          }else{
            arr=arr+'⊙'+this.fileIds[i];
          }
      }
      return arr;
    }
  toUpload(args){
    args.stopPropagation();
    this.inputFile.nativeElement.click();
  }
  backFile(args){
    args.stopPropagation();
    if(this.inputFile.nativeElement.files.length!=0){
      this.fileUpload(this.inputFile.nativeElement.files[0]);
    }
  }
  showFileList(args){
    if(args==null){
      this.displayData=[];
    }else{
      this.displayData=[];
      this.displayData=[...args];
    }

    // let ary=[];
    // for(let n of args){
    //   let obj={
    //     uid: n.id,
    //     name: n.fileName,
    //     status: 'done',
    //     response: '',
    //     linkProps: '',
    //   }
    //   ary.push(obj);
    // }
    // this.displayData=[...ary];
  }
  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.displayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allUnChecked = this.displayData.filter(value => !value.disabled).every(value => !value.checked);
  }
  fileUpload(item){
    const formData = new FormData();
    formData.append('file', item as any);
    let urls ='';
    if(this.surveyTypes=='WL_WL'){
      //urls='/file/upload?dataId='+this.showIds+'&fileColumn=wl_files&operType=1&busId=1111';
      urls='/file/upload?fileColumn=wl_files&busId=1111';
    }else if(this.surveyTypes=='WD_WD'){
      urls='/file/upload?fileColumn=wd_files&busId=1202';
    }
    // else if(this.surveyTypes=='DM_DM'){
    //   urls='/file/upload?dataId='+this.showIds+'&fileColumn=dm_files&busId=1401';
    // }
    this.fileUploadSv.handlePostFileTest(urls,formData).subscribe(res => {
      if(res.code=="200"){
        //this.notice.success( '提示',"新增成功");
        this.fileIds.push(res.data);
        this.init();
        this.inputFile.nativeElement.files=[];
      }else{
        this.notice.error( '提示',"失败");
        this.inputFile.nativeElement.files=[];
      }
    },
    err => {
      this.notice.warning( '提示',err.error.message)
    },);
  }
  toLoad(args){
    let urls='/file/download?fileId='+args['id'];
    this.fileUploadSv.handleGetLoad(urls).subscribe(res => {
      const uA = window.navigator.userAgent;
      const isIE = /msie\s|trident\/|edge\//i.test(uA) && !!("uniqueID" in document || "documentMode" in document || ("ActiveXObject" in window) || "MSInputMethodContext" in window);
      const url = URL.createObjectURL(res);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display:none');
      a.setAttribute('id', 'download');
      a.setAttribute('href', url);
      a.setAttribute('target', '_blank');
      a.setAttribute('download', args.fileName); //这个name是下载名.后缀名
      if (isIE) {
        // 兼容IE11无法触发下载的问题
        navigator.msSaveBlob(res['blob'],args.fileName);
      } else {
        a.click();
      }
      // 触发下载后再释放链接
      a.addEventListener('click', function() {
        URL.revokeObjectURL(url);
        document.getElementById('download').remove();
      });
    },
    err => {
      this.notice.warning( '提示',err.error.message)
    },);
  }
  toSee(args){
    this.isSee=true;
    let params=[];
    let url='/file/preview?filePath='+args['filePath'];
    this.imgUrls=this.fileUploadSv.handleImgLoad(url);
        // let url='/file/preview?filePath='+args['filePath'];
        // this.fileUploadSv.handleGet(url,params).subscribe(res => {
        //   if(res.code=="200"){
        //    this.imgUrls=res.data;
        //   }else{
        //     this.notice.error( '提示',"失败")
        //   }
        // },

        // err => {
        //   this.notice.warning( '提示',err.error.message)
        // },);
  }
  filebackType(args,obj){
    let mark=true;
    if(obj=='fileName'){
      if(args.fileType=='bmp'||args.fileType=='jpg'||args.fileType=='jpeg'||args.fileType=='png'||args.fileType=='gif'){
        mark=false;
      }
    }
    return mark;

  }
  closeImg(){
    this.isSee=false;
  }
  deletes(){
    // if(this.displayData.filter(value => value.checked === true).length==0){
    //   this.notice.warning( '提示','请选择要操作的数据')
    // }else{
    //   let list=this.displayData.filter(value => value.checked === true);
    //     this.model.confirm({
    //       nzTitle     : '删除',
    //       nzContent   : '<b style="color: red;">您确定要删除吗</b>',
    //       nzOkText    : '是',
    //       nzOkType    : 'danger',
    //       nzOnOk      : () => {

    //       },
    //       nzCancelText: '否',
    //       nzOnCancel  : () => console.log('Cancel')
    //     });
    //   }else{
    //     this.notice.warning( '提示','数据不能删除！')
    //   }
    // }
  }
  toDel(args){
    this.model.confirm({
      nzTitle     : '删除',
      nzContent   : '<b style="color: red;">您确定要删除吗</b>',
      nzOkText    : '是',
      nzOkType    : 'danger',
      nzOnOk      : () => {
        let params=[];
        let url='/file/deleteByFileId?fileId='+args['id'];
        this.fileUploadSv.handleGet(url,params).subscribe(res => {
          if(res.code=="200"){
           //this.notice.success( '提示',"删除成功");
           for(let i=0;i<this.fileIds.length;i++){
             if(args['id']==this.fileIds[i]){
               this.fileIds.splice(i,1);
             }
           }
           this.init();
          }else{
            this.notice.error( '提示',"失败")
          }
        },

        err => {
          this.notice.warning( '提示',err.error.message)
        },);
      },
      nzCancelText: '否',
      nzOnCancel  : () => console.log('Cancel')
    });

  }
  handleCancel(){
    this.isVisible=false;
    this.handleBackEvent.emit(this.isVisible=false);
    this.handleBackSate.emit(this.fileIds);
  }
  handleOk(){
    this.isVisible=false;
    this.handleBackEvent.emit(this.isVisible=false);
    this.handleBackSate.emit(this.fileIds);
  }

}
