import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-mdm-tables',
  templateUrl: './mdm-tables.component.html',
  styleUrls: ['./mdm-tables.component.css']
})
export class MdmTablesComponent implements OnInit {
  allChecked = false;
  indeterminate = false;
  displayData = [];
  editMark=false;
  isVisible = false;
  isTemplate=false;
  indexMode=0;
  setModeDatas={
    natureDm    : '',
    natureNames    : '',
    natureType : '',
    dataType:'',
  };
  data = [
    {
      natureDm    : '物料123',
      natureNames    : '物料',
      natureType : 'input',
      dataType:'字符串',
      checked : false,
      disabled: false
    },
    {
      natureDm    : '钢材17893',
      natureNames    : '钢材',
      natureType : 'input',
      dataType:'布尔',
      checked : false,
      disabled: false
    },
    {
      natureDm    : '钢结构1456623',
      natureNames    : '钢结构',
      natureType : 'select',
      dataType:'字符串',
      checked : false,
      disabled: false
    },
    {
      natureDm    : '不锈钢234',
      natureNames    : '不锈钢',
      natureType : 'select',
      dataType:'布尔',
      checked : false,
      disabled: false
    },
   
  ];
  constructor(private message: NzMessageService) { }

  ngOnInit() {
  }
  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.displayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.displayData.filter(value => !value.disabled).every(value => value.checked === true);
    const allUnChecked = this.displayData.filter(value => !value.disabled).every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.displayData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }
  showModal(args): void {
    let mark=0,index=0;
    if(args==1){
      this.editMark=true;
      let lens=this.data.length;
      for(let i=0;i<lens;i++){
        if(this.data[i].checked==true){
          ++mark;
          this.indexMode=i;
        }
      }
    }
    if(args==2){
      this.isVisible = true;
      this.isTemplate=true;
      return 
    }
    if(mark==0&&args==0){
      this.isVisible = true;
    }else if(mark>1){
      this.isVisible = false;
      this.message.info('只能选一个');
    }else if(mark==1){
      let obj={};
      for(let i in this.setModeDatas){
        this.setModeDatas[i]=this.data[this.indexMode][i];
      }
      this.isVisible = true;
    }else if(mark==0&&args==1){
      this.message.info('请选择数据');
    }
    
  }
  handleDel(){
    this.data;
    this.data = this.data.filter(d => d.checked !== true);
  }
  handleOk(): void {
    if(!this.isTemplate){
      if(!this.editMark){
        this.data= [ ...this.data, {
          natureDm    : this.setModeDatas.natureDm,
          natureNames    : this.setModeDatas.natureNames,
          natureType : this.setModeDatas.natureType,
          dataType: this.setModeDatas.dataType,
          checked : false,
          disabled: false
        }];
      }else{
        for(let i in this.setModeDatas){
          this.data[this.indexMode][i]= this.setModeDatas[i];
        }
      
        this.indexMode=0;
        
      }
      this.initMode(this.setModeDatas);
    }
    this.isTemplate=false;
    this.isVisible = false;
    this.editMark=false;

  }
  initMode(args){
    for(let i in args){
      args[i]="";
    }
  }
  handleCancel(): void {
    this.isVisible = false;
    this.isTemplate=false;
    console.log(this.setModeDatas);
  }
}
