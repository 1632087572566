

import { Component, OnInit,Input, EventEmitter,Output,TemplateRef, ViewChild, ElementRef,HostListener} from '@angular/core';
import { UploadService } from '../../services/upload.service';
import { NzNotificationService,NzModalService } from 'ng-zorro-antd';
import { FakeMissingTranslationHandler } from '@ngx-translate/core';
import { AutoInternationService } from '../../services/auto-internation.service';


@Component({
  selector: 'app-common-data-lifecycle',
  templateUrl: './common-data-lifecycle.component.html',
  styleUrls: ['./common-data-lifecycle.component.css']
})
export class CommonDataLifecycleComponent implements OnInit {
  @Input() isShow:any;
  @Input() Data:any;
  @Output() handleEvent = new EventEmitter();

  @ViewChild('drags') drags: ElementRef;
  isDown = false;
  disX; // 记录鼠标点击事件的位置 X
  disY; // 记录鼠标点击事件的位置 Y
  totalOffsetX = 0; // 记录总偏移量 X轴
  totalOffsetY = 0; // 记录总偏移量 Y轴
  activeDom='';
  displayData1=[];
  isVisible;
  lifecycleList: any=[];
  wh="900px";
  userInfo={}
  constructor(private api:UploadService,private notice:NzNotificationService, private nation:AutoInternationService,) { }
  ngOnInit() {
    this.isVisible=this.isShow;
    this.changeTem();
    this.getLifecycleList();
  }
  getLifecycleList(){
    let data={};
    if(this.Data.modelId==""){
     
    this.api.handlePost3(this.Data.url,{wlbm:this.Data.wlbm}).subscribe(res => {
      if(res.code==200){
        if(res.data!=null){
          this.lifecycleList=res.data;
        }
        //this.notice.success(this.nation.getMessage('general','msg'),res.msg);
      }else{
        this.notice.warning(this.nation.getMessage('general','msg'),res.msg);
      }
    },
    err => {
      this.notice.warning(this.nation.getMessage('general','msg'),err.error.message)
    }, 
    );
    }else{
      
    this.api.handlePost2(this.Data.url,{id:this.Data.id,modelId:this.Data.modelId}).subscribe(res => {
      if(res.code==200){
        if(res.data!=null){
          this.lifecycleList=res.data;
        }
        //this.notice.success(this.nation.getMessage('general','msg'),res.msg);
      }else{
        this.notice.warning(this.nation.getMessage('general','msg'),res.msg);
      }
    },
    err => {
      this.notice.warning(this.nation.getMessage('general','msg'),err.error.message)
    }, 
    );
    }
  }
  // 点击事件
  @HostListener('document:mousedown', ['$event']) onMousedown(event) {
    if(event.path[0].localName!='input'){
      this.isDown = true;
      this.disX = event.clientX;
      this.disY = event.clientY;
    }
  }
  // 监听document移动事件事件
  @HostListener('document:mousemove', ['$event']) onMousemove(event) {
    // 判断该元素是否被点击了。
    if (this.isDown) {
      this.activeDom['style'].left = this.totalOffsetX + event.clientX - this.disX + 'px';
      this.activeDom['style'].top = this.totalOffsetY + event.clientY - this.disY + 'px';
    }
  }

  // 监听document离开事件
  @HostListener('document:mouseup', ['$event']) onMouseup(event) {
    // 只用当元素移动过了，离开函数体才会触发。
    if (this.isDown) {
      this.totalOffsetX += event.clientX - this.disX;
      this.totalOffsetY += event.clientY - this.disY;
      this.isDown = false;
    }
  }
 changeTem(){
  this.activeDom=this.drags['elementRef'].nativeElement.children[0].children[0].children[0].children[0];
  this.activeDom['style'].position = 'relative';
 }
  handleCancel(): void {
    this.isVisible = false;
    this.handleEvent.emit(this.isVisible);
  }
  handleOk(): void {
  
      this.isVisible = false;
      this.handleEvent.emit(this.isVisible);
    
  }
  mouseEnter(id,name){
    if(name!="自动下发"){
      let url="/sec/user/queryUserById?userId="+id
      this.api.handlePost4(url,{}).subscribe(res => {
        if(res.code==200){
          this.userInfo=res.data;
          
        }else{
          this.notice.warning(this.nation.getMessage('general','msg'),res.msg);
        }
      },
      err => {
        this.notice.warning(this.nation.getMessage('general','msg'),err.error.message)
      }, 
      );
    }
  }
  mouseLeave(){
    this.userInfo={};
  }
}

