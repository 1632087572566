import { Component, OnInit, Input,EventEmitter,Output, TemplateRef, ViewChild, ElementRef,} from '@angular/core';
import { UploadService } from '../../services/upload.service';
import { NzNotificationService,NzModalService } from 'ng-zorro-antd';

@Component({
	selector: 'app-upload-table',
	templateUrl: './upload-table.component.html',
	styleUrls: ['./upload-table.component.css']
})
export class UploadTableComponent implements OnInit {
	/***
	* 功能：上传，删除，预览，下载
	* isShow:是否显示，
	* isEdit：是否是编辑状态，一般有新增和编辑两种状态默认是新增
	* thDatas：表格表头信息
	* showTit：展示标题
	* wh：宽度
	* getUrls：得到所有file的所有数据的url
	* seeUrls：预览的url
	* delUrls：删除的url
	* uploadUrls：上传的url
	* downloadUrls:下载
	* showId:主键
	* handleBackEvent：方法是返回组件状态
	* 注记：此组件只是适合物料代码申请附件上传
	* 如需对所有都使用，需要增加属性
	* surveyType：物料代码固定值为WL_WL
	   fileColumn：物料代码固定值为wl_files
	   operType：值分为submit[新增]、update[变更]。
	   mainId：仅变更时需要传该值。
	*  **/
	@Input() isShow: boolean;
	@Input() isEdit: boolean;
	@Input() thDatas: any;
	@Input() showTit: any;
	@Input() showId: any;
	@Input() busId: any;
	@Input() fileColumn: any;
	@Input() wh: any;
	@Input() getUrls: any;
	@Input() seeUrls: any;
	@Input() delUrls: any;
	@Input() uploadUrls: any;
	@Input() downloadUrls: any;
	@Input() surveyType: any;
	@Output() handleBackEvent = new EventEmitter();
	@ViewChild('inputFile') inputFile: ElementRef;
	isVisible = false;
	toHref = "#";
	isEdits = false;
	surveyTypes = '';
	showIds = "";
	displayData = [];
	tableBox = [];
	thData = [];
	title = "附件上传";
	whs = "800";
	getUrl = "";
	seeUrl = "";
	delUrl = "";
	uploadUrl = "";
	downloadUrl = "";
	uploadStatus = 1;
	showBut = false;
	isSee = false;
	imgUrls = '';
	constructor(
		private fileUploadSv: UploadService,
		private notice: NzNotificationService,
		private model: NzModalService,
	) { }

	ngOnInit() {
	}
	ngOnChanges() {
		if (this.getUrls != "") {
			this.isVisible = this.isShow;
			this.isEdits = this.isEdit;
			this.thData = this.thDatas;
			this.title = this.showTit;
			this.whs = this.wh;
			this.showIds = this.showId;
			this.getUrl = this.getUrls;
			this.seeUrl = this.seeUrls;
			this.delUrl = this.delUrls;
			this.uploadUrl = this.uploadUrls;
			this.downloadUrl = this.downloadUrls;
			this.surveyTypes = this.surveyType;
			if (this.isEdits) {
				this.init();
			} else {

			}
			if (this.title == '附件详情') {
				this.showBut = true;
			} else {
				this.showBut = false;
			}
		}

	}
	init() {
		let params={};
		if (this.surveyTypes=='DM_DMD') {
		  params={
			ids: this.showIds,
		  }
		}
		if(this.showIds!=undefined){
			this.fileUploadSv.handlePost("/file/list", params).subscribe(res => {
				if (res.code == "200") {
					let fileList = res.data;
					if (fileList.fileList.length != 0) {
						this.showFileList(fileList.fileList);
					}
				} else {
					this.notice.error('提示', "失败")
				}
			},
			err => {
				this.notice.warning('提示', err.error.message)
			});
		}
		
	}
	showFileList(args) {
		this.displayData = [];
		this.displayData = [...args];
	}
	currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
		this.displayData = $event;
		this.refreshStatus();
	}

	refreshStatus(): void {
		const allUnChecked = this.displayData.filter(value => !value.disabled).every(value => !value.checked);
	}
	toLoad(args) {
		let urls = '/file/download?fileId=' + args['id'];
		this.fileUploadSv.handleGetLoad(urls).subscribe(res => {
			const uA = window.navigator.userAgent;
			const isIE = /msie\s|trident\/|edge\//i.test(uA) && !!("uniqueID" in document || "documentMode" in document || ("ActiveXObject" in window) || "MSInputMethodContext" in window);
			const url = URL.createObjectURL(res);
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.setAttribute('style', 'display:none');
			a.setAttribute('id', 'download');
			a.setAttribute('href', url);
			a.setAttribute('target', '_blank');
			a.setAttribute('download', args.fileName); //这个name是下载名.后缀名
			if (isIE) {
				// 兼容IE11无法触发下载的问题
				navigator.msSaveBlob(res['blob'], args.fileName);
			} else {
				a.click();
			}
			// 触发下载后再释放链接
			a.addEventListener('click', function () {
				URL.revokeObjectURL(url);
				document.getElementById('download').remove();
			});
		},
			err => {
				this.notice.warning('提示', err.error.message)
			});
	}
	toSee(args) {
		this.isSee = true;
		let params = [];
		let url = '/file/preview?filePath=' + args['filePath'];
		this.imgUrls = this.fileUploadSv.handleImgLoad(url);
	}
	filebackType(args, obj) {
		let mark = true;
		if (obj == 'fileName') {
			if (args.fileType == 'bmp' || args.fileType == 'jpg' || args.fileType == 'jpeg' || args.fileType == 'png' || args.fileType == 'gif') {
				mark = false;
			}
		}
		return mark;
	}
	closeImg() {
		this.isSee = false;
	}
}
