import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceApiService } from '../../commons/services/service-api.service';
import { TokenServiceService } from '../../commons/services/token-service.service';
@Injectable({
	providedIn: 'root'
})
export class HttpService {
	baseUrl = this.svApi.urlApi['sysManage'];
	materielUrl = this.svApi.urlApi['matter'];
	token = {
		'x-access-current-token' :this.tokens.getToken(),
		'x-access-refresh-token' :this.tokens.getToken1(),
	};
	constructor(private http: HttpClient, private svApi: ServiceApiService, private tokens: TokenServiceService, ) { }
	httpPost(url, data): Observable<any> {
		let tokens = {
			'x-access-current-token' :this.tokens.getToken(),
			'x-access-refresh-token' :this.tokens.getToken1(),
		};
		url = this.baseUrl + url;
		return this.http.post(url, data, { headers: tokens });
	}
	httpGet(url, data): Observable<any> {
		url = this.baseUrl + url;
		return this.http.get(url, { params: data })
	}
	materielQueryPost(url, data): Observable<any> {
		let tokens = {
			'x-access-current-token' :this.tokens.getToken(),
			'x-access-refresh-token' :this.tokens.getToken1(),
		};
		url = this.materielUrl + url;
		return this.http.post(url, data, { headers: tokens })
	}
}
